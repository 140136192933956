import {
	Badge,
	Box,
	Icons,
	Time,
	Spinner,
	Inline,
	Button,
	Divider,
	Heading,
	Text,
	Alert,
	Stack,
	Grid,
	Col,
	Table,
	TableFooterDataCell,
	Money,
	Component,
	RelativeTime,
} from "@sembark-travel/ui/base"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { useDialog, Dialog } from "@sembark-travel/ui/dialog"
import {
	utcTimestampToLocalDate,
	isBefore,
	localOrUtcTimestampToLocalDate,
} from "@sembark-travel/datetime-utils"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import { ActivityLogs } from "../ActivityLogs"
import { AddCommentInDialog, CommentableCommentsList } from "../Comments"
import { IInstalment } from "./store"
import { LogTransaction } from "./LogTransaction"
import useSWR from "swr"
import * as Validator from "yup"
import { useId, useRef } from "react"
import {
	Form,
	SwitchInputField,
	withServerErrors,
	SubmissionError,
} from "@sembark-travel/ui/form"
import pluralize from "pluralize"
import { InstalmentContact } from "./InstalmentContact"

function XHR(xhr: XHRInstance) {
	return {
		async show(instalmentId: number | string, params?: unknown) {
			return xhr
				.get<{ data: IInstalment }>(`/instalments/${instalmentId}`, { params })
				.then((resp) => resp.data)
		},
		async verifyPaidInstalment(
			data: Array<number>
		): Promise<Array<IInstalment>> {
			return xhr
				.post("/verified-instalments", {
					items: data,
				})
				.then((resp) => resp.data.data)
		},
		async unverifyPaidInstalment(
			data: Array<number>
		): Promise<Array<IInstalment>> {
			return xhr
				.delete("/verified-instalments", {
					data: { items: data },
				})
				.then((resp) => resp.data.data)
		},
		async deleteInstalment(instalmentId: number): Promise<void> {
			return xhr.delete(`/instalments/${instalmentId}`)
		},
		async revertInstalmentTransactions(
			instalmentId: number,
			data: unknown
		): Promise<void> {
			return xhr.post(`/revert-instalment-transactions/${instalmentId}`, data)
		},
	}
}

export function InstalmentItemInDialog({
	children,
	...props
}: React.ComponentProps<typeof InstalmentItem> & {
	children: (props: { show: () => void }) => React.ReactNode
}) {
	const [isOpen, open, close] = useDialog()
	return (
		<>
			{children({ show: open })}
			<Dialog open={isOpen} onClose={close} title="Instalment Details">
				<Dialog.Body>
					<InstalmentItem {...props} />
				</Dialog.Body>
			</Dialog>
		</>
	)
}

export function InstalmentItem({
	onChange,
	instalmentId,
	readOnly,
	showSiblings,
	disableLogging,
}: {
	instalmentId: number | string
	onChange?: () => void
	readOnly?: boolean
	showSiblings?: boolean
	disableLogging?: boolean
}) {
	const xhr = useXHR()
	const { data, mutate } = useSWR(`/instalments/${instalmentId}`, () =>
		XHR(xhr).show(instalmentId, { include: showSiblings ? "siblings" : "" })
	)
	if (!data) {
		return <Spinner alignCenter padding="4" />
	}
	const instalment = data.data
	const { payment, currency } = instalment
	const { amount: totalPaymentAmount, instalments } = payment
	return (
		<Stack gap="8">
			<Stack>
				<Stack gap="4">
					<Inline gap={{ xs: "4", md: "6" }} flexWrap="wrap">
						<Stack gap="1">
							<Text fontSize="sm">Amount ({currency})</Text>
							<Stack>
								<Money
									amount={instalment.amount}
									currency={instalment.currency}
									fontSize="xl"
									fontWeight="semibold"
								/>
								<Box>
									/{" "}
									<Money
										fontSize="sm"
										color="muted"
										amount={totalPaymentAmount}
										currency={instalment.currency}
									/>
								</Box>
							</Stack>
						</Stack>
						<Stack gap="1">
							<Text fontSize="sm">Due Date</Text>
							<Time
								fontSize="lg"
								timestamp={instalment.due_at}
								localTimestamp={instalment.due_at_local}
							/>
						</Stack>
						<Stack gap="1">
							<Text fontSize="sm">Contact</Text>
							<Box>
								<InstalmentContact instalment={instalment} />
							</Box>
						</Stack>
					</Inline>
					<Inline gap={{ xs: "4", md: "6" }} flexWrap="wrap">
						<Stack gap="1">
							<Text fontSize="sm">Status</Text>
							<Box>
								{instalment.paid_at ? (
									<Stack gap="1">
										<Badge success>Paid</Badge>
										<Box>
											{instalment.verified_at && instalment.verified_by ? (
												<Time timestamp={instalment.paid_at}>
													<Badge success>Verified</Badge>
												</Time>
											) : (
												<Badge warning>Verification Pending</Badge>
											)}
										</Box>
									</Stack>
								) : instalment.is_overdue ? (
									<Badge danger>Overdue</Badge>
								) : (
									<Badge>Due</Badge>
								)}
							</Box>
						</Stack>
						{instalment.paid_at ? (
							<>
								<Stack gap="1">
									<Text fontSize="sm">Paid On</Text>
									<Time
										fontSize="lg"
										fontWeight="semibold"
										timestamp={instalment.paid_at}
									/>
									{instalment.paid_by ? (
										<Text fontSize="sm">{instalment.paid_by.name}</Text>
									) : null}
								</Stack>
								{instalment.currency !== instalment.txn_currency &&
								instalment.txn_amount_money ? (
									<Stack gap="1">
										<Text fontSize="sm">Amount</Text>
										<Box>
											<Money
												showCurrency
												fontWeight="semibold"
												money={instalment.txn_amount_money}
												fontSize="lg"
											/>
										</Box>
									</Stack>
								) : null}
								{instalment.verified_at ? (
									<Stack gap="1">
										<Text fontSize="sm">Verified On</Text>
										<Time
											fontSize="lg"
											fontWeight="semibold"
											timestamp={instalment.verified_at}
										/>
										{instalment.verified_by ? (
											<Text fontSize="sm">{instalment.verified_by.name}</Text>
										) : null}
									</Stack>
								) : null}
								<Stack gap="1">
									<Text fontSize="sm">Ref. ID</Text>
									<Box>
										{instalment.reference_id || (
											<Box color="muted">Not Set</Box>
										)}
									</Box>
								</Stack>
							</>
						) : null}
					</Inline>
					{instalment.paid_at ? (
						<Inline gap={{ xs: "4", md: "6" }} flexWrap="wrap">
							{instalment.debit_accounts?.length ? (
								<Stack gap="1">
									<Text fontSize="sm">
										Debit{" "}
										{pluralize("Account", instalment.debit_accounts.length)}
									</Text>
									<Box fontWeight="semibold">
										{instalment.debit_accounts.map((a) => a.name).join(", ")}
									</Box>
								</Stack>
							) : null}
							{instalment.credit_accounts?.length ? (
								<Stack gap="1">
									<Text fontSize="sm">
										Credit{" "}
										{pluralize("Account", instalment.credit_accounts.length)}
									</Text>
									<Box fontWeight="semibold">
										{instalment.credit_accounts.map((a) => a.name).join(", ")}
									</Box>
								</Stack>
							) : null}
						</Inline>
					) : null}
				</Stack>
				<Divider sm />
				<Grid gap="6">
					<Col sm={12} md>
						<Stack gap="2">
							<Heading as="h4" fontSize="base" color="primary">
								Activities
							</Heading>
							<Box>
								<ActivityLogs
									subjectId={instalment.id}
									subjectType="instalments"
								/>
							</Box>
						</Stack>
					</Col>
					<Col>
						<Stack>
							<Inline gap="6" justifyContent="between" alignItems="center">
								<Heading as="h4" fontSize="base" color="primary">
									Comments
								</Heading>
								<Box>
									<AddCommentInDialog
										commentableId={instalment.id}
										commentableType="instalments"
										onChange={() => mutate()}
										level="tertiary"
									>
										{({ onAdd }) => (
											<Button
												onClick={() => onAdd()}
												size="sm"
												level="tertiary"
											>
												<Icons.Annotation />
											</Button>
										)}
									</AddCommentInDialog>
								</Box>
							</Inline>
							{instalment.comments?.length ? (
								<Box>
									<CommentableCommentsList
										comments={instalment.comments}
										onChange={() => mutate()}
									/>
								</Box>
							) : (
								<Text fontSize="sm" color="muted">
									No comments added!
								</Text>
							)}
						</Stack>
					</Col>
				</Grid>
				{instalment.journals?.length ? (
					<>
						<Divider sm />
						<Stack gap="2">
							<Heading as="h4" fontSize="base" color="primary">
								Transactions
							</Heading>
							<Stack as="ul" listStyleType="disc" paddingLeft="4" gap="2">
								{instalment.journals.map((j) => (
									<Inline gap="1" as="li" key={j.id}>
										{j.description}

										<Text color="muted">
											(<RelativeTime timestamp={j.created_at} />)
										</Text>
									</Inline>
								))}
							</Stack>
						</Stack>
					</>
				) : null}
				{showSiblings && instalments?.length > 1 ? (
					<>
						<Divider sm />
						<Table
							headers={[
								"# ID",
								"Due",
								<Box>Amount ({currency})</Box>,
								"Status",
							]}
							bordered
							caption="All instalments for this payment"
							hover
							responsive
							alignCols={{ 2: "right" }}
							rows={instalments.map(
								({
									id,
									due_at,
									due_at_local,
									currency,
									amount,
									paid_at,
									verified_at,
								}) => [
									<Box>
										{id}
										{instalment.id === id ? (
											<Badge primary>Selected</Badge>
										) : null}
									</Box>,
									<Box>
										<Time timestamp={due_at} localTimestamp={due_at_local} />
									</Box>,
									<Money amount={amount} currency={currency} />,
									<Box>
										{paid_at ? (
											<Time timestamp={paid_at}>
												<Badge success outlined>
													Paid
												</Badge>
											</Time>
										) : isBefore(
												localOrUtcTimestampToLocalDate(due_at_local, due_at)
										  ) ? (
											<Badge warning outlined>
												Overdue
											</Badge>
										) : (
											<Badge outlined>Due</Badge>
										)}
										{verified_at ? (
											<Time timestamp={verified_at}>
												<Badge success outlined>
													Verified
												</Badge>
											</Time>
										) : null}
									</Box>,
								]
							)}
						>
							<tfoot>
								<tr>
									<TableFooterDataCell colSpan={2} textAlign="right">
										Total ({currency})
									</TableFooterDataCell>
									<TableFooterDataCell textAlign="right">
										<Money amount={payment.amount} currency={currency} />
									</TableFooterDataCell>
									<TableFooterDataCell>
										<Badge success outlined title="Paid Amount">
											+
											<Money
												amount={instalments
													.filter((i) => i.paid_at)
													.reduce<number>(
														(paid, { amount }) => paid + Number(amount),
														0
													)}
												currency={currency}
											/>
										</Badge>
										<Badge warning outlined title="Due Amount">
											<Money
												amount={instalments
													.filter((i) => !i.paid_at)
													.reduce<number>(
														(paid, { amount }) => paid + Number(amount),
														0
													)}
												currency={currency}
											/>
										</Badge>
									</TableFooterDataCell>
								</tr>
							</tfoot>
						</Table>
					</>
				) : null}
			</Stack>
			<Inline gap="4" alignItems="center" flexWrap="wrap">
				{instalment.can_log_payment && !disableLogging ? (
					<LogTransaction
						instalment={instalment}
						onChange={() => {
							mutate()
							onChange?.()
						}}
					>
						{({ log }) => (
							<Button onClick={log} level="primary">
								Log Payment
							</Button>
						)}
					</LogTransaction>
				) : null}
				{!readOnly && instalment.can_delete ? (
					<Button
						status="warning"
						onClick={() => {
							if (
								window.confirm(
									"Are you sure you want to delete this instalment ?"
								)
							) {
								XHR(xhr).deleteInstalment(instalment.id).then(onChange)
							}
						}}
					>
						<Icons.Trash /> Delete
					</Button>
				) : null}
				{!readOnly && instalment.can_verify_payment ? (
					<VerifyPaymentInDialog
						instalment={instalment}
						onSuccess={() => {
							mutate()
							onChange?.()
						}}
					>
						{({ verify }) => (
							<Button level="primary" onClick={() => verify()}>
								<Icons.BadgeCheckSolid /> Verify Payment
							</Button>
						)}
					</VerifyPaymentInDialog>
				) : null}
				{!readOnly && instalment.can_unverify_payment ? (
					<VerifyPaymentInDialog
						instalment={instalment}
						revert
						onSuccess={() => {
							mutate()
							onChange?.()
						}}
					>
						{({ verify }) => (
							<Button
								level="secondary"
								status="primary"
								onClick={() => verify()}
							>
								<Icons.Reply /> Unverify Payment
							</Button>
						)}
					</VerifyPaymentInDialog>
				) : null}
				{instalment.can_revert_payment ? (
					<Component initialState={false}>
						{({ state, setState }) => (
							<Button
								status="warning"
								level="tertiary"
								disabled={state}
								onClick={() => {
									const comments = window.prompt(
										"Please provide a reason to revert this payment."
									)
									if (comments && comments.trim()) {
										setState(true)
										XHR(xhr)
											.revertInstalmentTransactions(instalment.id, {
												comments,
											})
											.then((resp) => {
												showSnackbar("Reverted successfully.")
												onChange?.()
												return resp
											})
											.catch((e) => {
												if (e.message) {
													alert("Error: " + e.message)
												}
											})
											.then(() => {
												mutate()
												setState(false)
											})
									}
								}}
							>
								<Icons.Refresh /> {state ? "Reverting..." : "Revert Payment"}
							</Button>
						)}
					</Component>
				) : null}
			</Inline>
		</Stack>
	)
}

type VerifyPaymentFormProps = {
	instalment: IInstalment
	onCancel?: () => void
	onSubmit: (data: Array<number>) => Promise<void>
}

type VerifyPaymentProps = Omit<VerifyPaymentFormProps, "onSubmit"> & {
	onSuccess?: () => void
	revert?: boolean
}

function VerifyPayment({
	onSuccess,
	instalment,
	revert,
	...props
}: VerifyPaymentProps) {
	const xhr = useXHR()
	const id = useId()
	const { data } = useSWR(`/instalments/${instalment.id}?${id}`, () =>
		XHR(xhr).show(instalment.id)
	)
	if (!data) {
		return <Spinner alignCenter padding="4" />
	}
	if (!revert) {
		return (
			<VerifyPaymentForm
				instalment={data.data}
				onSubmit={async (instalments) => {
					await XHR(xhr).verifyPaidInstalment(instalments)
					showSnackbar("Verified successfully.")
					onSuccess?.()
				}}
				{...props}
			/>
		)
	}
	return (
		<UnverifyPaymentForm
			instalment={data.data}
			onSubmit={async (instalments) => {
				await XHR(xhr).unverifyPaidInstalment(instalments)
				showSnackbar("Unverified successfully.")
				onSuccess?.()
			}}
			{...props}
		/>
	)
}

export function VerifyPaymentInDialog({
	onSuccess,
	children,
	...props
}: Omit<VerifyPaymentProps, "onCancel"> & {
	children: (props: { verify: () => void }) => React.ReactNode
}) {
	const [isOpen, open, close] = useDialog()
	return (
		<>
			{children({ verify: open })}
			{isOpen ? (
				<Dialog
					open={isOpen}
					onClose={close}
					title={`${props.revert ? "Unverify" : "Verify"} Payment`}
				>
					<Dialog.Body>
						<VerifyPayment
							{...props}
							onCancel={() => close()}
							onSuccess={() => {
								onSuccess?.()
								close()
							}}
						/>
					</Dialog.Body>
				</Dialog>
			) : null}
		</>
	)
}

const verifyPaymentDataValidationSchema = Validator.object().shape({
	instalments: Validator.array().required(),
	confirmed: Validator.boolean()
		.equals([true], "Please confirm that this is a verified transaction.")
		.required("Please confirm that this is a verified transaction."),
})

function VerifyPaymentForm({
	instalment,
	onSubmit,
	onCancel,
}: VerifyPaymentFormProps) {
	const initialValues = useRef({
		instalments: [instalment.id],
		confirmed: false,
	})
	return (
		<Form<typeof initialValues.current>
			initialValues={initialValues.current}
			validationSchema={verifyPaymentDataValidationSchema}
			onSubmit={withServerErrors(async (values) => {
				if (!values.confirmed) {
					throw new Error(
						"Please select the checkbox to confirm that this is a verified transaction."
					)
				}
				await onSubmit(values.instalments)
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="2">
						<Heading as="h4">Payment Details</Heading>
						<Text color="muted">
							Please review the instalment and transaction details.
						</Text>
						<Box
							paddingX="6"
							padding="4"
							borderWidth="1"
							rounded="md"
							bgColor="primary"
							borderColor="primary"
						>
							<InstalmentOverview instalment={instalment} />
						</Box>
					</Stack>
					<Divider sm />
					<Stack gap="4">
						<Stack gap="2">
							<Heading as="h4">Confirmation</Heading>
							<Text color="muted">
								Please provide your confirmation regarding this transaction.
							</Text>
						</Stack>
						<Box padding="6" boxShadow="inner" bgColor="subtle" rounded="lg">
							<SwitchInputField
								name="confirmed"
								label="I have verified with accounts that this is a valid transaction."
							/>
						</Box>
						<Alert status="warning">
							After verification, you can not update any details regarding this
							instalment and this transaction.
						</Alert>
					</Stack>
					<Divider />
					<Stack gap="4">
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								<Icons.BadgeCheckSolid /> I understand, this is verified
							</Button>
							{onCancel ? (
								<Button
									disabled={submitting}
									onClick={() => onCancel()}
									level="tertiary"
								>
									Cancel
								</Button>
							) : null}
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}

function UnverifyPaymentForm({
	instalment,
	onSubmit,
	onCancel,
}: VerifyPaymentFormProps) {
	const initialValues = useRef({
		instalments: [instalment.id],
		confirmed: false,
	})
	return (
		<Form<typeof initialValues.current>
			initialValues={initialValues.current}
			onSubmit={withServerErrors(async (values) => {
				await onSubmit(values.instalments)
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="2">
						<Heading as="h4">Payment Details</Heading>
						<Text color="muted">
							Please review the instalment and transaction details.
						</Text>
						<Box
							paddingX="6"
							padding="4"
							borderWidth="1"
							rounded="md"
							bgColor="primary"
							borderColor="primary"
						>
							<InstalmentOverview instalment={instalment} />
						</Box>
					</Stack>
					<Divider sm />
					<Alert status="warning">
						This will only remove the{" "}
						<Badge success>
							<Icons.BadgeCheckSolid /> verified
						</Badge>{" "}
						status and <b>WILL NOT</b> update the transaction details.
					</Alert>
					<Divider />
					<Stack gap="4">
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								<Icons.Reply /> I understand, Unverify it.
							</Button>
							{onCancel ? (
								<Button
									disabled={submitting}
									onClick={() => onCancel()}
									level="tertiary"
								>
									Cancel
								</Button>
							) : null}
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}

function InstalmentOverview({ instalment }: { instalment: IInstalment }) {
	return (
		<Stack gap="6">
			<Inline flexWrap="wrap" gap={{ xs: "4", md: "6" }}>
				<Stack gap="1">
					<Box fontSize="sm" color="muted">
						Amount ({instalment.currency})
					</Box>
					<Box fontWeight="semibold">
						<Money amount={instalment.amount} currency={instalment.currency} />
					</Box>
				</Stack>
				{instalment.currency !== instalment.txn_currency &&
				instalment.txn_amount_money ? (
					<Stack gap="1">
						<Text fontSize="sm" color="muted">
							Amount ({instalment.txn_currency})
						</Text>
						<Box>
							<Money
								fontWeight="semibold"
								money={instalment.txn_amount_money}
							/>
						</Box>
					</Stack>
				) : null}
				{instalment.paid_at ? (
					<Stack gap="1">
						<Box fontSize="sm" color="muted">
							Paid On
						</Box>
						<Box fontWeight="semibold">
							<Time value={utcTimestampToLocalDate(instalment.paid_at)} />
						</Box>
					</Stack>
				) : null}
				{instalment.paid_by ? (
					<Stack gap="1">
						<Box fontSize="sm" color="muted">
							Logged By
						</Box>
						<Box fontWeight="semibold">{instalment.paid_by.name}</Box>
					</Stack>
				) : null}
			</Inline>
			<Inline flexWrap="wrap" gap={{ xs: "4", md: "6" }}>
				{instalment.debit_accounts?.length ? (
					<Box>
						<Box fontSize="sm" marginBottom="1">
							Debit {pluralize("Account", instalment.debit_accounts.length)}
						</Box>
						<Box fontWeight="semibold">
							{instalment.debit_accounts.map((a) => a.name).join(", ")}
						</Box>
					</Box>
				) : null}
				{instalment.credit_accounts?.length ? (
					<Box>
						<Box fontSize="sm" marginBottom="1">
							Credit {pluralize("Account", instalment.credit_accounts.length)}
						</Box>
						<Box fontWeight="semibold">
							{instalment.credit_accounts.map((a) => a.name).join(", ")}
						</Box>
					</Box>
				) : null}
				<Stack gap="1">
					<Box fontSize="sm" color="muted">
						Reference Id
					</Box>
					{instalment.reference_id ? (
						<Box fontWeight="semibold">{instalment.reference_id}</Box>
					) : (
						<Box color="muted">Not Provided</Box>
					)}
				</Stack>
			</Inline>
			<Inline gap="4">
				{instalment.verified_by ? (
					<Stack gap="1">
						<Box fontSize="sm" color="muted">
							<Icons.BadgeCheckSolid /> Verified On
						</Box>
						<Box fontWeight="semibold">
							<Time timestamp={instalment.verified_at} />
						</Box>
					</Stack>
				) : null}
				{instalment.verified_by ? (
					<Stack gap="1">
						<Box fontSize="sm" color="muted">
							Verified By
						</Box>
						<Box fontWeight="semibold">{instalment.verified_by.name}</Box>
					</Stack>
				) : null}
			</Inline>
		</Stack>
	)
}
