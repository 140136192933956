import {
	Box,
	Button,
	Inline,
	CheckboxInput,
	Icons,
	Alert,
	Component,
	TabContent,
	TabItem,
	Tabs,
	TabsList,
	Text,
	Stack,
} from "@sembark-travel/ui/base"
import { ButtonLink } from "@sembark-travel/ui/router"
import { Dialog } from "@sembark-travel/ui/dialog"
import { ShareViaWhatsApp } from "@sembark-travel/ui/share"
import {
	utcTimestampToLocalDate,
	formatDate,
	getDiff,
	localOrUtcTimestampToLocalDate,
} from "@sembark-travel/datetime-utils"
import { withOrdinalSuffix } from "@sembark-travel/number-utils"
import { useXHR } from "@sembark-travel/xhr"
import pluralize from "pluralize"
import { useCallback, useMemo, useState } from "react"
import { useAuthUser, useHasFeatureFlag } from "../Auth"
import { collect } from "../utils"
import {
	getDaywiseTransportsAndActivitiesForQuote,
	getMultiplier,
	getUniqueHotels,
} from "./utils"
import { IItinerary, IQuote, ITripQuote } from "./store"
import { generatePath } from "../router-utils"
import {
	addMoneyFromDifferentCurrencies,
	makeCurrencyConverter,
	formatMoneyByIntl,
	moneyParseByDecimal,
	multipleMoneyBy,
	TMoney,
} from "@sembark-travel/money"
import { EmailContent, TEmailInclusionOptions } from "../shared/EmailContent"
import { Required } from "utility-types"
import { ErrorBoundary } from "@sembark-travel/ui/error-boundary"
import {
	TTripQuoteEmailPdfWATemplate,
	useTripQuoteWhatsAppTemplate,
} from "../EmailPdfTemplates"

export function ShareQuoteInDialog({
	quote: tripQuote,
	isValidating,
}: {
	quote: ITripQuote
	isValidating?: boolean
}) {
	const [activeTab, setActiveTab] = useState<undefined | TShareVia>(undefined)
	if (
		!tripQuote.quote ||
		!tripQuote.itinerary ||
		!tripQuote.given_quote ||
		!tripQuote.trip
	) {
		const editItineraryUrl = generatePath(
			"/trips/:tripId/quotes/:quoteId/edit-itinerary",
			{
				tripId: tripQuote.trip_id.toString(),
				quoteId: `${tripQuote.id}`,
			}
		)
		return (
			<Component initialState={false}>
				{({ state, setState }) => (
					<>
						<Button
							data-testid="share_quote"
							status="warning"
							disabled={isValidating}
							onClick={() => {
								setState(true)
							}}
						>
							{isValidating ? <Icons.Refresh spin /> : <Icons.Share />} Share
						</Button>
						<Dialog
							open={state}
							onClose={() => setState(false)}
							title="Share Quote"
							sm
						>
							<Dialog.Body>
								<Inline gap="4">
									<Box>
										<Icons.Attention size="8" color="warning" />
									</Box>
									<Box>
										<Stack gap="2">
											<Text fontSize="md" fontWeight="semibold" color="warning">
												Inclusion/Exclusions or Itinerary is missing from the
												quote.
											</Text>
											<Text>
												You can add or update inclusion/exclusions for a quote
												from the bottom on every quote. You can also click on
												the button below to add the required details.
											</Text>
											<Box>
												<ButtonLink
													to={editItineraryUrl}
													level="primary"
													status="primary"
													onClick={() => {
														setState(false)
													}}
												>
													Add Inclusion/Exclusions
												</ButtonLink>
											</Box>
										</Stack>
									</Box>
								</Inline>
							</Dialog.Body>
							<Dialog.Footer>
								<Button onClick={() => setState(false)}>Cancel</Button>
							</Dialog.Footer>
						</Dialog>
					</>
				)}
			</Component>
		)
	}
	return (
		<>
			<Button
				data-testid="share_quote"
				level="secondary"
				status="primary"
				onClick={() => {
					setActiveTab("whatsapp")
				}}
			>
				<Icons.Share /> Share
			</Button>
			<Dialog
				open={Boolean(activeTab)}
				lg
				onClose={() => setActiveTab(undefined)}
				title="Share Package"
			>
				<ErrorBoundary>
					<ShareQuote
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						quote={
							tripQuote as Required<
								ITripQuote,
								"quote" | "given_quote" | "trip" | "itinerary"
							>
						}
						isValidating={isValidating}
					/>
				</ErrorBoundary>
			</Dialog>
		</>
	)
}

type TShareVia = "whatsapp" | "text" | "rich_text" | "partner_sharing"

function makePackageCurrencyConverterForTripQuote(tripQuote: ITripQuote) {
	const currencyPairs = tripQuote.given_quote?.currency_pairs
	const packageCurrency =
		tripQuote.given_quote?.given_currency || tripQuote.currency
	const convertCurrency = makeCurrencyConverter(currencyPairs || [])
	const multiplier = getMultiplier(tripQuote)
	return function convertMoneyToPackageCurrency(
		money: TMoney | Array<TMoney>,
		dontAddMarkup = false
	): TMoney {
		const amountInPackageCurrency = convertCurrency(
			packageCurrency,
			convertCurrency(tripQuote.currency, money)
		)
		if (dontAddMarkup) return amountInPackageCurrency
		return multipleMoneyBy(amountInPackageCurrency, multiplier)
	}
}

function ShareQuote({
	activeTab,
	setActiveTab,
	quote: tripQuote,
	isValidating,
}: {
	activeTab: undefined | TShareVia
	setActiveTab: (value: TShareVia | undefined) => void
	quote: Required<ITripQuote, "quote" | "given_quote" | "itinerary" | "trip">
	isValidating?: boolean
}) {
	const { quote, given_quote, itinerary, trip, created_after_conversion } =
		tripQuote
	const { user } = useAuthUser()
	const canSharePDF = useHasFeatureFlag("share_quote_pdf")
	const canShareWordDoc = useHasFeatureFlag("share_quote_word_doc")
	const canShareWithPartner = useHasFeatureFlag("quote_partner_sharing")
	const templateSettings = useTripQuoteWhatsAppTemplate()
	const xhr = useXHR()
	const tripQuoteId = tripQuote.id
	const fetchEmailContent = useCallback(
		async (params: object) => {
			return xhr
				.get<{ content: string; options: TEmailInclusionOptions }>(
					`/trip-quotes/${tripQuoteId}/as-email`,
					{
						params,
					}
				)
				.then((resp) => resp.data)
		},
		[tripQuoteId, xhr]
	)

	const allOptions: Array<ITripQuote> = useMemo(
		() =>
			tripQuote.can_share_options
				? [tripQuote as ITripQuote].concat(tripQuote.options || [])
				: [tripQuote],
		[tripQuote]
	)

	const { start_date, start_date_local } = quote
	const currencyPairs = given_quote.currency_pairs
	const convertCurrency = makeCurrencyConverter(currencyPairs || [])

	const convertMoneyToPackageCurrency =
		makePackageCurrencyConverterForTripQuote(tripQuote)

	const {
		file_url,
		word_doc_file_url,
		daywise_schedules,
		inclusion_exclusions,
		terms_and_conditions,
	} = itinerary
	const { used_for_conversion } = given_quote
	const greetings = () => {
		return `Hi ${trip.contact.name},

Greetings from ${trip.tenant_brand?.short_name || user?.tenant?.short_name}.

${
	created_after_conversion
		? `As per our discussion, following is the *updated quote after conversion* details.`
		: used_for_conversion
			? `Thank you for allowing us the opportunity to serve you. Following are the *confirmed quote* details for your reference.`
			: `Thank you for your query with us. As per your requirements, following are the package details.`
}
${
	created_after_conversion
		? `
*After Conversion Updated Quote*
`
		: used_for_conversion
			? `
✅  *Confirmed Quote*
`
			: ``
}`
	}

	const tripDetails = `*Trip ID ${trip.id}*${
		allOptions.length > 1
			? ` _(${pluralize("Package Category/Option", allOptions.length, true)})_`
			: ``
	}
----------
*${trip.destinations.map((d) => d.name).join(", ")} Trip*
• *${formatDate(
		localOrUtcTimestampToLocalDate(quote.start_date_local, quote.start_date),
		"DD MMM, YYYY"
	)}* _for_ *${pluralize("Night", quote.nights, true)}, ${pluralize(
		"Day",
		quote.days,
		true
	)}*
• *${quote.pax_string}*`

	const packagePrice = (tripQuote: ITripQuote) => {
		const { given_quote, quote } = tripQuote
		if (!given_quote) return ``
		const { given_price, given_prices_per_person, gst_included } = given_quote
		const flights_price_in_costing_currency = convertCurrency(
			tripQuote.currency,
			addMoneyFromDifferentCurrencies(
				quote.flights.map(({ given_price, currency }) =>
					moneyParseByDecimal(given_price || 0, currency)
				)
			)
		)
		return `${
			given_prices_per_person?.length
				? `
*Price (${given_quote.given_currency}):*
${given_prices_per_person
	.map(
		({ per_pax_label, total_pax_label, price }) =>
			`• *${formatMoneyByIntl(
				moneyParseByDecimal(Number(price), given_quote.given_currency)
			)} / ${per_pax_label}* x ${total_pax_label}`
	)
	.concat(
		flights_price_in_costing_currency.amount
			? [
					`• *${formatMoneyByIntl(
						convertMoneyToPackageCurrency(
							flights_price_in_costing_currency,
							true
						)
					)} / Flights (inc.)*`,
				]
			: []
	)
	.join("\n")}`
				: ``
		}
*Total${
			!given_prices_per_person?.length
				? ` Price (${given_quote.given_currency})`
				: ``
		}: ${formatMoneyByIntl(
			moneyParseByDecimal(Number(given_price), given_quote.given_currency)
		)} /-*${given_quote.tax_label ? ` _(${gst_included ? "inc." : "exc."} ${given_quote.tax_label})_` : ""}\n\n${given_quote.customer_remarks ? `*_Note_*\n-------\n${given_quote.customer_remarks}\n-------\n` : ""}`
	}

	return (
		<>
			{terms_and_conditions?.deleted_at ? (
				<Alert
					status="warning"
					title="Disabled Terms and Conditions"
					rounded="none"
				>
					TnC used in this quote has been disabled. Please edit
					Inclusions/Exclusions and select valid terms and conditions before
					sharing.
				</Alert>
			) : null}
			<Tabs layout="col">
				<TabsList>
					<TabItem
						active={activeTab === "whatsapp"}
						onClick={() => setActiveTab("whatsapp")}
					>
						WhatsApp
					</TabItem>
					<TabItem
						active={activeTab === "rich_text"}
						onClick={() => setActiveTab("rich_text")}
					>
						Email
					</TabItem>
					{canShareWithPartner &&
					(tripQuote.created_after_conversion ||
						tripQuote.used_for_conversion) ? (
						<TabItem
							active={activeTab === "partner_sharing"}
							onClick={() => setActiveTab("partner_sharing")}
						>
							Partner
						</TabItem>
					) : null}
				</TabsList>
				<TabContent active={activeTab === "whatsapp"}>
					<Component
						initialState={{
							incExc:
								!canSharePDF &&
								!canShareWordDoc &&
								Boolean(inclusion_exclusions?.length),
							itinerary: !canSharePDF && !canShareWordDoc,
							pdfLink: false,
							wordDocLink: canSharePDF ? false : canShareWordDoc,
							otherInfo:
								!canSharePDF &&
								!canShareWordDoc &&
								Boolean(itinerary.other_info),
							component_prices_breakup: false,
							tnc: false,
						}}
					>
						{({ state, setState }) => {
							const whatsappContent = `${([] as Array<string>)
								.concat([
									greetings(),
									tripDetails,
									...allOptions.map((tripQuote, index, arr) => {
										const { quote } = tripQuote
										const convertMoneyToPackageCurrency =
											makePackageCurrencyConverterForTripQuote(tripQuote)
										return [
											arr.length > 1
												? `⏬ *OPTION ${index + 1}: ${tripQuote.name}*`
												: ``,
											packagePrice(tripQuote),
											getContentForHotels(quote, {
												includePrice: state.component_prices_breakup,
												convertMoneyToPackageCurrency,
											}).whatsApp,
										]
											.map((t) => t.trim())
											.filter(Boolean)
											.join("\n\n")
									}),
									allOptions.length > 1
										? "-------\n⏩ *For All Options*\nDetails below are applicable for all the options.\n-------"
										: "",
									getContentForCabs(
										quote,
										{
											includePrice: state.component_prices_breakup,
											convertMoneyToPackageCurrency,
										},
										templateSettings?.transport_and_activity_section_settings
									).whatsApp,
									getContentForFlights(quote, {
										includePrice: state.component_prices_breakup,
										convertMoneyToPackageCurrency: (
											money: TMoney | Array<TMoney>
										) => convertMoneyToPackageCurrency(money, true),
									}).whatsApp,
									getContentForOtherExtras(quote, {
										includePrice: state.component_prices_breakup,
										convertMoneyToPackageCurrency,
									}).whatsApp,
									state.incExc ? getContentForIncExc(itinerary).whatsApp : ``,
									state.itinerary
										? getContentForDaywiseItinerary(
												itinerary,
												start_date,
												start_date_local,
												templateSettings?.daywise_schedule_section_settings
											).whatsApp
										: ``,
									state.tnc && itinerary.terms_and_conditions
										? `*${templateSettings?.terms_section_settings?.title || "Terms and Conditions"}*\n-------\n${itinerary.terms_and_conditions.description}`
										: ``,
									state.otherInfo && itinerary.other_info
										? `*Other Information*\n-------\n${itinerary.other_info}`
										: ``,
									(state.pdfLink && canSharePDF) ||
									(state.wordDocLink && canShareWordDoc)
										? `For full quote details, please checkout the document linked below.`
										: ``,
								])
								.map((t) => t.trim())
								.filter(Boolean)
								.join("\n\n")}`
							return (
								<Box position="relative">
									<Box paddingX="4" paddingY="2">
										<Text fontSize="sm">
											<Icons.LightBulb opacity="70" /> Use toggles to customize
											the content according to your needs.
										</Text>
									</Box>
									<Box
										position="sticky"
										top="0"
										padding="4"
										zIndex="10"
										bgColor="subtle"
										borderBottomWidth="1"
										boxShadow="sm"
									>
										<Box display="flex" flexWrap="wrap" gap="4">
											{inclusion_exclusions?.length ? (
												<Box as="label">
													<CheckboxInput
														checked={state.incExc}
														onChange={() =>
															setState({
																...state,
																incExc: !state.incExc,
															})
														}
													/>{" "}
													Inc/Exc
												</Box>
											) : null}
											{daywise_schedules?.length ? (
												<Box as="label">
													<CheckboxInput
														checked={state.itinerary}
														onChange={() =>
															setState({
																...state,
																itinerary: !state.itinerary,
															})
														}
													/>{" "}
													Itinerary
												</Box>
											) : null}
											{tripQuote.is_root &&
											(allOptions.length === 1 ||
												allOptions.every(
													function hasOnlyHotelService(tripQuote) {
														const { quote } = tripQuote
														return !(
															quote.cabs?.length ||
															quote.travel_activities?.length ||
															quote.transport_extras?.length ||
															quote.flights?.length ||
															quote.other_extras?.length
														)
													}
												)) ? (
												<Box as="label">
													<CheckboxInput
														checked={state.component_prices_breakup}
														onChange={() =>
															setState({
																...state,
																component_prices_breakup:
																	!state.component_prices_breakup,
															})
														}
													/>{" "}
													Prices Breakup
												</Box>
											) : null}
											{file_url && canSharePDF ? (
												<Box as="label">
													<CheckboxInput
														checked={state.pdfLink}
														onChange={() =>
															setState({
																...state,
																pdfLink: !state.pdfLink,
																wordDocLink: false,
															})
														}
													/>{" "}
													PDF
												</Box>
											) : null}
											{!canSharePDF && word_doc_file_url && canShareWordDoc ? (
												<Box as="label">
													<CheckboxInput
														checked={state.wordDocLink}
														onChange={() =>
															setState({
																...state,
																pdfLink: false,
																wordDocLink: !state.wordDocLink,
															})
														}
													/>{" "}
													Word Doc
												</Box>
											) : null}
											{itinerary.terms_and_conditions ? (
												<Box as="label">
													<CheckboxInput
														checked={state.tnc}
														onChange={() =>
															setState({
																...state,
																tnc: !state.tnc,
															})
														}
													/>{" "}
													Terms
												</Box>
											) : null}
											{itinerary.other_info ? (
												<Box as="label">
													<CheckboxInput
														checked={state.otherInfo}
														onChange={() =>
															setState({
																...state,
																otherInfo: !state.otherInfo,
															})
														}
													/>{" "}
													Other Info
												</Box>
											) : null}
											{isValidating ? (
												<Box>
													<Icons.Refresh spin />
												</Box>
											) : null}
											{file_url && canSharePDF ? (
												<Box marginLeft="auto">
													<Button
														as="a"
														target="_blank"
														rel="noopener noreferrer"
														href={file_url}
														level="primary"
														size="sm"
													>
														Download PDF
													</Button>
												</Box>
											) : null}
										</Box>
									</Box>
									<Box
										key={whatsappContent}
										roundedBottom="md"
										overflow="hidden"
										position="relative"
									>
										{state.pdfLink ? (
											<Alert
												status="warning"
												title="PDF Links NOT Recommended. Instead, download and share the PDF file."
												rounded="none"
											>
												<Stack gap="4">
													<Text>
														Links, shared to new customers are,{" "}
														<b>NOT clickable</b> and WhatsApp might{" "}
														<b>block the chat</b> because of recent policy
														changes. This also increases message ignorance due
														to security concerns.
													</Text>
													<Text color="default">
														<Icons.LightBulb /> Instead, download the PDF and
														share it as a separate message. This also adds
														Preview Thumnails and improves click-through rate.
													</Text>
												</Stack>
											</Alert>
										) : null}
										<ShareViaWhatsApp
											text={whatsappContent}
											url={
												state.pdfLink
													? file_url
													: state.wordDocLink
														? word_doc_file_url
														: undefined
											}
											phoneNumber={trip?.contact?.phone_numbers}
											trackingContentType="quotes"
											trackingContentId="guest"
											disabled={isValidating}
										/>
									</Box>
								</Box>
							)
						}}
					</Component>
				</TabContent>
				<TabContent active={activeTab === "rich_text"} position="relative">
					<Box paddingX="4" paddingY="2">
						<Text fontSize="sm">
							<Icons.LightBulb opacity="70" /> Use toggles to customize the
							content according to your needs.
						</Text>
					</Box>
					<Box style={{ minHeight: "90vh" }}>
						{activeTab === "rich_text" ? (
							<EmailContent
								paddingY="4"
								fetchContent={fetchEmailContent}
								contentCacheKey={`/trip-quotes/${tripQuote.id}/as-email`}
								inclusionOptionsCacheKey={`trip_source_${String(
									trip?.trip_source.id || ""
								)}_quote_email_share_inclusions`}
								copyLabel="Copy"
								copyTrackId="quotes"
								downloadUrl={`/trip-quotes/${tripQuote.id}/as-email`}
							/>
						) : null}
					</Box>
				</TabContent>
				<TabContent
					active={activeTab === "partner_sharing"}
					position="relative"
				>
					<Box paddingX="4" paddingY="2">
						<Text fontSize="sm">
							<Icons.LightBulb opacity="70" /> Use toggles to customize the
							content according to your needs.
						</Text>
					</Box>
					<Box style={{ minHeight: "90vh" }}>
						{activeTab === "partner_sharing" ? (
							<PartnerSharing quote={tripQuote} />
						) : null}
					</Box>
				</TabContent>
			</Tabs>
		</>
	)
}

function getContentForHotels(
	quote: IQuote,
	config: {
		includePrice: boolean
		convertMoneyToPackageCurrency: (money: TMoney | Array<TMoney>) => TMoney
	}
) {
	const { hotels, hotel_extras } = quote
	const { includePrice, convertMoneyToPackageCurrency } = config
	return {
		get whatsApp() {
			return !hotels?.length
				? ``
				: `🏨  *_Hotels_*
-----------
${getUniqueHotels(hotels, config)
	.map(
		({ hotel, nights, roomsAndMealPlans, similar_hotel_options }) => `*${nights
			.map((n) =>
				withOrdinalSuffix(
					getDiff(n, utcTimestampToLocalDate(quote.start_date), "days") + 1
				)
			)
			.join(", ")} ${pluralize("Night", nights.length)}* _at_ *${collect(
			[hotel]
				.concat(
					similar_hotel_options ? similar_hotel_options.map((s) => s.hotel) : []
				)
				.map((hotel) => hotel.location.short_name)
		)
			.unique()
			.toArray()
			.join("/")}*
_Check-in on ${formatDate(nights[0], "DD MMM")}_
*${hotel.name}*${hotel.stars_string ? ` (${hotel.stars_string})` : ``}
${
	roomsAndMealPlans.length === 1
		? `${[
				roomsAndMealPlans[0].meal_plan.description,
				`${roomsAndMealPlans[0].no_of_rooms} ${roomsAndMealPlans[0].room_type.display_name}` +
					` (${roomsAndMealPlans[0].room_configuration?.description})`,
			]
				.filter(Boolean)
				.join(" • ")}${getContentForHotelRoomPrice({
				roomsAndMealPlan: roomsAndMealPlans[0],
				noOfNights: nights.length,
				convertMoneyToPackageCurrency,
			})}`
		: roomsAndMealPlans
				.map(
					(r) =>
						`- *${r.nights
							.map((n) =>
								withOrdinalSuffix(
									getDiff(
										n,
										utcTimestampToLocalDate(quote.start_date),
										"days"
									) + 1
								)
							)
							.join(",")} ${pluralize("Night", r.nights.length)}:* ${
							r.meal_plan.description
						}, ${r.no_of_rooms} ${r.room_type.display_name} (${
							r.room_configuration?.description
						})${getContentForHotelRoomPrice({
							roomsAndMealPlan: r,
							noOfNights: r.nights.length,
							convertMoneyToPackageCurrency,
						})}`
				)
				.join("\n\n")
}${
			similar_hotel_options?.length
				? `\n*Similar Options:*\n\`\`\`-\`\`\` ` +
					similar_hotel_options
						.map(
							(option) =>
								`*${option.hotel.name}*${
									option.hotel.stars_string
										? ` (${option.hotel.stars_string})`
										: ``
								} ${
									option.hotel.location.name !== hotel.location.name
										? `\n\`\`\`•\`\`\` ${option.hotel.location.short_name}`
										: ``
								}${
									roomsAndMealPlans.length === 1 &&
									(roomsAndMealPlans[0].room_type.id !== option.room_type.id ||
										roomsAndMealPlans[0].no_of_rooms !== option.no_of_rooms)
										? `\n\`\`\`•\`\`\` ${option.no_of_rooms} ${option.room_type.display_name} (${roomsAndMealPlans[0].room_configuration?.description})`
										: ``
								}`
						)
						.join(`\n\`\`\`-\`\`\` `)
				: ``
		}`
	)
	.join("\n\n")}${
						hotel_extras?.length
							? `

*Hotel Special Inclusions*
-------
${hotel_extras
	.map(
		(ex) =>
			`${
				ex.date
					? `*${withOrdinalSuffix(
							getDiff(
								utcTimestampToLocalDate(ex.date),
								utcTimestampToLocalDate(quote.start_date),
								"days"
							) + 1
						)} Night* - `
					: ``
			}*${ex.service.name}* (${ex.hotel.name})${
				includePrice && ex.given_price
					? `
  • *${formatMoneyByIntl(
		convertMoneyToPackageCurrency(
			moneyParseByDecimal(ex.given_price, ex.currency)
		),
		{ showCurrency: true, roundOff: true }
	)} /-*`
					: ``
			}`
	)
	.join("\n")}`
							: ``
					}`
		},
	}
}

function getContentForHotelRoomPrice({
	roomsAndMealPlan,
	noOfNights,
	convertMoneyToPackageCurrency,
}: {
	roomsAndMealPlan: ReturnType<
		typeof getUniqueHotels
	>[number]["roomsAndMealPlans"][number]
	noOfNights: number
	convertMoneyToPackageCurrency: (money: TMoney | Array<TMoney>) => TMoney
}) {
	if (!roomsAndMealPlan.given_price) return ``
	function formatToPackageCurrency(
		amount: number,
		config?: Parameters<typeof formatMoneyByIntl>[1]
	): string {
		return formatMoneyByIntl(
			convertMoneyToPackageCurrency(
				moneyParseByDecimal(amount, roomsAndMealPlan.currency || "INR")
			),
			{
				roundOff: true,
				...config,
			}
		)
	}
	const perRoomPrices = [
		roomsAndMealPlan.per_room_given_price
			? `${formatToPackageCurrency(
					roomsAndMealPlan.per_room_given_price
				)} Per Room ${roomsAndMealPlan.room_configuration?.per_room_details}`
			: "",
		roomsAndMealPlan.per_adult_with_extra_bed_given_price
			? `${formatToPackageCurrency(
					roomsAndMealPlan.per_adult_with_extra_bed_given_price
				)} Per Adult with Extra Bed/Mattress ${
					roomsAndMealPlan.room_configuration?.per_adult_with_extra_bed_details
				}`
			: "",
		roomsAndMealPlan.per_child_with_extra_bed_given_price
			? `${formatToPackageCurrency(
					roomsAndMealPlan.per_child_with_extra_bed_given_price
				)} Per Child with Extra Bed/Mattress ${
					roomsAndMealPlan.room_configuration?.per_child_with_extra_bed_details
				}`
			: "",
		roomsAndMealPlan.per_child_without_extra_bed_given_price
			? `${formatToPackageCurrency(
					roomsAndMealPlan.per_child_without_extra_bed_given_price
				)} Per Child without Extra Bed/Mattress ${
					roomsAndMealPlan.room_configuration
						?.per_child_without_extra_bed_details
				}`
			: "",
	].filter((str): str is string => Boolean(str))
	return `
*Prices:* ${
		perRoomPrices.length
			? `
• ${perRoomPrices.join("\n• ")}
= *Total:* `
			: ``
	}*${formatToPackageCurrency(roomsAndMealPlan.given_price, {
		showCurrency: true,
	})} ${
		noOfNights > 1
			? ` x ${noOfNights}N =  ${formatToPackageCurrency(
					roomsAndMealPlan.given_price * noOfNights
				)}`
			: ``
	}/-*`
}

function getContentForCabs(
	quote: IQuote,
	config: {
		includePrice: boolean
		convertMoneyToPackageCurrency: (money: TMoney | TMoney[]) => TMoney
	},
	templateSettings?: TTripQuoteEmailPdfWATemplate["transport_and_activity_section_settings"]
) {
	const { start_date, start_date_local } = quote
	const title =
		templateSettings?.title ||
		`Transportation${quote.travel_activities.length ? " and Activities" : ""}`
	const { includePrice, convertMoneyToPackageCurrency } = config
	const { daywiseServices, extraServiceWithoutDates } =
		getDaywiseTransportsAndActivitiesForQuote({
			transports: quote.cabs,
			transportExtras: quote.transport_extras,
			travelActivities: quote.travel_activities,
		})
	return {
		get whatsApp() {
			return !daywiseServices.length && !extraServiceWithoutDates.length
				? ``
				: `
🚖  *${title}*
-----------
${daywiseServices
	.map(({ date, date_local, transportsAndActivities, extras }) =>
		[
			`*${withOrdinalSuffix(
				getDiff(
					localOrUtcTimestampToLocalDate(date_local, date),
					localOrUtcTimestampToLocalDate(start_date_local, start_date),
					"day"
				) + 1
			)} Day - ${formatDate(localOrUtcTimestampToLocalDate(date_local, date), "ddd, Do MMM")}*`,
		]
			.concat(
				transportsAndActivities.map(({ type, transport, activity }) =>
					type === "transport"
						? `${transport.transport_service.name} _(${transport.cabs
								.map((c) => `${c.no_of_cabs}-${c.cab_type.display_name}`)
								.join(" + ")})_`
						: `${activity.activity.name}${
								activity.ticket_type
									? ` - ${activity.ticket_type.display_name}`
									: ``
							} _(${
								activity.ticket_tourist_configurations_short_name ||
								activity.ticket_tourist_configurations
									.map(
										({ configuration, quantity }) =>
											`${quantity} ${configuration.name}`
									)
									.join(" + ")
							})_`
				)
			)
			.concat(extras.map(({ service }) => `${service.name}`))
			.join("\n• ")
	)
	.join("\n\n")}${
						extraServiceWithoutDates.length
							? `

---
*Other Inclusions*
${extraServiceWithoutDates
	.map(({ service }) => `${service.name}`)
	.join("\n• ")}`
							: ``
					}${
						includePrice
							? `

*Price: ${formatMoneyByIntl(
									convertMoneyToPackageCurrency(
										addMoneyFromDifferentCurrencies(
											(
												quote.cabs as Array<{
													given_price?: number
													currency: string
												}>
											)
												.concat(
													quote.travel_activities.flatMap(
														(a) => a.ticket_tourist_configurations
													)
												)
												.concat(quote.transport_extras)
												.map(({ given_price, currency }) =>
													moneyParseByDecimal(given_price || 0, currency)
												)
										)
									),
									{
										roundOff: true,
										showCurrency: true,
									}
								)} /-*`
							: ``
					}`
		},
	}
}

function getContentForOtherExtras(
	quote: IQuote,
	config: {
		includePrice: boolean
		convertMoneyToPackageCurrency: (money: TMoney | Array<TMoney>) => TMoney
	}
) {
	const { other_extras } = quote
	const { includePrice, convertMoneyToPackageCurrency } = config
	return {
		get whatsApp() {
			return !other_extras?.length
				? ``
				: `
🪝  *_Other Special Inclusions_*
-------
${other_extras
	.map(
		(ex) =>
			`${
				ex.date
					? `*${withOrdinalSuffix(
							getDiff(
								utcTimestampToLocalDate(ex.date),
								utcTimestampToLocalDate(quote.start_date),
								"days"
							) + 1
						)} Day* - `
					: ``
			}*${ex.service.name}*${
				includePrice && ex.given_price
					? `\n• *Price: ${formatMoneyByIntl(
							convertMoneyToPackageCurrency(
								moneyParseByDecimal(ex.given_price, ex.currency)
							),
							{ showCurrency: true, roundOff: true }
						)} /-*`
					: ``
			}`
	)
	.join("\n")}`
		},
	}
}

function getContentForIncExc(itinerary: IItinerary) {
	const { inclusion_exclusions } = itinerary
	return {
		get whatsApp() {
			return !inclusion_exclusions?.length
				? ``
				: `*_Inclusions_*
-----------
${inclusion_exclusions
	.filter((inc) => inc.inclusion)
	.map(
		(inc) =>
			`+ ${
				inc.category
					? `${inc.category} ${inc.is_boolean ? "" : ` : ${inc.inclusion}`}`
					: `${inc.inclusion}`
			}${inc.comments ? `\n_${inc.comments}_` : ``}`
	)
	.join("\n")}

*_Exclusions_*
-----------
${inclusion_exclusions
	.filter((inc) => inc.exclusion)
	.map(
		(inc) =>
			`- ${
				inc.category
					? `${inc.category} ${inc.is_boolean ? "" : ` : ${inc.exclusion}`}`
					: `${inc.exclusion}`
			}${inc.comments ? `\n_${inc.comments}_` : ``}`
	)
	.join("\n")}
_*NOTE*: Anything not in inclusions is excluded_`
		},
	}
}

function getContentForDaywiseItinerary(
	itinerary: IItinerary,
	start_date: string,
	start_date_local: string | undefined,
	templateSettings: TTripQuoteEmailPdfWATemplate["daywise_schedule_section_settings"]
) {
	const hideTravelDistance = Boolean(templateSettings?.hide_travel_distance)
	const title = templateSettings?.title
	const { daywise_schedules } = itinerary
	return {
		get whatsApp() {
			let previousDay: number | undefined = undefined
			return !daywise_schedules.length
				? ``
				: `${
						title
							? `
🗓️   *_${title}_*`
							: ``
					}
${daywise_schedules
	.map((schedule) => {
		const day =
			getDiff(
				localOrUtcTimestampToLocalDate(
					schedule.start_date_local,
					schedule.start_date
				),
				localOrUtcTimestampToLocalDate(start_date_local, start_date),
				"days"
			) + 1
		const text = `${
			day !== previousDay
				? `----------\n*${withOrdinalSuffix(day)} Day - ${formatDate(
						localOrUtcTimestampToLocalDate(
							schedule.start_date_local,
							schedule.start_date
						),
						"dddd Do MMMM, YYYY"
					)}*\n----\n*${schedule.title}*`
				: `+ *${schedule.title}*`
		}${schedule.description ? `\n${schedule.description}` : ""}${
			(schedule.distance && !hideTravelDistance) || schedule.duration
				? `\n_- ` +
					[
						schedule.distance && !hideTravelDistance
							? `Approx. Distance: ${schedule.distance} km`
							: ``,
						schedule.duration ? `Est. Travel Time: ${schedule.duration}` : null,
					]
						.filter(Boolean)
						.join(" • ") +
					`_`
				: ``
		}
`
		previousDay = day
		return text
	})
	.join("\n")}`
		},
	}
}

function getContentForFlights(
	quote: IQuote,
	config: {
		includePrice: boolean
		convertMoneyToPackageCurrency: (money: TMoney | Array<TMoney>) => TMoney
	}
) {
	const { flights } = quote
	const { includePrice, convertMoneyToPackageCurrency } = config
	return {
		get whatsApp() {
			return !flights?.length
				? ``
				: `
🛩️   *_Flights_*
-----------
${flights
	.map(
		(flight) => `*${flight.source.name}* (${formatDate(
			localOrUtcTimestampToLocalDate(
				flight.departs_at_local,
				flight.departs_at
			),
			"D MMM, YYYY [at] HH:mm [hrs]"
		)})
✈️  *${flight.destination.name}* (${formatDate(
			localOrUtcTimestampToLocalDate(
				flight.arrives_at_local,
				flight.arrives_at
			),
			"D MMM, YYYY [at] HH:mm [hrs]"
		)})
${[
	flight.airline.name,
	flight.number_plate,
	flight.category_class,
	flight.no_of_adults ? pluralize("Adult", flight.no_of_adults, true) : null,
	flight.children ? pluralize("Child", flight.children, true) : null,
	flight.infants ? pluralize("Infant", flight.infants, true) : null,
]
	.filter(Boolean)
	.join(" • ")}`
	)
	.join("\n\n")}${
						includePrice
							? `

*Price: ${formatMoneyByIntl(
									convertMoneyToPackageCurrency(
										addMoneyFromDifferentCurrencies(
											(
												flights as Array<{
													currency: string
													given_price?: number
												}>
											).map(({ currency, given_price }) =>
												moneyParseByDecimal(given_price || 0, currency)
											)
										)
									),
									{
										showCurrency: true,
										roundOff: true,
									}
								)} /-*`
							: ``
					}`
		},
	}
}

function PartnerSharing({ quote: tripQuote }: { quote: ITripQuote }) {
	const { trip } = tripQuote
	const xhr = useXHR()
	const tripQuoteId = tripQuote.id
	const fetchContent = useCallback(
		async (params: object) => {
			return xhr
				.get<{
					content: string
					options: TEmailInclusionOptions
				}>(`/trip-quotes/${tripQuoteId}/as-email`, {
					params: {
						...params,
						partner_sharing: 1,
					},
				})
				.then((resp) => resp.data)
		},
		[xhr, tripQuoteId]
	)
	return (
		<EmailContent
			paddingY="4"
			copyTrackId="quote_partner"
			contentCacheKey={`/trip-quotes/${tripQuote.id}/as-email/partner`}
			fetchContent={fetchContent}
			inclusionOptionsCacheKey={`quote_partner_sharing-source_${trip?.trip_source?.id}`}
		/>
	)
}
