import { TColorStyles } from "./css/color.css"
import { TSizeStyles } from "./css/size.css"
import { TTransformStyles } from "./css/transform.css"
import { TAnimationStyles } from "./css/animations.css"
import { Box, BoxOwnProps } from "./Box"
import type { ElementType, ForwardedRef } from "react"
import { forwardRef } from "react"
import type {
	PolymorphicForwardRefExoticComponent,
	PolymorphicPropsWithoutRef,
	PolymorphicPropsWithRef,
} from "./polymorphic-types"

const DefaultIconElement = "svg"

export type IconOwnProps = { title?: string; spin?: boolean } & TSizeStyles &
	TColorStyles &
	Omit<TTransformStyles, "transform"> &
	TAnimationStyles &
	Pick<BoxOwnProps, "opacity" | "transition">

export type IconProps<T extends React.ElementType = typeof DefaultIconElement> =
	PolymorphicPropsWithRef<IconOwnProps, T>

export const Icon: PolymorphicForwardRefExoticComponent<
	IconOwnProps,
	typeof DefaultIconElement
> = forwardRef(function Text<T extends ElementType = typeof DefaultIconElement>(
	{
		as,
		title,
		children,
		size = "4",
		spin,
		...restProps
	}: PolymorphicPropsWithoutRef<IconOwnProps, T>,
	ref: ForwardedRef<Element>
) {
	const Element: ElementType = as || DefaultIconElement
	return (
		<Box
			animation={spin ? "spin" : undefined}
			{...restProps}
			as={Element}
			ref={ref}
			xmlns="http://www.w3.org/2000/svg"
			display="inlineBlock"
			size={size}
		>
			{title ? <title>{title}</title> : null}
			{children}
		</Box>
	)
})

export function AirplaneIcon(props: IconProps) {
	return (
		<Icon {...props} viewBox="0 0 24 24" fill="currentColor">
			<path d="M16.5 14.25l-3.214-3.214L21 5.25 18.75 3 9.108 6.858 6.072 3.822c-.875-.875-2.097-1.085-2.715-.466-.62.619-.41 1.841.465 2.716l3.036 3.035L3 18.75 5.25 21l5.786-7.715L14.25 16.5V21h2.25l1.125-3.376L21 16.498v-2.25h-4.5v.001z" />
		</Icon>
	)
}

export function AnnotationIcon(props: IconProps) {
	return (
		<Icon {...props} viewBox="0 0 24 24" fill="none" stroke="currentColor">
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
				d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
			/>
		</Icon>
	)
}

export function ArrowExpandIcon(props: IconProps) {
	return (
		<Icon {...props} viewBox="0 0 24 24" fill="none" stroke="currentColor">
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
				d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
			/>
		</Icon>
	)
}

export function AttentionIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
		>
			<path d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
		</Icon>
	)
}

export function AttentionSolidIcon(props: IconProps) {
	return (
		<Icon {...props} viewBox="0 0 20 20" fill="currentColor">
			<path
				d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
				clipRule="evenodd"
				fillRule="evenodd"
			/>
		</Icon>
	)
}

export function BadgeCheckSolidIcon(props: IconProps) {
	return (
		<Icon {...props} viewBox="0 0 20 20" fill="currentColor">
			<path
				d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
				clipRule="evenodd"
				fillRule="evenodd"
			/>
		</Icon>
	)
}

export function BanIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			viewBox="0 0 24 24"
			fill="none"
			strokeWidth={1.5}
			stroke="currentColor"
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636"
			/>
		</Icon>
	)
}

export function BedIcon(props: IconProps) {
	return (
		<Icon {...props} viewBox="0 0 24 24" fill="currentColor">
			<path d="M5.252 12.742h15.181a.51.51 0 01.394.173.64.64 0 01.173.393v3.937h-2.252v-2.252H5.252v2.252H3V6.568a.56.56 0 01.173-.41.49.49 0 01.394-.157h1.118c.158 0 .289.053.394.158a.61.61 0 01.173.41v6.173zm5.055-2.804c0-.62-.22-1.15-.661-1.59a2.169 2.169 0 00-1.59-.662c-.62 0-1.15.22-1.591.662a2.11 2.11 0 00-.646 1.59c.01.62.226 1.15.646 1.59.42.442.95.662 1.59.662.64 0 1.17-.22 1.59-.661.42-.441.641-.971.662-1.59zM21 12.19v-.567c0-.934-.33-1.732-.992-2.393-.662-.662-1.454-.987-2.378-.977h-6.19a.534.534 0 00-.393.158.593.593 0 00-.173.393v3.386H21z" />
		</Icon>
	)
}

export function BusIcon(props: IconProps) {
	return (
		<Icon {...props} viewBox="0 0 24 24" fill="currentColor">
			<path d="M7.495 15.034c0-.354-.147-.656-.441-.904a1.576 1.576 0 00-1.05-.372c-.407 0-.764.124-1.072.372-.308.248-.455.55-.44.904.013.354.16.65.44.886s.637.36 1.071.372c.435.012.785-.112 1.05-.372.267-.26.414-.555.442-.886zm12.014 0c0-.354-.147-.656-.441-.904a1.606 1.606 0 00-1.072-.372c-.42 0-.77.124-1.05.372s-.427.55-.44.904c-.015.354.132.65.44.886s.658.36 1.05.372c.393.012.75-.112 1.072-.372.322-.26.469-.555.44-.886zm-.546-3.917l-.84-3.81a.644.644 0 00-.274-.372.809.809 0 00-.462-.142H6.613a.808.808 0 00-.462.142.644.644 0 00-.274.372l-.84 3.81c-.042.19.014.36.168.514a.77.77 0 00.567.23h12.456c.238 0 .427-.076.567-.23a.602.602 0 00.168-.514zm-2.647-6.06a.428.428 0 00-.168-.337.599.599 0 00-.399-.142H8.251a.599.599 0 00-.4.142.428.428 0 00-.167.337c0 .13.056.242.168.336a.599.599 0 00.399.142h7.498a.599.599 0 00.4-.142.428.428 0 00.167-.336zM21 12.234v5.954h-1.491v1.276c0 .343-.147.638-.441.887-.294.248-.651.372-1.072.372-.42 0-.77-.124-1.05-.372-.28-.249-.427-.544-.44-.887v-1.276H7.494v1.276c0 .343-.147.638-.441.887a1.576 1.576 0 01-1.05.372c-.407 0-.764-.124-1.072-.372-.308-.249-.455-.544-.44-.887v-1.276H3v-5.954c0-.744.098-1.483.294-2.215l1.197-4.484c.084-.52.47-.975 1.155-1.364.687-.39 1.583-.68 2.689-.869a23.015 23.015 0 013.675-.301c1.345-.012 2.563.089 3.655.301 1.092.213 1.988.502 2.689.869.7.366 1.085.82 1.155 1.364l1.218 4.484c.182.673.273 1.412.273 2.215z" />
		</Icon>
	)
}

export function CalendarIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
		>
			<path d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
		</Icon>
	)
}

export function ClockIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
			/>
		</Icon>
	)
}

export function CancelIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
		>
			<path d="M6 18L18 6M6 6l12 12" />
		</Icon>
	)
}

export function CancelCircleSolidIcon(props: IconProps) {
	return (
		<Icon {...props} viewBox="0 0 20 20" fill="currentColor">
			<path
				d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
				clipRule="evenodd"
				fillRule="evenodd"
			/>
		</Icon>
	)
}

export function ChevronDownIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
		>
			<path d="M19 9l-7 7-7-7" />
		</Icon>
	)
}

export function ClipboardCopyIcon(props: IconProps) {
	return (
		<Icon {...props} viewBox="0 0 24 24" fill="none" stroke="currentColor">
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
				d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
			/>
		</Icon>
	)
}

export function CogIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
		>
			<path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
			<path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
		</Icon>
	)
}

export function OkCircleSolidIcon(props: IconProps) {
	return (
		<Icon {...props} viewBox="0 0 20 20" fill="currentColor">
			<path
				d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
				clipRule="evenodd"
				fillRule="evenodd"
			/>
		</Icon>
	)
}

export function InfoSolidIcon(props: IconProps) {
	return (
		<Icon {...props} fill="currentColor" viewBox="0 0 20 20">
			<path
				d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
				clipRule="evenodd"
				fillRule="evenodd"
			/>
		</Icon>
	)
}

export function RupeeIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M6 5h12M8 5a4.8 4.8 0 013.6 1.5A5.1 5.1 0 0113 10m0 0a5.1 5.1 0 01-3 4.6c-.5.3-1.4.4-2 .4H6l6 6m1-11H6m7 0h5" />
		</Icon>
	)
}

export function DocumentIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
		</Icon>
	)
}

export function DocumentDownloadIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
		</Icon>
	)
}

export function DotsVerticalIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
		</Icon>
	)
}

export function DuplicateIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
		</Icon>
	)
}

export function DriverIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M7.05 16.05a7 7 0 019.9 0m-9.9 0A7 7 0 005 21h7m-4.95-4.95L12 21m4.95-4.95A7 7 0 0119 21h-7m4.95-4.95L12 21m4-14a4 4 0 11-8 0 4 4 0 018 0z" />
		</Icon>
	)
}

export function SteeringWheelIcon(props: IconProps) {
	return (
		<Icon {...props} fill="currentColor" viewBox="0 0 24 24">
			<path d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 2a8 8 0 0 1 7.38 4.92 29.93 29.93 0 0 0-14.78.02A8 8 0 0 1 12 4Zm-8 8.67 1.11-.13A4.38 4.38 0 0 1 10 16.89v2.85a8 8 0 0 1-6-7.07Zm10 7.07v-2.85a4.38 4.38 0 0 1 4.86-4.35l1.11.13A8 8 0 0 1 14 19.74Z" />
		</Icon>
	)
}

export function FlagIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9" />
		</Icon>
	)
}

export function FlagSolidIcon(props: IconProps) {
	return (
		<Icon {...props} fill="currentColor" viewBox="0 0 20 20">
			<path
				fillRule="evenodd"
				d="M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z"
				clipRule="evenodd"
			/>
		</Icon>
	)
}

export function HomeIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			viewBox="0 0 24 24"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
		>
			<path d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
		</Icon>
	)
}

export function IdentificationIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
		</Icon>
	)
}

export function InfoIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
		</Icon>
	)
}

export function LightBulbIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
		</Icon>
	)
}

export function LightningBoltIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M13 10V3L4 14h7v7l9-11h-7z" />
		</Icon>
	)
}

export function LightningBoltSolidIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			viewBox="0 0 20 20"
			fill="currentColor"
			fillRule="evenodd"
			clipRule="evenodd"
		>
			<path d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" />
		</Icon>
	)
}

export function LocationMarkerIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
			<path d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
		</Icon>
	)
}

export function LoginIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
		</Icon>
	)
}

export function LogoutIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
		</Icon>
	)
}

export function MailIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
		</Icon>
	)
}

export function MapIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
		</Icon>
	)
}

export function MinusCircleIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
		</Icon>
	)
}

export function OkIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M5 13l4 4L19 7" />
		</Icon>
	)
}

export function PaperAirplaneIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
		</Icon>
	)
}

export function PencilIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
		</Icon>
	)
}

export function PhoneIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
		</Icon>
	)
}

export function PhotographIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
		</Icon>
	)
}

export function PlusIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
		</Icon>
	)
}

export function PlusCircleIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
		</Icon>
	)
}

export function QuestionMarkCircleIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
		</Icon>
	)
}

export function RefreshIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
		</Icon>
	)
}

export function ReplyIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
		</Icon>
	)
}

export function SearchIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
		</Icon>
	)
}

export function ShareIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
		</Icon>
	)
}

export function ShieldCheckIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
		</Icon>
	)
}

export function ShieldExclamationIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M20.618 5.984A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016zM12 9v2m0 4h.01" />
		</Icon>
	)
}

export function SlidersIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
		>
			<path d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
		</Icon>
	)
}

export function SpinnerIcon(props: IconProps) {
	return (
		<Icon {...props} fill="currentColor" viewBox="0 0 24 24" animation="spin">
			<path d="M11.957 3c-2.05 0-4.101.702-5.756 2.088 3.112-2.52 7.699-2.34 10.576.558a7.916 7.916 0 011.601 8.912c-.18.522-.09.9.216 1.224.378.36 1.097.414 1.511 0 .054-.054.216-.27.27-.432a9.07 9.07 0 00-2.015-9.704A9.085 9.085 0 0011.957 3zM4.6 7.933a.95.95 0 00-.701.288c-.054.054-.234.27-.27.432a9.035 9.035 0 002.015 9.704c3.327 3.33 8.615 3.51 12.159.558-3.112 2.52-7.699 2.34-10.595-.558-2.41-2.413-2.931-5.995-1.6-8.912.197-.522.108-.9-.216-1.224a1.03 1.03 0 00-.792-.288z" />
		</Icon>
	)
}

export function StarIcon(props: IconProps) {
	return (
		<Icon {...props} fill="currentColor" viewBox="0 0 20 20">
			<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
		</Icon>
	)
}

export function StarEmptyIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			stroke="currentColor"
			viewBox="0 0 24 24"
		>
			<path d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
		</Icon>
	)
}

export function SupportIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			stroke="currentColor"
			viewBox="0 0 24 24"
		>
			<path d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
		</Icon>
	)
}

export function SwitchHorizontalIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			stroke="currentColor"
			viewBox="0 0 24 24"
		>
			<path d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
		</Icon>
	)
}

export function TagsIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			stroke="currentColor"
			viewBox="0 0 24 24"
		>
			<path d="M7 7h.01M7 3h5a1.99 1.99 0 011.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
		</Icon>
	)
}

export function UploadIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			stroke="currentColor"
			viewBox="0 0 24 24"
		>
			<path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
		</Icon>
	)
}

export function UserIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			stroke="currentColor"
			viewBox="0 0 24 24"
		>
			<path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zm-4 7a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
		</Icon>
	)
}

export function TrashIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			stroke="currentColor"
			viewBox="0 0 24 24"
		>
			<path d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
		</Icon>
	)
}

export function UserGroupIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			stroke="currentColor"
			viewBox="0 0 24 24"
		>
			<path d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
		</Icon>
	)
}

export function UsersIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			stroke="currentColor"
			viewBox="0 0 24 24"
		>
			<path d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
		</Icon>
	)
}

export function ViewListIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			stroke="currentColor"
			viewBox="0 0 24 24"
		>
			<path d="M4 6h16M4 10h16M4 14h16M4 18h16" />
		</Icon>
	)
}

export function ArrowLeftIcon(props: IconProps) {
	return (
		<Icon {...props} viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
			<path
				d="M10 19l-7-7m0 0l7-7m-7 7h18"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</Icon>
	)
}

export function MarkdownIcon(props: IconProps) {
	return (
		<Icon {...props} viewBox="0 0 16 16">
			<path
				fillRule="evenodd"
				fill="currentColor"
				d="M14.85 3H1.15C.52 3 0 3.52 0 4.15v7.69C0 12.48.52 13 1.15 13h13.69c.64 0 1.15-.52 1.15-1.15v-7.7C16 3.52 15.48 3 14.85 3zM9 11H7V8L5.5 9.92 4 8v3H2V5h2l1.5 2L7 5h2v6zm2.99.5L9.5 8H11V5h2v3h1.5l-2.51 3.5z"
			></path>
		</Icon>
	)
}

export function InboxInIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			viewBox="0 0 24 24"
			stroke="currentColor"
			strokeWidth={2}
			fill="none"
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M8 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-2m-4-1v8m0 0l3-3m-3 3L9 8m-5 5h2.586a1 1 0 01.707.293l2.414 2.414a1 1 0 00.707.293h3.172a1 1 0 00.707-.293l2.414-2.414a1 1 0 01.707-.293H20"
			/>
		</Icon>
	)
}

export function ArrowTopRightOnSquareIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			viewBox="0 0 24 24"
			stroke="currentColor"
			strokeWidth={1.5}
			fill="none"
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
			/>
		</Icon>
	)
}

export function BellIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			viewBox="0 0 24 24"
			stroke="currentColor"
			strokeWidth={1.5}
			fill="none"
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
			/>
		</Icon>
	)
}

export function BellAlertSolidIcon(props: IconProps) {
	return (
		<Icon {...props} viewBox="0 0 24 24" fill="currentColor">
			<path d="M5.85 3.5a.75.75 0 00-1.117-1 9.719 9.719 0 00-2.348 4.876.75.75 0 001.479.248A8.219 8.219 0 015.85 3.5zM19.267 2.5a.75.75 0 10-1.118 1 8.22 8.22 0 011.987 4.124.75.75 0 001.48-.248A9.72 9.72 0 0019.266 2.5z" />
			<path
				fillRule="evenodd"
				d="M12 2.25A6.75 6.75 0 005.25 9v.75a8.217 8.217 0 01-2.119 5.52.75.75 0 00.298 1.206c1.544.57 3.16.99 4.831 1.243a3.75 3.75 0 107.48 0 24.583 24.583 0 004.83-1.244.75.75 0 00.298-1.205 8.217 8.217 0 01-2.118-5.52V9A6.75 6.75 0 0012 2.25zM9.75 18c0-.034 0-.067.002-.1a25.05 25.05 0 004.496 0l.002.1a2.25 2.25 0 11-4.5 0z"
				clipRule="evenodd"
			/>
		</Icon>
	)
}

export function BankNotesIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			viewBox="0 0 24 24"
			stroke="currentColor"
			strokeWidth={1.5}
			fill="none"
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
			/>
		</Icon>
	)
}

export function LifeBouyIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			viewBox="0 0 24 24"
			stroke="currentColor"
			strokeWidth={1.5}
			fill="none"
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M16.712 4.33a9.027 9.027 0 011.652 1.306c.51.51.944 1.064 1.306 1.652M16.712 4.33l-3.448 4.138m3.448-4.138a9.014 9.014 0 00-9.424 0M19.67 7.288l-4.138 3.448m4.138-3.448a9.014 9.014 0 010 9.424m-4.138-5.976a3.736 3.736 0 00-.88-1.388 3.737 3.737 0 00-1.388-.88m2.268 2.268a3.765 3.765 0 010 2.528m-2.268-4.796a3.765 3.765 0 00-2.528 0m4.796 4.796c-.181.506-.475.982-.88 1.388a3.736 3.736 0 01-1.388.88m2.268-2.268l4.138 3.448m0 0a9.027 9.027 0 01-1.306 1.652c-.51.51-1.064.944-1.652 1.306m0 0l-3.448-4.138m3.448 4.138a9.014 9.014 0 01-9.424 0m5.976-4.138a3.765 3.765 0 01-2.528 0m0 0a3.736 3.736 0 01-1.388-.88 3.737 3.737 0 01-.88-1.388m2.268 2.268L7.288 19.67m0 0a9.024 9.024 0 01-1.652-1.306 9.027 9.027 0 01-1.306-1.652m0 0l4.138-3.448M4.33 16.712a9.014 9.014 0 010-9.424m4.138 5.976a3.765 3.765 0 010-2.528m0 0c.181-.506.475-.982.88-1.388a3.736 3.736 0 011.388-.88m-2.268 2.268L4.33 7.288m6.406 1.18L7.288 4.33m0 0a9.024 9.024 0 00-1.652 1.306A9.025 9.025 0 004.33 7.288"
			/>
		</Icon>
	)
}

export function FingerPrintIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			viewBox="0 0 24 24"
			stroke="currentColor"
			strokeWidth={1.5}
			fill="none"
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33"
			/>
		</Icon>
	)
}

export function ArrowDownLeftIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			viewBox="0 0 24 24"
			stroke="currentColor"
			strokeWidth={1.5}
			fill="none"
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M19.5 4.5l-15 15m0 0h11.25m-11.25 0V8.25"
			/>
		</Icon>
	)
}

export function SparklesSolidIcon(props: IconProps) {
	return (
		<Icon {...props} viewBox="0 0 24 24" fill="currentColor">
			<path
				fillRule="evenodd"
				d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z"
				clipRule="evenodd"
			/>
		</Icon>
	)
}

export function RectangleStackIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			viewBox="0 0 24 24"
			stroke="currentColor"
			strokeWidth={1.5}
			fill="none"
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M6 6.878V6a2.25 2.25 0 0 1 2.25-2.25h7.5A2.25 2.25 0 0 1 18 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 0 0 4.5 9v.878m13.5-3A2.25 2.25 0 0 1 19.5 9v.878m0 0a2.246 2.246 0 0 0-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0 1 21 12v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6c0-.98.626-1.813 1.5-2.122"
			/>
		</Icon>
	)
}

export function TicketIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			viewBox="0 0 24 24"
			stroke="currentColor"
			strokeWidth={1.5}
			fill="none"
		>
			<path
				strokeLinejoin="round"
				strokeLinecap="round"
				d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z"
			/>
		</Icon>
	)
}

export function HandRaisedIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			viewBox="0 0 24 24"
			stroke="currentColor"
			strokeWidth={1.5}
			fill="none"
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M10.05 4.575a1.575 1.575 0 1 0-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 0 1 3.15 0v1.5m-3.15 0 .075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 0 1 3.15 0V15M6.9 7.575a1.575 1.575 0 1 0-3.15 0v8.175a6.75 6.75 0 0 0 6.75 6.75h2.018a5.25 5.25 0 0 0 3.712-1.538l1.732-1.732a5.25 5.25 0 0 0 1.538-3.712l.003-2.024a.668.668 0 0 1 .198-.471 1.575 1.575 0 1 0-2.228-2.228 3.818 3.818 0 0 0-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0 1 16.35 15m.002 0h-.002"
			/>
		</Icon>
	)
}

export function HandThumbUpIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			viewBox="0 0 24 24"
			stroke="currentColor"
			strokeWidth={1.5}
			fill="none"
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
			/>
		</Icon>
	)
}

export function TaxiIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path d="M10 2h4" />
			<path d="m21 8-2 2-1.5-3.7A2 2 0 0 0 15.646 5H8.4a2 2 0 0 0-1.903 1.257L5 10 3 8" />
			<path d="M7 14h.01" />
			<path d="M17 14h.01" />
			<rect width="18" height="8" x="3" y="10" rx="2" />
			<path d="M5 18v2" />
			<path d="M19 18v2" />
		</Icon>
	)
}

export function RefreshClockIcon(props: IconProps) {
	return (
		<Icon
			{...props}
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path d="M3 12a9 9 0 1 0 9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
			<path d="M3 3v5h5" />
			<path d="M12 7v5l4 2" />
		</Icon>
	)
}

export default {
	Airplane: AirplaneIcon,
	Annotation: AnnotationIcon,
	ArrowExpand: ArrowExpandIcon,
	ArrowLeft: ArrowLeftIcon,
	ArrowDownLeft: ArrowDownLeftIcon,
	ArrowTopRightOnSquare: ArrowTopRightOnSquareIcon,
	Attention: AttentionIcon,
	AttentionSolid: AttentionSolidIcon,
	BadgeCheckSolid: BadgeCheckSolidIcon,
	Ban: BanIcon,
	BankNotes: BankNotesIcon,
	Bed: BedIcon,
	Bell: BellIcon,
	BellAlertSolid: BellAlertSolidIcon,
	Bus: BusIcon,
	Calendar: CalendarIcon,
	Cancel: CancelIcon,
	CancelCircleSolid: CancelCircleSolidIcon,
	ChevronDown: ChevronDownIcon,
	ClipboardCopy: ClipboardCopyIcon,
	Clock: ClockIcon,
	Cog: CogIcon,
	Document: DocumentIcon,
	DocumentDownload: DocumentDownloadIcon,
	DotsVertical: DotsVerticalIcon,
	Driver: DriverIcon,
	Duplicate: DuplicateIcon,
	FingerPrint: FingerPrintIcon,
	Flag: FlagIcon,
	FlagSolid: FlagSolidIcon,
	Home: HomeIcon,
	HandRaised: HandRaisedIcon,
	HandThumbUp: HandThumbUpIcon,
	Identification: IdentificationIcon,
	InboxIn: InboxInIcon,
	Info: InfoIcon,
	InfoSolid: InfoSolidIcon,
	LifeBouy: LifeBouyIcon,
	LightBulb: LightBulbIcon,
	LightningBolt: LightningBoltIcon,
	LightningBoltSolid: LightningBoltSolidIcon,
	LocationMarker: LocationMarkerIcon,
	Login: LoginIcon,
	Logout: LogoutIcon,
	Mail: MailIcon,
	Map: MapIcon,
	Markdown: MarkdownIcon,
	MinusCircle: MinusCircleIcon,
	Ok: OkIcon,
	OkCircleSolid: OkCircleSolidIcon,
	PaperAirplane: PaperAirplaneIcon,
	Pencil: PencilIcon,
	Phone: PhoneIcon,
	Photograph: PhotographIcon,
	Plus: PlusIcon,
	PlusCircle: PlusCircleIcon,
	QuestionMarkCircle: QuestionMarkCircleIcon,
	RectangleStack: RectangleStackIcon,
	Refresh: RefreshIcon,
	Reply: ReplyIcon,
	Rupee: RupeeIcon,
	Search: SearchIcon,
	Share: ShareIcon,
	ShieldCheck: ShieldCheckIcon,
	ShieldExclamation: ShieldExclamationIcon,
	Sliders: SlidersIcon,
	SparklesSolid: SparklesSolidIcon,
	Spinner: SpinnerIcon,
	Star: StarIcon,
	StarEmpty: StarEmptyIcon,
	SteeringWheel: SteeringWheelIcon,
	Support: SupportIcon,
	SwitchHorizontal: SwitchHorizontalIcon,
	Tags: TagsIcon,
	Taxi: TaxiIcon,
	Ticket: TicketIcon,
	Trash: TrashIcon,
	Upload: UploadIcon,
	User: UserIcon,
	UserGroup: UserGroupIcon,
	Users: UsersIcon,
	ViewList: ViewListIcon,
	RefreshClock: RefreshClockIcon,
	History: RefreshClockIcon,
}
