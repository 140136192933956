import {
	Box,
	Button,
	Inline,
	Icons,
	Stack,
	Text,
	Heading,
	Col,
	Divider,
	Grid,
	Tip,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import { Link, ButtonLink, queryToSearch } from "@sembark-travel/ui/router"
import { dateToUTCString } from "@sembark-travel/datetime-utils"
import { useXHR, type XHRInstance } from "@sembark-travel/xhr"
import {
	DatePickerField,
	FieldArray,
	Form,
	GetFieldValue,
	SelectField,
	SubmissionError,
	TextInputField,
	withServerErrors,
	EmptyNumberValidator,
	arrayMutators,
	validateFormValues,
	useForm,
} from "@sembark-travel/ui/form"
import { Fragment, useEffect, useMemo } from "react"
import { $PropertyType, Optional, Required } from "utility-types"
import * as Validator from "yup"
import { SelectAccount } from "../Accounting"
import { generatePath } from "../router-utils"
import { IAccount, IJournal } from "./store"
import {
	MoneyInputWithTenantCurrenciesField,
	useFunctionalCurrencyOfTenant,
} from "../Currencies"
import {
	convertMoneyToCurrency,
	currencyPairToHuman,
	formatMoneyByDecimal,
	makeCurrencyPair,
	moneyParseByDecimal,
} from "@sembark-travel/money"

function XHR(xhr: XHRInstance) {
	return {
		async saveTransactions(data: unknown): Promise<Array<IJournal>> {
			return xhr
				.post("/accounting/save-bulk-transactions", data)
				.then((resp) => resp.data.data)
		},
	}
}

interface ILogTransactionProps {
	onCancel?: () => void
	onSuccess: () => void
	initialValues?: Optional<
		$PropertyType<Required<ILogTransactionParams>, "data">[0]
	>
	hideTip?: boolean
}

export function LogTransaction({ onSuccess, ...props }: ILogTransactionProps) {
	const xhr = useXHR()
	return (
		<>
			<LogTransactionForm
				onSubmit={async (data) => {
					await XHR(xhr).saveTransactions(data)
					onSuccess()
				}}
				{...props}
			/>
		</>
	)
}

interface ILogData {
	data: Array<{
		paid_at: string
		narration: string
		reference_id?: string
		debit_account_id: number
		credit_account_id: number
		amount: number
		asset: string
		credit_amount?: number
		credit_asset?: string
	}>
}

interface ILogTransactionParams {
	data?: Array<{
		paid_at: Date | string
		narration: string
		reference_id?: string
		debit_account?: IAccount
		credit_account?: IAccount
		debit_amount: number
		debit_currency: string
		credit_amount?: number
		credit_currency?: string
		debit_to_credit_exchange_rate?: number
	}>
}

export function LogTransactionForm({
	onSubmit,
	onCancel,
	hideTip,
	initialValues: initialValuesProps,
	disableBulk,
	readOnlyAmount,
}: Pick<ILogTransactionProps, "onCancel" | "hideTip" | "initialValues"> & {
	onSubmit: (data: ILogData) => Promise<void>
	disableBulk?: boolean
	readOnlyAmount?: boolean
}) {
	const functionalCurrency = useFunctionalCurrencyOfTenant()
	const initialValues: Required<ILogTransactionParams, "data"> = useMemo(() => {
		return {
			data: [
				{
					debit_amount: 0,
					debit_currency: functionalCurrency,
					credit_amount: 0,
					credit_currency: undefined,
					paid_at: new Date(),
					reference_id: "",
					narration: "",
					debit_account: undefined,
					credit_account: undefined,
					debit_to_credit_exchange_rate: 1,
					...(initialValuesProps || {}),
				},
			],
		}
	}, [initialValuesProps, functionalCurrency])
	return (
		<Form<ILogTransactionParams>
			initialValues={initialValues}
			validate={validateFormValues(
				Validator.object().shape({
					data: Validator.array()
						.min(1, "Please provide atleast one transaction details to log")
						.of(
							Validator.object()
								.required()
								.shape({
									debit_amount: EmptyNumberValidator()
										.positive("Amount should be positive")
										.required("Please enter the amount of transaction"),
									debit_currency: Validator.string().required(
										"Please select a currency"
									),
									paid_at: Validator.date().required(
										"Please provide a date when the payment was made"
									),
									narration: Validator.string()
										.required(
											"Please provide the details/narration for this transaction"
										)
										.max(
											191,
											"Please use 191 or fewer characters for narration"
										),
									reference_id: Validator.string().max(
										191,
										"Please use 191 or fewer characters for reference id"
									),
									debit_account: Validator.object().required(
										"Please select a debit account from which the payment is made"
									),
									credit_account: Validator.object().required(
										"Please select a credit account where the payment is made"
									),
									credit_amount: EmptyNumberValidator().when(
										["debit_currency", "credit_currency"],
										((
											debit_currency: string,
											credit_currency: string | undefined,
											schema: Validator.AnyObjectSchema
										) => {
											if (
												debit_currency &&
												credit_currency &&
												debit_currency !== credit_currency
											) {
												return schema
													.required(
														"Please provide the amount in mentioned currency."
													)
													.positive("Please provide a value.")
											}
											return schema.notRequired()
										}) as never
									),
								})
						),
				})
			)}
			onSubmit={withServerErrors(async (values) => {
				const data: ILogData = {
					data:
						values.data?.map(
							({
								debit_amount,
								debit_currency,
								credit_amount,
								credit_currency,
								narration,
								reference_id,
								debit_account,
								credit_account,
								paid_at,
							}) => {
								if (!debit_account || !credit_account) {
									throw Error(
										"Please fill the required data e.g. debit/credit accounts."
									)
								}
								return {
									amount: debit_amount,
									asset: debit_currency,
									credit_amount:
										!credit_currency || debit_currency === credit_currency
											? debit_amount
											: credit_amount,
									credit_asset:
										!credit_currency || debit_currency === credit_currency
											? debit_currency
											: credit_currency,
									paid_at: paid_at
										? dateToUTCString(paid_at)
										: dateToUTCString(new Date()),
									narration,
									reference_id,
									debit_account_id: debit_account.id,
									credit_account_id: credit_account.id,
								}
							}
						) || [],
				}
				return await onSubmit(data)
			})}
			subscription={{ submitting: true }}
			mutators={{ ...arrayMutators }}
		>
			{({ submitting, handleSubmit, form }) => {
				return (
					<form noValidate onSubmit={handleSubmit}>
						<FieldArray<
							Required<ILogTransactionParams>["data"][number]
						> name="data">
							{({ fields }) => {
								const isBulk = Number(fields.length || 0) > 1
								return (
									<>
										{fields.map((name, index) => {
											return (
												<Fragment key={name}>
													<Grid gap={isBulk ? "4" : undefined}>
														<Col xs={12} sm={isBulk ? true : 12}>
															<Grid gap="4">
																{!isBulk ? (
																	<Col sm={12} md={4}>
																		<Stack gap="1">
																			<Heading as="h4">
																				<Icons.SwitchHorizontal size="6" />{" "}
																				Accounts Involved
																			</Heading>
																			<Text color="muted">
																				Please provide the debit and credit
																				accounts for this transaction.
																			</Text>
																		</Stack>
																	</Col>
																) : null}
																<Col>
																	<Stack gap="4">
																		<GetFieldValue<string>
																			name={`${name}.debit_currency`}
																		>
																			{({ value: debit_currency }) => (
																				<SelectField
																					label="Debit Account"
																					select={SelectAccount}
																					name={`${name}.debit_account`}
																					creatable
																					onChange={(value: IAccount) => {
																						form.change(
																							`${name}.debit_account` as never,
																							value as never
																						)

																						form.change(
																							`${name}.debit_currency` as never,
																							(value.asset_type as never) ||
																								functionalCurrency
																						)

																						if (
																							(value?.asset_type ||
																								functionalCurrency) &&
																							debit_currency
																						) {
																							if (
																								debit_currency !==
																								(value.asset_type ||
																									functionalCurrency)
																							) {
																								form.change(
																									`${name}.credit_amount` as never,
																									0 as never
																								)
																								form.change(
																									`${name}.debit_to_credit_exchange_rate` as never,
																									0 as never
																								)
																							}
																						}
																					}}
																				/>
																			)}
																		</GetFieldValue>
																		<GetFieldValue<string>
																			name={`${name}.credit_currency`}
																		>
																			{({ value: credit_currency }) => (
																				<SelectField
																					label="Credit Account"
																					select={SelectAccount}
																					name={`${name}.credit_account`}
																					creatable
																					onChange={(value: IAccount) => {
																						form.change(
																							`${name}.credit_account` as never,
																							value as never
																						)

																						form.change(
																							`${name}.credit_currency` as never,
																							(value.asset_type as never) ||
																								functionalCurrency
																						)

																						if (
																							(value?.asset_type ||
																								functionalCurrency) &&
																							credit_currency
																						) {
																							if (
																								credit_currency !==
																								(value.asset_type ||
																									functionalCurrency)
																							) {
																								form.change(
																									`${name}.credit_amount` as never,
																									0 as never
																								)
																								form.change(
																									`${name}.debit_to_credit_exchange_rate` as never,
																									0 as never
																								)
																							}
																						}
																					}}
																				/>
																			)}
																		</GetFieldValue>
																	</Stack>
																</Col>
															</Grid>
														</Col>
														{!isBulk ? (
															<Col xs={12}>
																<Divider />
															</Col>
														) : null}
														<Col xs={12} sm={isBulk ? true : 12}>
															<Grid gap="4">
																{!isBulk ? (
																	<Col sm={12} md={4}>
																		<Stack gap="1">
																			<Heading as="h4">
																				<Icons.BankNotes size="6" /> Amount and
																				Dates
																			</Heading>
																			<Text color="muted">
																				Please provide payment amount and the
																				dates of payment along with other
																				informations.
																			</Text>
																		</Stack>
																	</Col>
																) : null}
																<Col>
																	<Stack gap="4">
																		<Grid gap="4">
																			<Col>
																				<GetFieldValue<string>
																					name={`${name}.debit_currency`}
																				>
																					{({ value: debit_currency }) => (
																						<GetFieldValue<string>
																							name={`${name}.credit_currency`}
																						>
																							{({ value: credit_currency }) => (
																								<Inline
																									gap="4"
																									collapseBelow="md"
																								>
																									<Box
																										flex="1"
																										style={{
																											maxWidth: "300px",
																										}}
																									>
																										<GetFieldValue
																											name={`${name}.debit_to_credit_exchange_rate`}
																										>
																											{({
																												value:
																													debit_to_credit_exchange_rate,
																											}) => (
																												<MoneyInputWithTenantCurrenciesField
																													label={`Amount`}
																													name={`${name}.debit_amount`}
																													currencyFieldName={`${name}.debit_currency`}
																													required
																													readOnly={
																														readOnlyAmount
																													}
																													onChange={(e) => {
																														const value =
																															e.currentTarget
																																.value
																														form.change(
																															`${name}.debit_amount` as never,
																															value as never
																														)

																														if (!value) {
																															form.change(
																																`${name}.credit_amount` as never,
																																0 as never
																															)
																														} else if (
																															debit_to_credit_exchange_rate &&
																															credit_currency
																														) {
																															form.change(
																																`${name}.credit_amount` as never,
																																formatMoneyByDecimal(
																																	convertMoneyToCurrency(
																																		moneyParseByDecimal(
																																			Number(
																																				value
																																			) || 0,
																																			debit_currency
																																		),
																																		credit_currency ||
																																			"INR",
																																		Number(
																																			debit_to_credit_exchange_rate ||
																																				0
																																		) || 0
																																	)
																																) as never
																															)
																														}
																													}}
																												/>
																											)}
																										</GetFieldValue>
																									</Box>
																									<GetFieldValue<IAccount>
																										name={`${name}.debit_account`}
																									>
																										{({
																											value: debit_account,
																										}) => (
																											<GetFieldValue<IAccount>
																												name={`${name}.credit_account`}
																											>
																												{({
																													value: credit_account,
																												}) =>
																													debit_account &&
																													credit_account &&
																													debit_currency &&
																													credit_currency &&
																													debit_currency !==
																														credit_currency ? (
																														<GetFieldValue<number>
																															name={`${name}.debit_amount`}
																														>
																															{({
																																value:
																																	debit_amount,
																															}) => (
																																<Inline>
																																	<AmountExchangeRateInputField
																																		debit_amount={
																																			debit_amount
																																		}
																																		debit_currency={
																																			debit_currency
																																		}
																																		credit_currency={
																																			credit_currency
																																		}
																																		name={name}
																																		readOnlyAmount={
																																			readOnlyAmount
																																		}
																																	/>

																																	<Box
																																		paddingX="2"
																																		paddingTop="6"
																																	>
																																		<Icons.SwitchHorizontal />
																																	</Box>
																																	<MoneyInputWithTenantCurrenciesField
																																		label={`Credit Amount`}
																																		name={`${name}.credit_amount`}
																																		currencyFieldName={`${name}.credit_currency`}
																																		type="number"
																																		min={0}
																																		required
																																		placeholder="Amount"
																																		style={{
																																			maxWidth:
																																				"150px",
																																		}}
																																		readOnly={
																																			readOnlyAmount
																																		}
																																		disabled={
																																			debit_currency ===
																																			credit_currency
																																		}
																																		onChange={(
																																			e
																																		) => {
																																			const value =
																																				e
																																					.currentTarget
																																					.value
																																			form.change(
																																				`${name}.credit_amount` as never,
																																				value as never
																																			)

																																			if (
																																				!value
																																			) {
																																				form.change(
																																					`${name}.debit_to_credit_exchange_rate` as never,
																																					0 as never
																																				)
																																			} else if (
																																				debit_currency ===
																																				credit_currency
																																			) {
																																				form.change(
																																					`${name}.debit_to_credit_exchange_rate` as never,
																																					1 as never
																																				)
																																				form.change(
																																					`${name}.credit_amount` as never,
																																					debit_amount as never
																																				)
																																			} else {
																																				form.change(
																																					`${name}.debit_to_credit_exchange_rate` as never,
																																					Number(
																																						Number(
																																							value ||
																																								0
																																						) /
																																							Number(
																																								debit_amount ||
																																									1
																																							)
																																					).toFixed(
																																						3
																																					) as never
																																				)
																																			}
																																		}}
																																	/>
																																</Inline>
																															)}
																														</GetFieldValue>
																													) : null
																												}
																											</GetFieldValue>
																										)}
																									</GetFieldValue>
																								</Inline>
																							)}
																						</GetFieldValue>
																					)}
																				</GetFieldValue>
																			</Col>
																		</Grid>
																		<DatePickerField
																			label="Paid on"
																			name={`${name}.paid_at`}
																			dateFormat
																			timeFormat
																			required
																		/>
																		<TextInputField
																			name={`${name}.narration`}
																			label="Details/Narration"
																			type="text"
																			placeholder="TXN-123212-UPI"
																			max={191}
																			required
																		/>
																		<TextInputField
																			name={`${name}.reference_id`}
																			label="Reference Id"
																			type="text"
																			secondaryLabel="optional"
																			placeholder="Reference Id of the payment"
																			max={191}
																		/>
																	</Stack>
																	{isBulk ? (
																		<Box marginTop="4">
																			<Inline gap="2">
																				<GetFieldValue<
																					Required<ILogTransactionParams>["data"][number]
																				>
																					name={`${name}`}
																				>
																					{({ value }) => (
																						<Button
																							onClick={() => fields.push(value)}
																							size="sm"
																						>
																							<Icons.Duplicate /> Duplicate
																						</Button>
																					)}
																				</GetFieldValue>
																				<Button
																					onClick={() => fields.remove(index)}
																					size="sm"
																					level="tertiary"
																				>
																					<Icons.Cancel /> Remove
																				</Button>
																			</Inline>
																		</Box>
																	) : null}
																</Col>
															</Grid>
														</Col>
													</Grid>
													<Divider />
												</Fragment>
											)
										})}
										<Grid>
											<Col sm={12} md={isBulk ? 12 : { offset: 4, span: 8 }}>
												<Stack gap="4">
													<SubmissionError />
													<Inline gap="4">
														<Button disabled={submitting} type="submit">
															{submitting ? "Saving..." : "Save Details"}
														</Button>
														{!disableBulk && !submitting ? (
															<Button
																onClick={() =>
																	fields.push(initialValues.data[0])
																}
																level="secondary"
																status="primary"
															>
																<Icons.Plus /> Add More
															</Button>
														) : null}
														{onCancel ? (
															<Button
																onClick={onCancel}
																disabled={submitting}
																level="tertiary"
															>
																Cancel
															</Button>
														) : null}
													</Inline>
												</Stack>
											</Col>
										</Grid>
									</>
								)
							}}
						</FieldArray>
						{!hideTip ? (
							<Tip marginTop="16">
								To log instalment's payments, use{" "}
								<Link to={generatePath("/payments/incoming")} color="accent">
									Incoming
								</Link>{" "}
								or{" "}
								<Link to={generatePath("/payments/outgoing")} color="accent">
									Outgoing
								</Link>{" "}
								payments page for convenient and better accounting.
							</Tip>
						) : null}
					</form>
				)
			}}
		</Form>
	)
}

export function LogTransactionDialog({
	open,
	...props
}: ILogTransactionProps & { open: boolean }) {
	return (
		<Dialog open={open} onClose={props.onCancel} lg>
			<Dialog.Header>
				<Inline
					gap="4"
					justifyContent="between"
					paddingRight="6"
					alignItems="center"
				>
					<Dialog.Title>Add Transaction(s)</Dialog.Title>
					<ButtonLink
						status="primary"
						size="sm"
						to={
							generatePath("/accounting/transactions/csv-upload") +
							(props.initialValues?.debit_account
								? queryToSearch({
										account: {
											id: props.initialValues?.debit_account?.id,
											name: props.initialValues?.debit_account?.name,
										},
									})
								: "")
						}
					>
						<Icons.Upload /> CSV
					</ButtonLink>
				</Inline>
			</Dialog.Header>
			<Dialog.Body position="relative">
				<LogTransaction hideTip {...props} />
			</Dialog.Body>
		</Dialog>
	)
}

function AmountExchangeRateInputField({
	debit_amount,
	debit_currency,
	credit_currency,
	name,
	readOnlyAmount,
}: {
	debit_amount: number
	debit_currency: string
	credit_currency: string
	name: string
	readOnlyAmount?: boolean
}) {
	const form = useForm()

	useEffect(() => {
		if (debit_currency === credit_currency) {
			form.change(`${name}.debit_to_credit_exchange_rate` as never, 1)
			form.change(`${name}.credit_amount` as never, debit_amount)
		}
	}, [debit_currency, credit_currency, form, name, debit_amount])

	return (
		<TextInputField
			label={`Exchange (${debit_currency}/${credit_currency})`}
			name={`${name}.debit_to_credit_exchange_rate`}
			type="number"
			placeholder="Exchange Rate"
			min={0}
			required
			style={{
				maxWidth: "150px",
			}}
			readOnly={readOnlyAmount}
			disabled={debit_currency === credit_currency}
			help={({ value }) =>
				value !== "" ? (
					<Text>
						{currencyPairToHuman(
							makeCurrencyPair(
								debit_currency,
								credit_currency || "INR",
								Number(value) || 0
							)
						)}
					</Text>
				) : null
			}
			onChange={(e) => {
				const value = e.currentTarget.value
				form.change(
					`${name}.debit_to_credit_exchange_rate` as never,
					value as never
				)

				if (!value) {
					form.change(`${name}.credit_amount` as never, 0 as never)
				} else {
					form.change(
						`${name}.credit_amount` as never,
						formatMoneyByDecimal(
							convertMoneyToCurrency(
								moneyParseByDecimal(debit_amount || 0, debit_currency),
								credit_currency || "INR",
								Number(value || 0) || 0
							)
						) as never
					)
				}
			}}
		/>
	)
}
