import {
	Alert,
	Text,
	Box,
	Button,
	Inline,
	Icons,
	Stack,
	Col,
	Component,
	Divider,
	Grid,
	Spinner,
	Dropdown,
} from "@sembark-travel/ui/base"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { Dialog } from "@sembark-travel/ui/dialog"
import { useXHR } from "@sembark-travel/xhr"
import {
	Form,
	withServerErrors,
	FileInputField,
	SubmissionError,
	validateFormValues,
	checkFileTypes,
	checkFileSize,
	TextInputField,
} from "@sembark-travel/ui/form"
import { Fragment } from "react"
import * as Validator from "yup"
import { TTenantBrand, useTenantBrand } from "./store"
import { PhoneNumber } from "../Contacts"
import { TenantBrandAddresses } from "./Addresses"
import { Link } from "@sembark-travel/ui/router"
import { generatePath } from "../router-utils"
import { useNavigate } from "react-router-dom"
import { useHasFeatureFlag } from "../Auth"
import { EditTenantBrand } from "./EditItem"
import { TenantBrandBankAccounts } from "./BankAccounts"
import { ImageMediaItem } from "../Media"

export function TenantBrandItem({
	brandId,
	onChange,
}: {
	brandId: string | number
	onChange?: () => void
}) {
	const { tenantBrand, isFetching, error, refresh, isValidating } =
		useTenantBrand(brandId)
	const xhr = useXHR()
	const navigate = useNavigate()
	const hasMultiBranding = useHasFeatureFlag("multi_branding")
	if (isFetching) {
		return <Spinner padding="4" alignCenter />
	}
	if (!tenantBrand) {
		if (error) {
			return <Alert status="error">{error.message}</Alert>
		}
		return null
	}
	return (
		<Box cursor={isValidating ? "wait" : undefined}>
			<Grid gap="8">
				<Col>
					<Box>
						<Box maxWidth="2xl" bgColor="default" rounded="md" borderWidth="1">
							<Box padding="4">
								<Inline gap="4">
									<Stack flex="1" gap="4">
										<Inline gap="4" flexWrap="wrap">
											<Stack gap="1">
												<Text fontWeight="semibold" color="muted">
													Official/Registered Name
												</Text>
												<Text fontSize="md">{tenantBrand.name}</Text>
											</Stack>
											<Stack gap="1">
												<Text fontWeight="semibold" color="muted">
													Display/Brand/Short Name
												</Text>
												<Text fontSize="md">
													{tenantBrand.short_name || tenantBrand.name}
												</Text>
											</Stack>
										</Inline>
										<Inline gap="4" flexWrap="wrap">
											<Stack>
												<Text fontWeight="semibold" color="muted">
													Support Phone Numbers
												</Text>
												<Box fontSize="md">
													{tenantBrand.phone_numbers?.length ? (
														<PhoneNumber value={tenantBrand.phone_numbers} />
													) : (
														<Text as="span" color="muted">
															N/A
														</Text>
													)}
												</Box>
												{tenantBrand.helpline_remarks ? (
													<Text fontSize="sm" color="muted">
														{tenantBrand.helpline_remarks}
													</Text>
												) : null}
											</Stack>
											{tenantBrand.color_theme ? (
												<Stack>
													<Text fontWeight="semibold" color="muted">
														Color Theme
													</Text>
													<Inline gap="2" alignItems="center">
														<Text fontSize="md">{tenantBrand.color_theme}</Text>
														<Box
															as="span"
															display="inlineBlock"
															size="4"
															rounded="full"
															style={{
																backgroundColor: tenantBrand.color_theme,
															}}
														></Box>
													</Inline>
												</Stack>
											) : null}
										</Inline>
										{hasMultiBranding ? (
											<Stack>
												<Text fontWeight="semibold" color="muted">
													Destinations
												</Text>
												<Box>
													{tenantBrand.trip_destinations?.length ? (
														<Text>
															{tenantBrand.trip_destinations.map((d, i) => (
																<Fragment key={d.id}>
																	{i > 0 ? ", " : null}
																	<Link
																		color="accent"
																		to={generatePath(
																			"/trip-destinations/:tripDestinationId",
																			{
																				tripDestinationId: d.id.toString(),
																			}
																		)}
																	>
																		{d.name}
																	</Link>
																</Fragment>
															))}
														</Text>
													) : (
														<Text as="span" color="muted">
															N/A
														</Text>
													)}
												</Box>
											</Stack>
										) : null}
									</Stack>
									<Box>
										<Dropdown alignRight>
											<Dropdown.ToggleButton size="sm" level="tertiary">
												<Icons.DotsVertical />
											</Dropdown.ToggleButton>
											<Dropdown.Menu>
												<Component initialState={false}>
													{({ state, setState }) => (
														<>
															<Dropdown.MenuItem
																onClick={() => setState(true)}
																title="Edit Details"
															>
																<Icons.Pencil /> Edit Details
															</Dropdown.MenuItem>
															<Dialog
																open={state}
																onClose={() => setState(false)}
																title="Edit Profile Details"
															>
																<Dialog.Body>
																	<EditTenantBrand
																		tenantBrand={tenantBrand}
																		onCancel={() => setState(false)}
																		onSuccess={async () => {
																			setState(false)
																			refresh()
																			onChange?.()
																		}}
																	/>
																</Dialog.Body>
															</Dialog>
														</>
													)}
												</Component>
												{tenantBrand.is_primary ? null : (
													<>
														<Dropdown.MenuItemDivider />
														<Component initialState={false}>
															{({ state, setState }) => (
																<>
																	<Dropdown.MenuItem
																		onClick={() => setState(true)}
																		color="warning"
																	>
																		<Icons.Trash /> Delete
																	</Dropdown.MenuItem>
																	<Dialog
																		open={state}
																		onClose={() => setState(false)}
																		title={`Delete Brand: ${tenantBrand.short_name}`}
																	>
																		<Dialog.Body>
																			<Form
																				initialValues={{ short_name: "" }}
																				onSubmit={withServerErrors(
																					async (values) => {
																						if (
																							values.short_name !==
																							tenantBrand.short_name
																						) {
																							throw new Error(
																								"Please type the correct brand name for confirmation"
																							)
																						}
																						await xhr.delete(
																							`/tenant-brands/${tenantBrand.id}`
																						)
																						showSnackbar(
																							"Brand deleted successfully"
																						)
																						navigate("..", { replace: true })
																						onChange?.()
																					}
																				)}
																				subscription={{ submitting: true }}
																			>
																				{({ submitting, handleSubmit }) => (
																					<form
																						onSubmit={handleSubmit}
																						noValidate
																					>
																						<Stack gap="4">
																							<TextInputField
																								name="short_name"
																								label={`Type the brand name '${tenantBrand.short_name}' to confirm`}
																								type="text"
																								placeholder="Type the brand name"
																							/>
																							<Alert
																								status="warning"
																								title="Can NOT be undone!"
																							>
																								This is an irreversable process
																								and once deleted, a brand can
																								not be restored. Are you sure
																								you want to delete this brand ?
																							</Alert>
																							<SubmissionError />
																							<Inline gap="4">
																								<Button
																									type="submit"
																									disabled={submitting}
																									status="warning"
																								>
																									I understand, delete this.
																								</Button>
																								<Button
																									onClick={() =>
																										setState(false)
																									}
																									disabled={submitting}
																								>
																									Cancel
																								</Button>
																							</Inline>
																						</Stack>
																					</form>
																				)}
																			</Form>
																		</Dialog.Body>
																	</Dialog>
																</>
															)}
														</Component>
													</>
												)}
											</Dropdown.Menu>
										</Dropdown>
									</Box>
								</Inline>
							</Box>
						</Box>
					</Box>
				</Col>
				<Col xs={12} sm>
					<Stack gap="4">
						<Box fontSize="md" fontWeight="semibold" color="muted">
							Brand Logo
						</Box>
						<Box>
							<UploadTenantBrandLogo
								tenantBrand={tenantBrand}
								onSuccess={() => {
									refresh()
									showSnackbar("Organization logo updated successfully")
								}}
							/>
						</Box>
					</Stack>
				</Col>
			</Grid>
			<Divider sm />
			<Inline gap="8" collapseBelow="md">
				<Stack gap="1" flex="1">
					<Box fontSize="md" fontWeight="semibold">
						Header Banner/Letterhead Image
					</Box>
					<Text fontSize="sm" color="muted">
						Image to be used as header banner in generated PDFs e.g. Quote,
						Vouchers etc.
					</Text>
					<ImageMediaItem
						hidePlaceholderIcon
						media={tenantBrand.header_media}
						canEdit
						help="Image should be 800x100 (8:1) JPEG/PNG file with less than 1 MB in size."
						canRemove
						crop={false}
						onSubmit={async (data) => {
							await xhr.patch(
								`/tenant-brands/${tenantBrand.id}/header-image`,
								data
							)
							await refresh()
						}}
					/>
				</Stack>
				<Stack gap="1" flex="1">
					<Box fontSize="md" fontWeight="semibold">
						Footer Banner Image
					</Box>
					<Text fontSize="sm" color="muted">
						Image to be used for footer in generated PDFs e.g. Quote, Vouchers
						etc.
					</Text>
					<ImageMediaItem
						hidePlaceholderIcon
						media={tenantBrand.footer_media}
						canEdit
						help="Image should be 800x100 (8:1) JPEG/PNG file with less than 1 MB in size."
						canRemove
						crop={false}
						onSubmit={async (data) => {
							await xhr.patch(
								`/tenant-brands/${tenantBrand.id}/footer-image`,
								data
							)
							await refresh()
						}}
					/>
				</Stack>
			</Inline>
			<Divider sm />
			<TenantBrandAddresses tenantBrand={tenantBrand} onChange={refresh} />
			<Divider sm />
			<TenantBrandBankAccounts tenantBrand={tenantBrand} onChange={refresh} />
		</Box>
	)
}

function UploadTenantBrandLogo({
	tenantBrand,
	onSuccess,
	cancelLabel = "Cancel",
	onCancel,
}: {
	tenantBrand: TTenantBrand
	onSuccess: (tenantBrand: TTenantBrand) => void
	cancelLabel?: string
	onCancel?: () => void
}) {
	const xhr = useXHR()
	const { id, short_name, name, profile_image_url } = tenantBrand
	return (
		<Stack gap="4">
			<Box>
				{profile_image_url ? (
					<Box>
						<img
							src={profile_image_url}
							style={{
								width: "auto",
								maxWidth: "100%",
								height: "150px",
								background: "white",
							}}
							alt={short_name || name}
						/>
					</Box>
				) : (
					<Box
						style={{ width: "200px", height: "200px" }}
						display="flex"
						justifyContent="center"
						alignItems="center"
						bgColor="inset"
						as="label"
						htmlFor="file"
					>
						<Icons.Photograph />
					</Box>
				)}
			</Box>
			<Box>
				<Component initialState={!profile_image_url}>
					{({ state, setState }) =>
						!state ? (
							<Button onClick={() => setState(true)}>Update Logo</Button>
						) : (
							<Form<{ file?: FileList }>
								initialValues={{ file: undefined }}
								validate={validateFormValues(
									Validator.object().shape({
										file: Validator.mixed()
											.required("Please select a file")
											.test(
												"type-is-correct",
												"Please select an png/jpeg image file",
												checkFileTypes(["image/png", "image/jpeg"])
											)
											.test(
												"size-not-big",
												"Please select a file that is less then 1 MB in size",
												checkFileSize(1000)
											),
									})
								)}
								onSubmit={withServerErrors(async (values) => {
									const data = new FormData()
									if (values.file) {
										data.append("file", values.file as never)
									}
									const tenantBrandData = await xhr.post(
										`/tenant-brands/${id}/profile-image`,
										data
									)
									onSuccess(tenantBrandData.data.data)
									setState(false)
								})}
								subscription={{ submitting: true }}
							>
								{({ submitting, handleSubmit }) => (
									<form noValidate onSubmit={handleSubmit}>
										<Stack gap="4">
											<FileInputField
												label="Select an image"
												name="file"
												accept="image/jpeg,image/png"
												help="Image must be 400x400 JPEG/PNG file with less than 500 KB in size."
											/>
											<SubmissionError />
											<Inline gap="4">
												<Button type="submit" disabled={submitting}>
													{submitting ? "Uploading..." : "Upload"}
												</Button>
												<Button
													disabled={submitting}
													onClick={() => {
														setState(false)
														onCancel?.()
													}}
												>
													{cancelLabel}
												</Button>
											</Inline>
										</Stack>
									</form>
								)}
							</Form>
						)
					}
				</Component>
			</Box>
		</Stack>
	)
}
