import type { IInstalment } from "../Payments"
import type { ITag } from "../Tags"
import type { ITrip } from "../Trips"
import type { IUser } from "../Users"
import useSWR from "swr"
import {
	useXHR,
	type IListResponse,
	type XHRInstance,
} from "@sembark-travel/xhr"
import { useId } from "react"
import { T_BankAccount } from "../BankAccounts"

export type TEntityTypes = "tenants" | "users" | "third_party"

export interface IAccount {
	id: number
	name: string
	description?: string
	disabled_at?: string
	is_public: boolean
	postings?: Array<IPosting>
	assets?: Array<IAsset>
	asset_type?: TAssetType
	postings_count?: number
	can_delete?: boolean
	can_disable: boolean
	can_enable: boolean
	can_edit: boolean
	entity_meta?: Array<{ id: number; type: TEntities }>
	created_by: IUser
	updated_by?: IUser
	tags?: Array<ITag>
	types?: Array<IAccountType>
	users?: Array<IUser>
	entity_type: TEntityTypes
	is_suspense: boolean
	bank_accounts_count?: number | null
	bank_accounts?: Array<T_BankAccount>
}

export interface IJournal {
	id: number
	type: string
	narration: string
	short_txn_id: string
	reference_id?: string
	date: string
	created_at: string
	created_by?: IUser
	postings?: Array<IPosting>
	description?: string
	transfered_amount?: number
	transfered_asset_type?: TAssetType
	is_transfer_to_tenant?: boolean
	is_reversal: boolean
	reverted_by_id?: number // reverted by journal
	reversal_of_id?: number // reversal of journal
	reverted_at?: string
	reverted_by_user: IUser
	reversal_comments?: string
	trips?: Array<ITrip>
	instalments?: Array<IInstalment>
}

export interface IPosting {
	id: number
	amount: number
	account: IAccount
	journal: IJournal
	is_credit: boolean
	reference_id?: string
	created_at: string
	created_by?: IUser
	asset_type: string
}

export interface ISuspenseTransaction {
	id: number
	date: string
	amount: number
	narration: string
	debit_account?: string
	credit_account?: string
	reference_id?: string
	created_at: string
	created_by: IUser
}

export type TAssetType = string

export interface IAsset {
	type: TAssetType
	amount: number
	description: string
}

export interface IAccountType {
	id: number
	name: string
	description?: string
}

export type TEntities =
	| "tenants"
	| "hotels"
	| "contacts"
	| "users"
	| "third_party"
	| "trip_sources"
	| "transport_service_providers"
	| "drivers"
	| "all"

export function AccountsXHR(xhr: XHRInstance) {
	return {
		async show(accountId: number | string) {
			return xhr
				.get<{ data: IAccount }>(`/accounting/accounts/${accountId}`)
				.then((resp) => resp.data.data)
		},
		async edit(accountId: number | string) {
			return xhr
				.get<{ data: IAccount }>(`/accounting/accounts/${accountId}/edit`)
				.then((resp) => resp.data.data)
		},
		async update(accountId: number | string, data: unknown) {
			return xhr
				.patch<{ data: IAccount }>(`/accounting/accounts/${accountId}`, data)
				.then((resp) => resp.data.data)
		},
	}
}

export function useAccount(accountId?: number | string) {
	const xhr = useXHR()
	const {
		data: account,
		error,
		mutate: revalidate,
	} = useSWR(accountId ? `/accounting/accounts/${accountId}` : null, () =>
		!accountId
			? Promise.reject("Invalid account ID")
			: AccountsXHR(xhr).show(accountId)
	)
	const isFetching = !account && !error
	return {
		account,
		error,
		mutate: revalidate,
		isFetching,
	}
}

export function useEditAccount(accountId: number | string) {
	const xhr = useXHR()
	const id = useId()
	const {
		data: account,
		error,
		mutate: revalidate,
	} = useSWR(`/accounting/accounts/${accountId}/edit?${id}`, () =>
		AccountsXHR(xhr).edit(accountId)
	)
	const isFetching = !account && !error
	return {
		account,
		error,
		mutate: revalidate,
		isFetching,
	}
}

export function AccountTypesXHR(xhr: XHRInstance) {
	return {
		async get(params?: unknown): Promise<IListResponse<IAccountType>> {
			return xhr
				.get("/accounting/account-types", { params })
				.then((resp) => resp.data)
		},
	}
}
