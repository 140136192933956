import type { SWRConfiguration } from "swr"
import useSWR, { mutate } from "swr"
import {
	useXHR,
	type XHRInstance,
	type IListResponse,
} from "@sembark-travel/xhr"
import type { Optional, Required } from "utility-types"
import type { ITenant } from "../Admin/Tenants"
import type { IComment } from "../Comments"
import type { IFlightService } from "../Flights"
import { IQuoteRequest } from "../QuoteRequests"
import type { ITermsAndCondition } from "../TermsAndConditions"
import type { TTripDestination } from "../TripDestinations"
import type { IUser } from "../Users"
import type { IActivityLog } from "./../ActivityLogs"
import type { ICabType } from "./../CabTypes"
import type { IContact } from "./../Contacts"
import type { IExtraService } from "./../ExtraServices"
import type { IHotelBookingStage } from "./../HotelBookingStages"
import type { IHotel, IHotelMealPlan, IHotelRoomType } from "./../Hotels"
import type { ILocation } from "./../Locations"
import type { IPayment } from "./../Payments"
import type { ITag } from "./../Tags"
import type { ITransportService } from "./../TransportServices"
import type { ITripSource } from "./../TripSources"
import type { ITripStage } from "./../TripStages"
import type { TCurrencyPair } from "@sembark-travel/money"
import type { TTourist, TChildrenArray } from "./../Tourists"
import { childrenToQueryParams } from "./../Tourists"
import type {
	TTravelActivity,
	TTravelActivityTicketType,
	TTravelActivityTicketTouristConfiguration,
} from "../TravelActivities"
import { useId, useCallback } from "react"
import { type IBooking } from "./../HotelBookings"
import { type IScheduledCab } from "./../CabScheduling"
import {
	addUnit,
	dateToUTCString,
	formatDate,
	getDiff,
	isSame,
	localOrUtcTimestampToLocalDate,
	startOf,
	toISOString,
} from "@sembark-travel/datetime-utils"
import {
	TCabPriceCalculationMetric,
	TCabPriceCalculationMetricPreset,
} from "../CabPriceCalculationMetrics"
import { TInclusionExclusionPreset } from "../InclusionExclusionPresets"
import { TInvoice } from "../Invoices"
import { TTenantBrand } from "../Brands"
import type {
	ICalculateHotelPriceParams,
	ICalculateHotelExtraPriceParams,
} from "./../HotelPrices"
import { TTravelActiviytBooking } from "../TravelActivityBookings"
import { TTaxType } from "../TaxTypes"

export interface ITripLatestStage extends ITripStage {
	pivot: {
		created_by: IUser
		created_at: string
	}
}

export interface IQuoteHotelLatestBookingStage extends IHotelBookingStage {
	pivot: {
		created_by: IUser
		created_at: string
		comments: string
	}
}

export interface IQuoteHotel {
	id: number
	quote_id: number
	date: string
	date_local?: string
	hotel_id: number
	hotel: IHotel
	meal_plan_id: number
	meal_plan: IHotelMealPlan
	room_type_id: number
	room_type: IHotelRoomType
	adults_with_extra_bed: number
	children_with_extra_bed: number
	children_without_extra_bed: number
	no_of_rooms: number
	persons_per_room: number
	calculated_price?: number
	currency: string
	given_price: number
	per_room_given_price?: number
	per_adult_with_extra_bed_given_price?: number
	per_child_with_extra_bed_given_price?: number
	per_child_without_extra_bed_given_price?: number
	booked_price: number
	per_room_booked_price?: number
	per_adult_with_extra_bed_booked_price?: number
	per_child_with_extra_bed_booked_price?: number
	per_child_without_extra_bed_booked_price?: number
	comments: string
	booking_stages: IHotelBookingStage[]
	latest_booking_stage?: IQuoteHotelLatestBookingStage
	booked_at?: string
	booking_confirmation_id?: string
	voucher_id?: string
	similar_hotel_options?: Omit<Array<IQuoteHotel>, "similar_hotel_options">
	room_configuration?: {
		no_of_rooms: number
		persons_per_room: number
		adults_with_extra_bed: number
		children_with_extra_bed: number
		children_without_extra_bed: number
		per_room_details: string
		per_adult_with_extra_bed_details: string | null
		per_child_with_extra_bed_details: string | null
		per_child_without_extra_bed_details: string | null
		description: string
		description_short: string
	}
}

export interface IQuoteCab {
	id: number
	quote_id: number
	date: string
	date_local?: string
	cab_type_id: number
	cab_type: ICabType
	transport_service_id: number
	transport_service: ITransportService
	cab_locality?: ILocation
	no_of_cabs: number
	currency: string
	calculated_price?: number
	given_price: number
	booked_price: number
	comments: string
	group_id?: number
	sort_order: number
}

export interface IQuoteTravelActivity {
	id: number
	quote_id: number
	date: string
	date_local?: string
	activity: TTravelActivity
	ticket_type?: TTravelActivityTicketType
	duration?: string
	slot?: string
	quote_cab_id?: number
	comments?: string
	ticket_tourist_configurations: Array<{
		configuration: TTravelActivityTicketTouristConfiguration
		quantity: number
		currency: string
		calculated_price?: number
		per_quantity_given_price?: number
		given_price: number
	}>
	ticket_tourist_configurations_short_name?: string
	group_id?: number
	sort_order: number
}

export interface IQuoteHotelExtras {
	id: number
	service: IExtraService
	date?: string
	date_local?: string
	calculated_price?: number
	currency: string
	given_price?: number
	booked_price?: number
	hotel: IHotel
	comments: string
}

export interface IQuoteTransportExtras {
	id: number
	service: IExtraService
	date?: string
	date_local?: string
	calculated_price?: number
	currency: string
	given_price?: number
	booked_price?: number
	comments: string
}

export interface IQuoteOtherExtras {
	id: number
	service: IExtraService
	date?: string
	date_local?: string
	currency: string
	calculated_price?: number
	given_price?: number
	booked_price?: number
	comments: string
}

export type IQuoteFlight = IFlightService

export interface IDaywiseSchedule {
	id: number
	title: string
	description: string
	distance: number
	duration: string
	travel_info: string
	transport_service_id?: number
	start_date: string
	start_date_local?: string
	end_date: string
	end_date_local: string
}

export interface IInclusionExclusion {
	id: number
	category?: string
	is_boolean?: boolean
	inclusion?: string
	exclusion?: string
	comments?: string
}

export interface IItinerary {
	id: number
	trip_quote_id: number
	other_info?: string
	created_at: string
	daywise_schedules: Array<IDaywiseSchedule>
	inclusion_exclusions: Array<IInclusionExclusion>
	terms_and_conditions?: ITermsAndCondition
	inclusion_exclusion_preset?: TInclusionExclusionPreset
	file_url?: string
	word_doc_file_url?: string
	is_generating: boolean
	created_from_quote_itinerary?: IItinerary
	is_shared?: boolean
}

export interface IQuote {
	id: number
	start_date: string
	start_date_local?: string
	end_date: string
	end_date_local?: string
	days: number
	nights: number
	no_of_adults: number
	no_of_children: number
	children: TChildrenArray
	children_ages: Array<number>
	pax_string: string
	comments: string
	hotels: IQuoteHotel[]
	cabs: IQuoteCab[]
	cabs_tax_percentage?: number
	add_cab_prices_at_once?: boolean
	used_cab_types?: Array<{
		cab_type: ICabType
		no_of_cabs: number
	}>
	cabs_price_calculation_configurations?: Array<{
		id: number
		metric_preset?: TCabPriceCalculationMetricPreset
		services: Array<{
			date?: string
			transport_service: ITransportService
			is_addon: 0 | 1
		}>
	}>
	cabs_price_calculations?: Array<{
		id: number
		cab_type: ICabType
		no_of_cabs: number
		configuration_id: number
		services: Array<{
			transport_service: ITransportService
			date?: string
			is_addon?: 0 | 1
		}>
		metric_quantities: {
			total_kms: number | undefined
			total_days: number
			datewise_total_kms:
				| Array<{ date?: Date | undefined | string; kms: number }>
				| undefined
		}
		currency: string
		tax_percentage?: number
		per_quantity_excluding_tax_given_price: number
		per_quantity_given_price: number
		given_price: number
		metric_wise_prices: Array<{
			id: number
			metric: TCabPriceCalculationMetric
			quantity?: number
			given_quantity: number
			currency: string
			per_quantity_price?: number
			per_quantity_given_price: number
		}>
	}>
	travel_activities: IQuoteTravelActivity[]
	hotel_extras: IQuoteHotelExtras[]
	transport_extras: IQuoteTransportExtras[]
	other_extras: IQuoteTransportExtras[]
	flights: IQuoteFlight[]
	created_by: IUser
	updated_by: IUser
	created_at: string
	trip?: ITrip
	room_configurations?: Array<{
		no_of_rooms: number
		persons_per_room: number
		adults_with_extra_bed: number
		children_with_extra_bed: number
		children_without_extra_bed: number
		per_adult_details: string
		per_adult_with_extra_bed_details: string | null
		per_child_with_extra_bed_details: string | null
		per_child_without_extra_bed_details: string | null
	}>
	currency_pairs: Array<TCurrencyPair>
}

export type TPricePerPersonAllocation = {
	id: string
	type: "adult" | "child"
	per_pax_label: string
	total_pax_label: string
	pax_count: number
	currency: string
	price: string
	ages: Array<number>
	accommodation?: string
	room_sharing_type?: string
}

export interface ITripQuote {
	id: number
	trip_id: number
	quote_id: number
	currency: string
	cost_price: number
	per_adult_cost_price?: number
	per_adult_with_extra_bed_cost_price?: number
	per_child_with_extra_bed_cost_price?: number
	per_child_without_extra_bed_cost_price?: number
	per_person_cost_price_components?: {
		include_children_in_cabs_start_age?: number
		include_children_in_cabs?: boolean | number
		include_adults_with_extra_bed_in_cabs?: boolean
		include_children_in_extras?: boolean
		total?: number
	}
	cost_prices_per_person?: Array<TPricePerPersonAllocation>
	comments?: string
	quote: IQuote
	trip?: ITrip
	given_quote?: IGivenQuote
	itinerary?: IItinerary
	shared_by_tenant?: ITenant
	shared_with_tenant?: ITenant
	readonly: boolean
	is_root: boolean
	created_for_quote_request?: IQuoteRequest
	/**
	 * Converted quote of other business for which this quote was created
	 */
	converted_shared_quote?: Optional<ITripQuote, "quote">
	/**
	 * This quote was created after conversion or not
	 */
	created_after_conversion: boolean
	created_after_holding: boolean
	created_before_conversion: boolean
	used_for_conversion: boolean
	used_for_holding: boolean
	/**
	 * Id of the latest sibling quote
	 */
	latest_sibling_quote_id: number
	/**
	 * Is this the latest quote
	 */
	is_latest: boolean
	options?: Array<ITripQuote>
	name?: string
	can_share_options?: boolean
}

export interface IQuoteDiff {
	changed: boolean
	hotels_changed: boolean
	hotel_extras_changed: boolean
	hotels: Array<
		Omit<IQuoteHotel, "id" | "quote_id"> & {
			removed?: boolean
			added?: boolean
		}
	>
	hotel_extras: Array<
		Omit<IQuoteHotelExtras, "id" | "quote_id"> & {
			removed?: boolean
			added?: boolean
		}
	>
	cabs_changed: boolean
	cabs: Array<
		Omit<IQuoteCab, "id" | "quote_id"> & {
			removed?: boolean
			added?: boolean
		}
	>
	travel_activities_changed: boolean
	travel_activities: Array<
		Omit<IQuoteTravelActivity, "id" | "quote_id"> & {
			removed?: boolean
			added?: boolean
		}
	>
}

export interface IGivenQuote {
	id: number
	trip_quote_id: number
	quote: IQuote
	trip_quote?: ITripQuote
	currency: string
	price: number
	given_currency: string
	given_price: number
	functional_currency: string
	functional_price: number
	currency_pairs: Array<TCurrencyPair>
	per_adult_given_price?: number
	per_adult_with_extra_bed_given_price?: number
	per_child_with_extra_bed_given_price?: number
	per_child_without_extra_bed_given_price?: number
	comments?: string
	customer_remarks?: string
	created_by: IUser
	created_at: string
	locations: Array<ILocation>
	used_for_conversion: boolean
	used_for_holding: boolean
	gst_included: boolean
	tax_percentage?: null | number
	tax_label: string // display name
	tax_name: string // system name
	tax_type: TTaxType
	margins?: null | {
		use_percentage: 0 | 1
		editing_total: 0 | 1
		rounding_point?: number
		total: number
		total_percentage: number
		adult?: number
		adult_percentage?: number
		adult_with_extra_bed?: number
		adult_with_extra_bed_percentage?: number
		child_with_extra_bed?: number
		child_with_extra_bed_percentage?: number
		child_without_extra_bed?: number
		child_without_extra_bed_percentage?: number
		per_person_allocation?: {
			[perPersonAllocationId: string]: number | undefined
		}
	}
	given_prices_per_person?: Array<TPricePerPersonAllocation>
}

export type ILatestGivenQuote = Optional<IGivenQuote, "quote">

export type TArrivalDepartureDetail = {
	id: number
	date_time: string
	date_time_local?: string
	description: string
}

export interface ITrip {
	id: number
	name: string
	start_date: string
	start_date_local?: string
	end_date: string
	end_date_local?: string
	days: number
	nights: number
	destinations: Array<TTripDestination>
	trip_source: ITripSource
	reference_id: string | null
	no_of_adults: number
	no_of_children: number
	children: TChildrenArray
	pax_string: string
	created_at: string
	updated_at: string
	quotes: ITripQuote[]
	latest_given_quote?: ILatestGivenQuote
	given_quotes?: IGivenQuote[]
	tourists?: TTourist[]
	tourist?: TTourist
	trip_source_contact?: IContact
	contact: TTourist
	stages: ITripStage[]
	latest_stage: ITripLatestStage
	converted_at?: string
	converted_by?: IUser
	on_hold_at?: string
	on_hold_by?: IUser
	customer_payments?: IPayment<ITrip>[]
	hotel_payments?: IPayment<IQuoteHotel>[]
	cab_payments?: IPayment<IQuoteCab>[]
	tags?: Array<ITag>
	created_by: IUser
	activity_logs?: Array<IActivityLog>
	sales_team?: Array<IUser>
	reservations_team?: Array<IUser>
	operations_team?: Array<IUser>
	team?: Array<IUser>
	/**
	 * Total number of quotes created for this trip
	 */
	total_quotes: number | null
	/**
	 * Total package (payments) amount after conversion
	 */
	package_amount: number
	/**
	 * Number of quote requested created for this trip
	 */
	quote_requests_count?: number
	/**
	 * Quote request that CREATED this trip
	 */
	owner_quote_request?: IQuoteRequest
	cancellation_reason?: {
		id: number
		reason: string
		contact_remind_at?: string
		comments?: string
		created_at: string
		created_by: IUser
	}
	/**
	 * The quote that can be used for conversion.
	 * This is the quote that is send to other business and there
	 * it was used to convert the trip
	 */
	convertiable_shared_trip_quote?: ITripQuote
	/**
	 * Is trip live ?
	 */
	is_live: boolean
	/**
	 * Is this a past trip (end date passed)
	 */
	is_end_date_passed: boolean
	latest_comment?: IComment
	given_quotes_count?: number
	operated_by_connected_tenant?: ITenant
	/**
	 * Number of hotel bookings for this service
	 */
	hotel_bookings_count?: number
	/**
	 * Number of Transport service bookings for this trip
	 */
	cab_schedules_count?: number
	/**
	 * Number of flight bookings for this trip
	 */
	flight_bookings_count?: number
	/**
	 * Can the user update the trip details
	 */
	can_update: boolean
	/**
	 * Can the user mark the query as canceled
	 */
	can_cancel: boolean
	/**
	 * Can the user mark the trip as dropped
	 */
	can_drop: boolean
	comments_count?: number
	/**
	 * Due payments for this trip
	 */
	due_payments?: Array<IPayment<ITrip>>
	/**
	 * Number of due payments for this trip
	 */
	due_payments_count?: number
	/**
	 * Total Due Payments Amount
	 */
	due_payments_amount?: number
	paid_payments_amount?: number
	payments_amount?: number
	cancellation_charges?: number
	refunding_payments_amount?: number
	paid_refunding_payments_amount?: number
	arrival_details?: Array<TArrivalDepartureDetail>
	departure_details?: Array<TArrivalDepartureDetail>
	tenant_brand?: TTenantBrand
	/**
	 * The status of the the trip, doesn't update based on time
	 */
	status: string
	/**
	 * The current status of the trip, auto changes based on current time
	 */
	current_status?: string

	last_follow_up_at?: string
	deleted_at?: string
	deleted_by?: IUser
}

export function isTripConverted(trip: ITrip): boolean {
	return !!trip.converted_at
}

function XHR(xhr: XHRInstance) {
	return {
		async getQuote(quoteId: number | string): Promise<ITripQuote> {
			return xhr.get(`/trip-quotes/${quoteId}`).then((resp) => resp.data.data)
		},
		async getEditableQuote(quoteId: number | string): Promise<ITripQuote> {
			return xhr
				.get(`/trip-quotes/${quoteId}/edit`)
				.then((resp) => resp.data.data)
		},
	}
}

export function useFetchQuote(
	tripQuoteId: number | string | undefined,
	initialQuote?: ITripQuote,
	options: SWRConfiguration = {}
) {
	const xhr = useXHR()
	return useSWR<ITripQuote | undefined>(
		!tripQuoteId ? null : `/trip-quotes/${tripQuoteId}`,
		() => (tripQuoteId ? XHR(xhr).getQuote(tripQuoteId) : undefined),
		{
			fallbackData: initialQuote,
			...options,
		}
	)
}

export function useFetchEditableQuote(
	tripQuoteId: number | string | undefined,
	options: SWRConfiguration = {}
) {
	const xhr = useXHR()
	const id = useId()
	return useSWR<ITripQuote | undefined>(
		!tripQuoteId ? null : `/trip-quotes/${tripQuoteId}?${id}`,
		() => (tripQuoteId ? XHR(xhr).getEditableQuote(tripQuoteId) : undefined),
		{
			revalidateOnFocus: false,
			...options,
		}
	)
}

export function tripListXHR(xhr: XHRInstance) {
	return {
		async getTrips(
			params?: Record<string, unknown>
		): Promise<IListResponse<ITrip>> {
			return xhr
				.get("/trips", {
					params: {
						...params,
						with: ["given_price"],
					},
				})
				.then((resp) => resp.data)
		},
		async getCount(
			params?: Record<string, unknown>
		): Promise<{ data: number }> {
			return xhr
				.get("/trips-count", {
					params,
				})
				.then((resp) => resp.data)
		},
	}
}

export function tripPaymentsXHR(xhr: XHRInstance) {
	return {
		async payments(tripId: number | string): Promise<{
			can_log_payments: boolean
			customer_payments: Array<IPayment<ITrip>>
			hotel_payments: Array<
				IBooking & {
					payments: Array<IPayment<IBooking>>
				}
			>
			cab_payments: Array<
				IScheduledCab & {
					payments: Array<IPayment<IScheduledCab>>
				}
			>
			travel_activity_booking_payments: Array<
				TTravelActiviytBooking & {
					payments: Array<IPayment<TTravelActiviytBooking>>
				}
			>
			outgoing_payments_for_quote_request: IQuoteRequest & {
				payments: Array<IPayment>
			}
			incoming_payments_from_quote_request: IQuoteRequest & {
				payments: Array<IPayment>
			}
		}> {
			return xhr.get(`/trips/${tripId}/payments`).then((resp) => resp.data)
		},
	}
}

export function tripDetailsXHR(xhr: XHRInstance) {
	return {
		async getTrip(tripId: string | number): Promise<ITrip> {
			return xhr
				.get(`/trips/${tripId}`, {
					params: {
						with: ["latest_given_quote", "owners"],
					},
				})
				.then((resp) => resp.data.data)
		},
	}
}

export type TTripInvoice = TInvoice

export function tripInvoicesXHR(xhr: XHRInstance) {
	return {
		async latest(tripId: number | string) {
			return xhr
				.get<{ data?: TTripInvoice }>(`/trips/${tripId}/invoices/latest`)
				.then((resp) => resp.data)
		},
		async get(tripId: number | string) {
			return xhr
				.get<IListResponse<TTripInvoice>>(`/trips/${tripId}/invoices`)
				.then((resp) => resp.data)
		},
		async store(tripId: number | string, payload: unknown) {
			return xhr
				.post<{ data: TTripInvoice }>(`/trips/${tripId}/invoices`, payload)
				.then((resp) => resp.data.data)
		},
		async update(
			tripId: number | string,
			invoiceId: number | string,
			payload: unknown
		) {
			return xhr
				.patch<{
					data: TTripInvoice
				}>(`/trips/${tripId}/invoices/${invoiceId}`, payload)
				.then((resp) => resp.data.data)
		},
		async destroy(invoiceId: number | string) {
			return xhr
				.delete<{ message: string }>(`/invoices/${invoiceId}`)
				.then((resp) => resp.data)
		},
		async getLocationsOfSupply(params: unknown) {
			return xhr
				.get<{ data: Array<ILocation> }>(`/invoices/locations-of-supply`, {
					params,
				})
				.then((resp) => resp.data.data)
		},
	}
}

export function getCacheKeyForTripDetails(tripId: number | string) {
	return `/api/trips/${tripId}`
}

export function useTripRefresh() {
	const xhr = useXHR()
	const refreshTrip = useCallback(
		async (tripId: string | number) => {
			const trip = await tripDetailsXHR(xhr).getTrip(String(tripId))
			return mutate(getCacheKeyForTripDetails(tripId), trip)
		},
		[xhr]
	)
	return refreshTrip
}

export function useTrip(tripId?: string, config?: SWRConfiguration) {
	const xhr = useXHR()
	const {
		data,
		mutate: revalidate,
		error,
		isValidating,
	} = useSWR(
		tripId ? getCacheKeyForTripDetails(tripId) : null,
		() => (tripId ? tripDetailsXHR(xhr).getTrip(tripId) : Promise.reject()),
		config
	)
	const isFetching = !data && !error
	return {
		trip: data,
		isFetching: isFetching,
		fetchTrip: revalidate,
		isRefreshing: isValidating,
		error,
	}
}

export function getBasicInfoForQueryFromTrip(
	trip: Pick<
		ITrip,
		"start_date" | "start_date_local" | "days" | "no_of_adults" | "children"
	>,
	quoteId: number
) {
	return {
		startDate: formatDate(
			localOrUtcTimestampToLocalDate(trip.start_date_local, trip.start_date),
			"YYYY-MM-DD"
		),
		days: trip.days,
		adults: trip.no_of_adults,
		children: childrenToQueryParams(trip.children || []),
		quoteId,
	}
}

export type IQuoteHotelParams = Array<
	Required<
		Omit<
			Required<ICalculateHotelPriceParams, "hotels">["hotels"][number],
			"similar_hotel_options"
		>,
		| "hotel"
		| "dates"
		| "meal_plan"
		| "room_type"
		| "date_wise_prices"
		| "persons_per_room"
	> & {
		similar_hotel_options?: Array<
			Omit<IQuoteHotelParams[number], "similar_hotel_options">
		>
	}
>

export function getEditableHotels(
	hotels: IQuote["hotels"],
	initialStartDate: Date,
	newStartDate: Date,
	durationInDays: number,
	config?: {
		disablePriceResetsUsingRates?: boolean
	}
): IQuoteHotelParams {
	function transformHotel({
		id,
		date: hotel_date,
		date_local: hotel_date_local,
		room_type,
		adults_with_extra_bed,
		children_with_extra_bed,
		children_without_extra_bed,
		no_of_rooms,
		persons_per_room,
		calculated_price,
		given_price: hotel_given_price,
		per_room_given_price,
		per_adult_with_extra_bed_given_price,
		per_child_with_extra_bed_given_price,
		per_child_without_extra_bed_given_price,
		booked_price: hotel_booked_price,
		per_room_booked_price,
		per_adult_with_extra_bed_booked_price,
		per_child_with_extra_bed_booked_price,
		per_child_without_extra_bed_booked_price,
		comments,
		similar_hotel_options,
		currency,
		room_configuration,
		...hotel
	}: (typeof hotels)[0]) {
		const date = localOrUtcTimestampToLocalDate(hotel_date_local, hotel_date)
		const stayDayDiff = getDiff(date, initialStartDate, "days")
		const newDate =
			stayDayDiff < durationInDays - 1
				? addUnit(newStartDate, stayDayDiff, "days")
				: undefined
		let edited_given_price = config?.disablePriceResetsUsingRates
			? true
			: calculated_price !== hotel_given_price
		let edited_booked_price = config?.disablePriceResetsUsingRates
			? true
			: calculated_price !== hotel_booked_price
		let given_price = Number(hotel_given_price || 0)
		let booked_price = Number(hotel_booked_price || given_price)
		// reset the given price if date has changed
		if (newDate && !isSame(newDate, date, "day")) {
			given_price = 0
			booked_price = 0
			per_room_given_price = 0
			per_adult_with_extra_bed_given_price = 0
			per_child_with_extra_bed_given_price = 0
			per_child_without_extra_bed_given_price = 0
			per_room_booked_price = 0
			per_adult_with_extra_bed_booked_price = 0
			per_child_with_extra_bed_booked_price = 0
			per_child_without_extra_bed_booked_price = 0
			edited_given_price = false
			edited_booked_price = false
		}
		return {
			...hotel,
			dates: newDate ? [newDate] : [],
			room_type,
			adults_with_extra_bed,
			children_with_extra_bed,
			children_without_extra_bed,
			no_of_rooms,
			persons_per_room,
			comments: comments || "",
			date_wise_prices: newDate
				? [
						{
							date: newDate,
							currency,
							price: Number(calculated_price || 0),
							given_price,
							per_room_given_price,
							per_adult_given_price: per_room_given_price
								? Number(per_room_given_price) / Number(persons_per_room || 1)
								: 0,
							per_adult_with_extra_bed_given_price,
							per_child_with_extra_bed_given_price,
							per_child_without_extra_bed_given_price,
							edited_given_price,
							booked_price,
							per_room_booked_price,
							per_adult_with_extra_bed_booked_price,
							per_child_with_extra_bed_booked_price,
							per_child_without_extra_bed_booked_price,
							edited_booked_price,
						},
					]
				: [],
		}
	}
	const hotelParams = hotels.map(({ similar_hotel_options, ...hotel }) => ({
		...transformHotel(hotel),
		similar_hotel_options: similar_hotel_options
			? similar_hotel_options.map(transformHotel)
			: [],
	}))
	return hotelParams.reduce<typeof hotelParams>((hotels, hotel) => {
		// try putting this hotel in the existing array where we have same services
		const foundIndex = hotels.findIndex(
			(h) =>
				h.hotel.id === hotel.hotel.id &&
				h.meal_plan.id === hotel.meal_plan.id &&
				h.room_type.id === hotel.room_type.id &&
				h.no_of_rooms === hotel.no_of_rooms &&
				h.adults_with_extra_bed === hotel.adults_with_extra_bed &&
				h.children_without_extra_bed === hotel.children_without_extra_bed &&
				h.children_with_extra_bed === hotel.children_with_extra_bed &&
				h.persons_per_room === hotel.persons_per_room &&
				h.comments === hotel.comments &&
				// TOOD: Check each attribute to ensure all the similar hotels are same for both hotels
				h.similar_hotel_options?.length === hotel.similar_hotel_options?.length
		)
		if (foundIndex !== -1) {
			const existingHotel = hotels[foundIndex]
			existingHotel.dates = existingHotel.dates.concat(hotel.dates)
			existingHotel.date_wise_prices = existingHotel.date_wise_prices.concat(
				hotel.date_wise_prices
			)
			if (existingHotel.similar_hotel_options?.length) {
				existingHotel.similar_hotel_options =
					existingHotel.similar_hotel_options.map((h, index) => ({
						...h,
						dates: existingHotel.dates,
						date_wise_prices: h.date_wise_prices.concat(
							hotel.similar_hotel_options[index].date_wise_prices
						),
					}))
			}
		} else {
			hotels.push(hotel)
		}
		return hotels
	}, [])
}

export type IQuoteHotelExtraParams = Array<
	Required<
		Required<ICalculateHotelExtraPriceParams>["hotel_extras"][number],
		"service" | "hotel"
	>
>

export function getEditableHotelExtras(
	extras: IQuote["hotel_extras"],
	initialStartDate: Date,
	newStartDate: Date,
	durationInDays: number
): IQuoteHotelExtraParams {
	return extras.map(
		({ given_price, date, date_local, comments, ...others }) => {
			let newDate: Date | undefined = undefined
			if (date) {
				const stayDayDiff = getDiff(
					localOrUtcTimestampToLocalDate(date_local, date),
					initialStartDate,
					"days"
				)
				// remove the dates if it is not withing the duration range
				newDate =
					stayDayDiff < durationInDays
						? startOf(addUnit(newStartDate, stayDayDiff, "days"), "day")
						: undefined
			}
			return {
				...others,
				price: given_price,
				date: date ? newDate : undefined,
				comments: comments || "",
			}
		}
	)
}

export function normalizeHotels(hotels: IQuoteHotelParams) {
	type T_Quote_Hotel = Omit<
		IQuoteHotel,
		"quote_id" | "similar_hotel_options"
	> & {
		similar_hotel_options?: Array<
			Omit<IQuoteHotel, "quote_id" | "similar_hotel_options">
		>
		fetching_prices?: boolean
	}
	return hotels.reduce<Array<T_Quote_Hotel>>(function transformHotel(
		hotels,
		{
			dates,
			fetching_prices,
			date_wise_prices = [],
			similar_hotel_options,
			...otherData
		}
	) {
		dates.forEach((date, i) => {
			const {
				currency,
				price,
				given_price,
				booked_price,
				per_room_given_price,
				per_adult_with_extra_bed_given_price,
				per_child_with_extra_bed_given_price,
				per_child_without_extra_bed_given_price,
				per_room_booked_price,
				per_adult_with_extra_bed_booked_price,
				per_child_with_extra_bed_booked_price,
				per_child_without_extra_bed_booked_price,
			} = date_wise_prices[i] ||
				date_wise_prices[0] || {
					currency: "INR",
					price: 0,
					given_price: 0,
					booked_price: 0,
					per_room_given_price: 0,
					per_adult_with_extra_bed_given_price: 0,
					per_child_with_extra_bed_given_price: 0,
					per_child_without_extra_bed_given_price: 0,
					per_room_booked_price: 0,
					per_adult_with_extra_bed_booked_price: 0,
					per_child_with_extra_bed_booked_price: 0,
					per_child_without_extra_bed_booked_price: 0,
				}
			const hotelStayDateISO = toISOString(startOf(date, "day"))
			hotels.push({
				...otherData,
				similar_hotel_options: similar_hotel_options?.length
					? similar_hotel_options
							.reduce<Array<T_Quote_Hotel>>(transformHotel, [])
							.filter((h) => h.date === hotelStayDateISO)
					: undefined,
				id: Math.random(),
				hotel_id: otherData.hotel.id,
				meal_plan_id: otherData.meal_plan.id,
				room_type_id: otherData.room_type.id,
				date: hotelStayDateISO,
				currency,
				calculated_price: price,
				given_price: given_price || 0,
				per_room_given_price: per_room_given_price || undefined,
				per_adult_with_extra_bed_given_price:
					per_adult_with_extra_bed_given_price || undefined,
				per_child_with_extra_bed_given_price:
					per_child_with_extra_bed_given_price || undefined,
				per_child_without_extra_bed_given_price:
					per_child_without_extra_bed_given_price || undefined,
				booked_price: booked_price || 0,
				per_room_booked_price: per_room_booked_price || undefined,
				per_adult_with_extra_bed_booked_price:
					per_adult_with_extra_bed_booked_price || undefined,
				per_child_with_extra_bed_booked_price:
					per_child_with_extra_bed_booked_price || undefined,
				per_child_without_extra_bed_booked_price:
					per_child_without_extra_bed_booked_price || undefined,
				comments: otherData.comments || "",
				booking_stages: [],
				fetching_prices: Boolean(fetching_prices),
			})
		})
		return hotels
	}, [])
}

export function normalizeHotelExtras(
	hotelExtras: IQuoteHotelExtraParams
): Array<Omit<IQuoteHotelExtras, "quote_id">> {
	return hotelExtras.map(({ date, ...otherData }) => {
		return {
			...otherData,
			id: Math.random(),
			date: date ? toISOString(startOf(date, "day")) : "",
			given_price: otherData.price,
			hotel_id: otherData.hotel?.id,
			comments: otherData.comments || "",
		}
	})
}

export function transformHotelsToRequestData(hotels: IQuoteHotelParams) {
	const normalizedHotels = normalizeHotels(hotels)
	function transform({
		date,
		hotel,
		meal_plan,
		room_type,
		similar_hotel_options,
		persons_per_room,
		no_of_rooms,
		adults_with_extra_bed,
		children_with_extra_bed,
		children_without_extra_bed,
		currency,
		calculated_price,
		given_price,
		per_room_given_price,
		per_adult_with_extra_bed_given_price,
		per_child_with_extra_bed_given_price,
		per_child_without_extra_bed_given_price,
		booked_price,
		per_room_booked_price,
		per_adult_with_extra_bed_booked_price,
		per_child_with_extra_bed_booked_price,
		per_child_without_extra_bed_booked_price,
		comments,
	}: (typeof normalizedHotels)[0]) {
		return {
			date: dateToUTCString(date),
			date_local: formatDate(date, "YYYY-MM-DD"),
			hotel_id: hotel.id,
			meal_plan_id: meal_plan.id,
			room_type_id: room_type.id,
			persons_per_room,
			no_of_rooms,
			adults_with_extra_bed,
			children_with_extra_bed,
			children_without_extra_bed,
			currency,
			calculated_price,
			given_price,
			per_room_given_price,
			per_adult_with_extra_bed_given_price,
			per_child_with_extra_bed_given_price,
			per_child_without_extra_bed_given_price,
			booked_price,
			per_room_booked_price,
			per_adult_with_extra_bed_booked_price,
			per_child_with_extra_bed_booked_price,
			per_child_without_extra_bed_booked_price,
			comments,
			similar_hotel_options,
		}
	}
	return normalizedHotels.map(({ similar_hotel_options, ...hotel }) => ({
		...transform(hotel),
		similar_hotel_options: similar_hotel_options?.length
			? similar_hotel_options.map(transform)
			: [],
	}))
}

export function transformHotelExtrasToRequestData(
	hotelExtras: IQuoteHotelExtraParams
) {
	return normalizeHotelExtras(hotelExtras).map(
		({
			date,
			service,
			hotel,
			given_price,
			currency,
			calculated_price,
			booked_price,
			comments,
		}) => ({
			date: date ? dateToUTCString(date) : "",
			date_local: date ? formatDate(date, "YYYY-MM-DD") : "",
			service: service.name,
			hotel_id: hotel?.id,
			currency,
			calculated_price,
			booked_price,
			comments,
			price: given_price,
		})
	)
}

export function quotesXHR(xhr: XHRInstance) {
	return {
		async getQuotes(
			tripId: number | string,
			params?: unknown
		): Promise<ITripQuote[]> {
			return xhr
				.get(`/trips/${tripId}/quotes`, { params })
				.then((resp) => resp.data.data)
		},
		async getLatestQuoteDiffFromBookingsForTrip(
			quoteId: string | number
		): Promise<IQuoteDiff | undefined> {
			return xhr
				.get(`/latest-quote-diff-from-booking/${quoteId}`)
				.then((resp) => resp.data.data || undefined)
		},
	}
}

export function useDiffBetweenLatestQuoteAndBookings(tripId: number | string) {
	const xhr = useXHR()
	const { data: diff } = useSWR(
		`/latest-quote-diff-after-booking-for-trip/${tripId}`,
		() => quotesXHR(xhr).getLatestQuoteDiffFromBookingsForTrip(tripId),
		{ revalidateOnFocus: false }
	)
	return { diff }
}
