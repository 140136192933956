import {
	Alert,
	Badge,
	Box,
	Button,
	Inline,
	Icons,
	Table,
	Tooltip,
	useId,
	Stack,
	Heading,
	Text,
	Component,
	Divider,
	Dropdown,
	joinAttributes,
	RelativeTime,
	Spinner,
	Time,
	TimeDuration,
	Money,
} from "@sembark-travel/ui/base"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { Dialog } from "@sembark-travel/ui/dialog"
import {
	ButtonLink,
	Link,
	XHRLink,
	useLocationQuery,
} from "@sembark-travel/ui/router"
import {
	areAdvancedFiltersAppliedDefault,
	CursorListView,
	TSearchParams,
	TSortOptions,
	useSearch,
	Search,
} from "@sembark-travel/ui/list"
import {
	utcTimestampToLocalDate,
	fromNow,
	utcTimestampToLocalDateString,
	dateToUTCString,
	startOf,
	dateToQuery,
	parseDateFromQuery,
} from "@sembark-travel/datetime-utils"
import { numberToLocalString } from "@sembark-travel/number-utils"
import { ICursorListResponse, useXHR, XHRInstance } from "@sembark-travel/xhr"
import {
	Form,
	withServerErrors,
	TextInputField,
	validateFormValues,
	SubmissionError,
	DateRangePickerField,
} from "@sembark-travel/ui/form"
import React, { useEffect, useMemo } from "react"
import useSWR from "swr"
import { $PropertyType } from "utility-types"
import * as Validator from "yup"
import { generatePath } from "../router-utils"
import { SelectAccount } from "./Accounts"
import { LogTransactionForm } from "./LogTransaction"
import { IAccount, IJournal } from "./store"
import { SelectField, SwitchInputField } from "@sembark-travel/ui/form"
import config from "../config"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import { useFunctionalCurrencyOfTenant } from "../Currencies"

async function fetchTransactions(
	xhr: XHRInstance,
	params: IFilters
): Promise<ICursorListResponse<IJournal>> {
	return xhr
		.get("/accounting/journals", { params: filtersToRequestParams(params) })
		.then((resp) => resp.data)
}

const sortOptions: TSortOptions = [
	{
		label: "Txn Date",
		name: "txn_date",
		type: "date",
		order: "desc",
	},
	{
		label: "Log Date",
		name: "log_date",
		type: "date",
		order: "desc",
	},
]

export function Transactions({
	params: paramsProps = {},
	pageKey = "accounting/transactions",
	disableLogging,
	title = "Transactions",
	onChange,
}: {
	title?: string
	params?: IFilters
	pageKey?: string
	disableLogging?: boolean
	onChange?: () => void
}) {
	const [query, setQuery] = useLocationQuery({
		fromQuery: locationQueryToParams,
		toQuery: paramsToLocationQuery,
	})
	const [params, setParams] = useSearch<IFilters>({
		...query,
		sort: query.sort || "-txn_date",
	})
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	const { hasPermission } = useCheckPermissions()
	const canManageAccounting = hasPermission(PERMISSIONS.MANAGE_ACCOUNTING)
	const canDownloadTransactions =
		canManageAccounting && params.after && params.before
	return (
		<>
			<Search
				initialParams={params}
				onSearch={(params) => setParams({ ...params, cursor: null })}
				title={title}
				Filters={Filters}
				actions={
					!disableLogging || canDownloadTransactions ? (
						<Inline gap="2">
							{disableLogging ? null : (
								<ButtonLink
									to={generatePath("/accounting/transactions/new")}
									level="primary"
								>
									<Icons.Plus /> Transaction
								</ButtonLink>
							)}
							{canDownloadTransactions ? (
								<Dropdown alignRight="sm">
									<Dropdown.ToggleButton>
										<Icons.DotsVertical />
									</Dropdown.ToggleButton>
									<Dropdown.Menu>
										<Dropdown.MenuItem
											as={XHRLink}
											href="/accounting/journals/download"
											title="Download as Excel"
											query={{
												...filtersToRequestParams({
													...params,
													...paramsProps,
												}),
												timezone_offset: config.timezoneOffset,
											}}
											download
										>
											<Icons.DocumentDownload /> Download
										</Dropdown.MenuItem>
									</Dropdown.Menu>
								</Dropdown>
							) : null}
						</Inline>
					) : null
				}
				areAdvancedFiltersApplied={(filters) => {
					const { reverted, ...otherParams } = filters
					if (reverted) return true
					return areAdvancedFiltersAppliedDefault(otherParams)
				}}
			>
				<CursorListView<IJournal, IFilters>
					params={params}
					pageKey={pageKey}
					fetch={(xhr, params = {}) =>
						fetchTransactions(xhr, { ...params, ...paramsProps })
					}
					onCursorChange={(cursor, sort) =>
						setParams({ ...params, cursor, sort })
					}
					sortOptions={sortOptions}
				>
					{({ items: transactions, refresh }) => {
						function handleChange() {
							refresh()
							onChange?.()
						}
						return (
							<TransactionList
								transactions={transactions}
								reverted={params.reverted}
								onChange={handleChange}
								selectedAccount={
									paramsProps.accounts?.length === 1
										? paramsProps.accounts[0]
										: undefined
								}
							/>
						)
					}}
				</CursorListView>
			</Search>
		</>
	)
}

export function TransactionList({
	transactions,
	reverted,
	onChange,
	selectedAccount,
}: {
	transactions: Array<IJournal>
	reverted?: boolean
	onChange?: () => void
	selectedAccount?: IAccount
}) {
	const transactionsWithDebitCreditAccounts = useMemo(() => {
		return transactions.map((t) => {
			const creditPosting = t.postings?.find((posting) => posting.is_credit)
			const debitPosting = t.postings?.find((posting) => !posting.is_credit)
			return {
				...t,
				debit: {
					account: debitPosting?.account,
					asset_type: debitPosting?.asset_type,
					amount: debitPosting?.amount,
				},
				credit: {
					account: creditPosting?.account,
					asset_type: creditPosting?.asset_type,
					amount: creditPosting?.amount,
				},
			}
		})
	}, [transactions])
	return (
		<Table
			bordered
			striped
			responsive
			headers={["Txn ID", "Txn Date"]
				.concat(
					!selectedAccount
						? ["Debit Acc.", "Credit Acc.", "Amount"]
						: ["Account", "Debit", "Credit"]
				)
				.concat([
					"Ref ID",
					"Narration",
					"Created By",
					reverted ? "Reverted" : "",
				])}
			alignCols={!selectedAccount ? { 4: "right" } : { 3: "right", 4: "right" }}
			rows={transactionsWithDebitCreditAccounts.map((p) =>
				(
					[
						<Text color="muted" fontSize="sm">
							{p.short_txn_id}
						</Text>,
						<Text whiteSpace="preserve">
							{utcTimestampToLocalDateString(p.date)}
						</Text>,
					] as Array<React.ReactNode>
				)
					.concat(
						!selectedAccount
							? [
									p.debit.account ? (
										<Box>
											<Link
												to={generatePath("/accounting/accounts/:accountId", {
													accountId: p.debit.account.id.toString(),
												})}
												anchored
												color="accent"
											>
												{p.debit.account.name}
											</Link>
										</Box>
									) : null,
									p.credit.account ? (
										<Box>
											<Link
												to={generatePath("/accounting/accounts/:accountId", {
													accountId: p.credit.account.id.toString(),
												})}
												anchored
												color="accent"
											>
												{p.credit.account.name}
											</Link>
										</Box>
									) : null,
									<Stack>
										<Text
											fontWeight="semibold"
											color={
												p.is_transfer_to_tenant === true
													? "success"
													: p.is_transfer_to_tenant === false
														? "danger"
														: undefined
											}
										>
											{numberToLocalString(p.transfered_amount)}
										</Text>
										{p.transfered_asset_type ? (
											<Text fontSize="sm" color="muted">
												{p.transfered_asset_type}
											</Text>
										) : null}
									</Stack>,
								]
							: [
									<Box>
										{p.debit.account &&
										selectedAccount?.id !== p.debit.account.id ? (
											<Box>
												<Link
													to={generatePath("/accounting/accounts/:accountId", {
														accountId: p.debit.account.id.toString(),
													})}
													color="accent"
													anchored
												>
													{p.debit.account.name}
												</Link>
											</Box>
										) : null}
										{p.credit.account &&
										selectedAccount?.id !== p.credit.account.id ? (
											<Box>
												<Link
													to={generatePath("/accounting/accounts/:accountId", {
														accountId: p.credit.account.id.toString(),
													})}
													color="accent"
													anchored
												>
													{p.credit.account.name}
												</Link>
											</Box>
										) : null}
									</Box>,
									p.debit.account &&
									p.debit.account.id === selectedAccount?.id ? (
										<Stack>
											<Text>{numberToLocalString(p.debit.amount)}</Text>

											{p.debit.asset_type ? (
												<Text fontSize="sm" color="muted">
													{p.debit.asset_type}
												</Text>
											) : null}
										</Stack>
									) : null,
									p.credit.account &&
									p.credit.account.id === selectedAccount?.id ? (
										<Stack>
											<Text>{numberToLocalString(p.credit.amount)}</Text>
											{p.credit.asset_type ? (
												<Text fontSize="sm" color="muted">
													{p.credit.asset_type}
												</Text>
											) : null}
										</Stack>
									) : null,
								]
					)
					.concat([
						<Text
							fontSize="sm"
							textOverflow="truncate"
							style={{ maxWidth: "100px" }}
							title={p.reference_id}
						>
							{p.reference_id}
						</Text>,
						<Text
							fontSize="sm"
							textOverflow="truncate"
							style={{ maxWidth: "150px" }}
							title={p.narration}
						>
							{p.narration}
						</Text>,
						<Stack>
							<Text>{p.created_by?.name || null} </Text>
							<Text fontSize="sm">
								<RelativeTime timestamp={p.created_at} color="muted" />
							</Text>
						</Stack>,
						reverted ? (
							p.reverted_at ? (
								<Tooltip
									placement="left"
									content={`Reverted by ${
										p.reverted_by_user?.name || "You"
									}, ${fromNow(utcTimestampToLocalDate(p.reverted_at))}`}
								>
									<Text color="warning">{p.reversal_comments || "N/A"}</Text>
								</Tooltip>
							) : null
						) : !p.is_reversal ? (
							<Box>
								<Dropdown alignRight>
									<Dropdown.ToggleButton size="sm" level="tertiary">
										<Icons.DotsVertical />
									</Dropdown.ToggleButton>
									<Dropdown.Menu>
										<TransactionDetailsInDialog transaction={p}>
											{({ showDetails }) => (
												<Dropdown.MenuItem onClick={showDetails}>
													<Icons.ArrowExpand /> Details
												</Dropdown.MenuItem>
											)}
										</TransactionDetailsInDialog>
										<EditTransactionInDialog
											transaction={p}
											onSuccess={() => onChange?.()}
										>
											{({ edit }) => (
												<Dropdown.MenuItem onClick={edit}>
													<Icons.Pencil /> Edit Details
												</Dropdown.MenuItem>
											)}
										</EditTransactionInDialog>
										<RevertTransactionInDialog
											transaction={p}
											onSuccess={() => onChange?.()}
										>
											{({ revert }) => (
												<Dropdown.MenuItem onClick={revert}>
													<Icons.Refresh /> Revert
												</Dropdown.MenuItem>
											)}
										</RevertTransactionInDialog>
									</Dropdown.Menu>
								</Dropdown>
							</Box>
						) : null,
					])
			)}
		/>
	)
}

interface IFilters extends TSearchParams {
	after?: string | Date
	before?: string | Date
	accounts?: Array<IAccount>
	reverted?: boolean
	sort?: string
	attached_to_type?: string
	attached_to_id?: string
}

interface IFiltersInLocationQuery extends TSearchParams {
	after?: string
	before?: string
	accounts?: Array<string>
	reverted?: 1
	sort?: string
}

function paramsToLocationQuery(params: IFilters): IFiltersInLocationQuery {
	const { q, page, after, before, accounts, reverted, sort } = params
	const filters: IFiltersInLocationQuery = {}
	if (q) {
		filters.q = q
	}
	if (sort) {
		filters.sort = sort
	}
	if (page) {
		filters.page = page
	}
	if (after) {
		filters.after = dateToQuery(after)
	}
	if (before) {
		filters.before = dateToQuery(before)
	}
	if (accounts?.length) {
		filters.accounts = accounts.map((a) => `${a.id}_${a.name}`)
	}
	if (reverted) {
		filters.reverted = 1
	}
	return filters
}

function locationQueryToParams(query: IFiltersInLocationQuery): IFilters {
	const { q, page, after, before, accounts, reverted, sort } = query
	const filters: IFilters = {}
	if (q) {
		filters.q = q
	}
	if (sort) {
		filters.sort = sort
	}
	if (page) {
		filters.page = page
	}
	if (after) filters.after = parseDateFromQuery(after)
	if (before) filters.before = parseDateFromQuery(before)
	if (accounts?.length) {
		filters.accounts = accounts.map((s: string) => {
			const [id, ...name] = s.split("_")
			return {
				id: parseInt(id),
				name: name.join("_"),
			}
		}) as unknown as $PropertyType<IFilters, "accounts">
	}
	if (reverted) {
		filters.reverted = true
	}
	return filters
}

function filtersToRequestParams(filters: IFilters) {
	const {
		after,
		before,
		page = 1,
		accounts,
		reverted,
		...otherParams
	} = filters
	return {
		page,
		...otherParams,
		after: after ? dateToUTCString(startOf(after, "day")) : null,
		before: before ? dateToUTCString(startOf(before, "day")) : null,
		accounts: accounts ? accounts.map((a) => a.id) : null,
		reverted: reverted ? 1 : undefined,
	}
}

function Filters() {
	return (
		<Stack gap="4">
			<DateRangePickerField
				label="Created Between"
				fromName="after"
				toName="before"
				dateFormat="DD MMM 'YY"
			/>
			<SelectField
				select={SelectAccount}
				label="Accounts Involved"
				name="accounts"
				multiple
			/>
			<SwitchInputField name="reverted" label="Reverted Only" />
		</Stack>
	)
}

function EditTransactionInDialog({
	transaction,
	children,
	onSuccess,
}: {
	transaction: IJournal
	children: (props: { edit: () => void }) => React.ReactNode
	onSuccess?: () => void
}) {
	return (
		<Component initialState={false}>
			{({ state, setState }) => (
				<>
					{children({ edit: () => setState(true) })}
					{state ? (
						<Dialog
							open={state}
							onClose={() => setState(false)}
							title="Edit Transaction"
						>
							<Dialog.Body>
								<EditTransaction
									transaction={transaction}
									onCancel={() => setState(false)}
									onSuccess={() => {
										setState(false)
										onSuccess?.()
									}}
								/>
							</Dialog.Body>
						</Dialog>
					) : null}
				</>
			)}
		</Component>
	)
}

function RevertTransactionInDialog({
	transaction,
	children,
	onSuccess,
}: {
	transaction: IJournal
	children: (props: { revert: () => void }) => React.ReactNode
	onSuccess?: () => void
}) {
	return (
		<Component initialState={false}>
			{({ state, setState }) => (
				<>
					{children({ revert: () => setState(true) })}
					{state ? (
						<Dialog
							open={state}
							onClose={() => setState(false)}
							title="Revert Transaction"
							sm
						>
							<Dialog.Body>
								<RevertTransaction
									transaction={transaction}
									onCancel={() => setState(false)}
									onSuccess={() => {
										setState(false)
										onSuccess?.()
									}}
								/>
							</Dialog.Body>
						</Dialog>
					) : null}
				</>
			)}
		</Component>
	)
}

function useTransaction(id: number | string, alwaysRefresh = false) {
	const xhr = useXHR()
	const refreshId = useId()
	const { data: transaction } = useSWR<IJournal>(
		`/accounting/journals/${id}?${alwaysRefresh ? refreshId : ""}`,
		() => xhr.get(`/accounting/journals/${id}`).then((resp) => resp.data.data)
	)
	return { transaction }
}

function EditTransaction({
	transaction: propsTransaction,
	onCancel,
	onSuccess,
}: {
	transaction: IJournal
	onCancel?: () => void
	onSuccess: () => void
}) {
	const functionalCurrency = useFunctionalCurrencyOfTenant()
	const { transaction } = useTransaction(propsTransaction.id, true)
	const xhr = useXHR()
	if (!transaction) return <Spinner padding="4" alignCenter />
	const {
		id,
		transfered_amount,
		transfered_asset_type,
		date,
		reference_id,
		narration,
		instalments,
	} = transaction
	const debitPosting = transaction.postings?.find(
		(posting) => !posting.is_credit
	)
	const creditPosting = transaction.postings?.find(
		(posting) => posting.is_credit
	)

	const debitAccount = debitPosting?.account
		? {
				...debitPosting.account,
				name: debitPosting.account?.asset_type
					? `${debitPosting.account.name} (${debitPosting.account.asset_type})`
					: debitPosting.account.name,
			}
		: undefined
	const creditAccount = creditPosting?.account
		? {
				...creditPosting.account,
				name: creditPosting.account?.asset_type
					? `${creditPosting.account.name} (${creditPosting.account.asset_type})`
					: creditPosting.account.name,
			}
		: undefined
	const debitAmount = Math.abs(Number(debitPosting?.amount || 0))
	const debitCurrency = debitPosting?.asset_type
	const creditCurrency = creditPosting?.asset_type

	const creditAmount = Math.abs(Number(creditPosting?.amount || 0))
	let debitToCreditExchangeRate: undefined | number = undefined

	if (debitCurrency !== creditCurrency && creditAmount > 0) {
		debitToCreditExchangeRate = Number((creditAmount / debitAmount).toFixed(3))
	}
	return (
		<Stack key={id} gap="4">
			{instalments?.length ? (
				<Alert status="warning">
					This transaction can not be updated because it is associated with
					instalments. Please update the instalment payment details instead.
				</Alert>
			) : (
				<LogTransactionForm
					initialValues={{
						debit_amount: debitAmount,
						debit_currency: debitCurrency,
						credit_amount:
							debitCurrency !== creditCurrency ? creditAmount : debitAmount,
						credit_currency:
							debitCurrency !== creditCurrency ? creditCurrency : undefined,
						paid_at: utcTimestampToLocalDate(date).toISOString(),
						reference_id: reference_id || "",
						narration: narration || "",
						credit_account: creditAccount,
						debit_account: debitAccount,
						debit_to_credit_exchange_rate: debitToCreditExchangeRate || 1,
					}}
					onSubmit={async (data) => {
						const requestData = data.data[0]
						const resp = await xhr.post(
							`/accounting/journals/${transaction.id}`,
							requestData
						)
						showSnackbar(
							`Transaction details updated with #${resp.data.data.short_txn_id}`
						)
						onSuccess()
					}}
					onCancel={onCancel}
					hideTip
					disableBulk
					readOnlyAmount={Boolean(instalments?.length)}
				/>
			)}
		</Stack>
	)
}

const revertDataValidationSchema = Validator.object().shape({
	journal_id: Validator.number().required(
		"Please select a transaction to revert"
	),
	reason: Validator.string().required(
		"Please provide a reason to revert the transaction"
	),
})

function RevertTransaction({
	transaction: propsTransaction,
	onCancel,
	onSuccess,
}: {
	transaction: IJournal
	onCancel?: () => void
	onSuccess: () => void
}) {
	const { transaction } = useTransaction(propsTransaction.id)
	const xhr = useXHR()
	if (!transaction) return <Spinner padding="4" alignCenter />
	const { id, instalments } = transaction
	return (
		<Form<{ journal_id: number; reason: string }>
			initialValues={{ journal_id: id, reason: "" }}
			validate={validateFormValues(revertDataValidationSchema)}
			onSubmit={withServerErrors(async (values) => {
				if (instalments?.length)
					throw new Error(
						"Can not revert the transaction. Please revert the instalment payment instead."
					)
				const resp = await xhr.post(
					`/accounting/revert-journals/${values.journal_id}`,
					{
						reason: values.reason,
					}
				)
				showSnackbar(resp.data.message || "Transaction reverted successfully")
				onSuccess()
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack marginBottom="4" gap="2">
						<Box borderBottomWidth="2">
							<Text fontWeight="semibold" fontSize="lg">
								Please review details before reverting
							</Text>
						</Box>
						<TransactionDetailsView transaction={transaction} />
					</Stack>
					{instalments?.length ? (
						<Alert status="warning">
							This transaction can not be reverted because it is associated with
							instalments. Please revert the instalment payment instead.
						</Alert>
					) : (
						<>
							<Box>
								<Alert status="warning" title="Transaction revert will">
									<Stack gap="4">
										<Box as="ul" listStyleType="disc" marginLeft="4">
											<Box as="li">Revert the debit/credit entries</Box>
											{instalments?.length ? (
												<Box as="li">Revert the instalment payments</Box>
											) : null}
										</Box>
										<Text as="p">
											Once reverted, it can not be undone. If you want to change
											the details (accounts, narration etc.), please use the{" "}
											<b>Edit Details</b> option.
										</Text>
									</Stack>
								</Alert>
							</Box>
							<TextInputField
								name="reason"
								label="Please provide a reason for reversal"
								type="text"
								required
								placeholder="e.g. Transaction canceled"
							/>
							<Divider sm />
							<Stack gap="4">
								<SubmissionError />
								<Inline gap="4">
									<Button
										type="submit"
										level="primary"
										status="warning"
										disabled={submitting}
									>
										I understand, revert it
									</Button>
									{onCancel ? (
										<Button
											disabled={submitting}
											onClick={onCancel}
											level="tertiary"
										>
											Cancel
										</Button>
									) : null}
								</Inline>
							</Stack>
						</>
					)}
				</form>
			)}
		</Form>
	)
}

export function TransactionDetailsInDialog({
	transaction,
	children,
}: {
	transaction: IJournal
	children: (props: { showDetails: () => void }) => React.ReactNode
}) {
	return (
		<Component initialState={false}>
			{({ state, setState }) => (
				<>
					{children({ showDetails: () => setState(true) })}
					{state ? (
						<Dialog
							open={state}
							onClose={() => setState(false)}
							title="Transaction Details"
							sm
						>
							<Dialog.Body>
								<TransactionDetails transaction={transaction} />
							</Dialog.Body>
							<Dialog.Footer>
								<Button onClick={() => setState(false)}>Close</Button>
							</Dialog.Footer>
						</Dialog>
					) : null}
				</>
			)}
		</Component>
	)
}

function TransactionDetails({
	transaction: propsTransaction,
}: {
	transaction: IJournal
}) {
	const { transaction } = useTransaction(propsTransaction.id)
	if (!transaction) return <Spinner padding="4" alignCenter />
	return <TransactionDetailsView transaction={transaction} />
}

function TransactionDetailsView({ transaction }: { transaction: IJournal }) {
	const {
		id,
		postings,
		instalments,
		trips,
		date,
		reference_id,
		short_txn_id,
		narration,
		created_by,
		created_at,
		is_reversal,
		reverted_at,
		reverted_by_user,
		reversal_comments,
	} = transaction

	return (
		<Box key={id}>
			{is_reversal ? (
				<Alert status="warning" title="Reverted Transaction">
					This transaction has been reverted{" "}
					{reverted_at ? (
						<Box display="inline" as="span">
							by {reverted_by_user?.name || "You"} on{" "}
							<Time value={utcTimestampToLocalDate(reverted_at)} /> due to "
							{reversal_comments || "n/a"}"
						</Box>
					) : null}
				</Alert>
			) : null}
			<Box>
				<Inline fontSize="md" fontWeight="semibold">
					{joinAttributes(
						<Text>Txn ID: {short_txn_id}</Text>,
						reference_id ? <Text>Ref ID: {reference_id}</Text> : null
					)}
				</Inline>
				<Box
					padding="4"
					borderWidth="1"
					rounded="md"
					bgColor="subtle"
					marginBottom="6"
				>
					<Inline gap="4" justifyContent="between">
						<Box>
							<Heading fontSize="sm" color="muted">
								Date
							</Heading>
							<Text fontWeight="semibold">
								<Time value={utcTimestampToLocalDate(date)} />
							</Text>
						</Box>
						{narration ? (
							<Box>
								<Heading fontSize="sm" color="muted">
									Narration
								</Heading>
								<Text fontWeight="semibold">{narration}</Text>
							</Box>
						) : null}
						{created_by ? (
							<Box>
								<Heading fontSize="sm" color="muted">
									Created
								</Heading>
								<Text>
									{created_by.name}{" "}
									<RelativeTime
										value={utcTimestampToLocalDate(created_at)}
										fontSize="xs"
										color="muted"
									/>
								</Text>
							</Box>
						) : null}
					</Inline>
				</Box>
				{postings ? (
					<Box>
						<Heading fontSize="md">
							Accounts involved in the transaction
						</Heading>
						<Table
							headers={["Account", `Debit`, `Credit`]}
							bordered
							responsive
							hover
							alignCols={{ 1: "right", 2: "right" }}
							rows={postings.map((p) => {
								const amount = Math.abs(p.amount)
								return [
									<Link
										to={generatePath("/accounting/accounts/:accountId", {
											accountId: p.account.id.toString(),
										})}
										color="accent"
									>
										{p.account.name}
									</Link>,
									!p.is_credit ? (
										<Money
											amount={amount}
											currency={p.asset_type}
											showCurrency
										/>
									) : null,
									p.is_credit ? (
										<Money
											amount={amount}
											currency={p.asset_type}
											showCurrency
										/>
									) : null,
								]
							})}
						/>
					</Box>
				) : null}
				{trips?.length ? (
					<Box marginY="6">
						<Heading fontSize="md">Trip{trips.length > 1 ? "s" : ""}</Heading>
						<Box borderWidth="1" rounded="md">
							{trips.map((trip, index) => (
								<Box
									key={trip.id}
									borderTopWidth={index !== 0 ? "1" : undefined}
									paddingX="4"
									paddingY="2"
								>
									<Box fontWeight="semibold">
										<Link
											to={generatePath("/trips/:tripId", {
												tripId: trip.id.toString(),
											})}
											color="accent"
										>
											{trip.name}
										</Link>
									</Box>
									<Box fontSize="sm">
										{joinAttributes(
											<Time value={utcTimestampToLocalDate(trip.start_date)} />,
											<TimeDuration days={trip.days}>
												{trip.nights}N,{trip.days}D
											</TimeDuration>,
											`${trip.no_of_adults}A${
												trip.no_of_children ? `,${trip.no_of_children}C` : ``
											}`
										)}
									</Box>
								</Box>
							))}
						</Box>
					</Box>
				) : null}
				{instalments?.length ? (
					<Box marginY="6">
						<Box>
							<Heading fontSize="md">
								Instalment{instalments.length > 1 ? "s" : ""}
							</Heading>
							<Box borderWidth="1" rounded="md">
								{instalments.map((instalment, index) => (
									<Box
										key={instalment.id}
										borderTopWidth={index !== 0 ? "1" : undefined}
										paddingX="4"
										paddingY="2"
									>
										<Box display="flex" justifyContent="between">
											<Box>
												<Money
													amount={instalment.amount}
													currency={instalment.currency}
													showCurrency
												/>
												{instalment.payment &&
												instalment.payment.amount !== instalment.amount ? (
													<Box
														fontSize="sm"
														color="muted"
														marginLeft="1"
														display="inline"
													>
														/ {numberToLocalString(instalment.payment.amount)}
													</Box>
												) : null}
											</Box>
											<Box>
												<Time
													value={utcTimestampToLocalDate(instalment.due_at)}
												/>
											</Box>
											<Box>
												<Badge primary outlined>
													{instalment.payment?.paymentable_type}
												</Badge>
											</Box>
										</Box>
									</Box>
								))}
							</Box>
						</Box>
					</Box>
				) : null}
			</Box>
		</Box>
	)
}
