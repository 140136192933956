import {
	Alert,
	Box,
	Container,
	Stack,
	Inline,
	Heading,
	Spinner,
	Icons,
	Text,
	RelativeTime,
	Component,
	Dropdown,
	Divider,
	Button,
	Badge,
	Time,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import {
	Form,
	SubmissionError,
	withServerErrors,
} from "@sembark-travel/ui/form"
import { Breadcrumbs, ButtonLink, Link } from "@sembark-travel/ui/router"
import { Markdown } from "@sembark-travel/ui/markdown"
import { useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import { generatePath } from "../router-utils"
import { ITermsAndCondition } from "./store"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { patienceDiffPlus } from "./patience-diff"
import { useState } from "react"

export function TermsAndConditionItemDetails({
	tncId,
}: {
	tncId: string | number
}) {
	const xhr = useXHR()
	const { hasPermission } = useCheckPermissions()
	const { data, error, mutate } = useSWR<ITermsAndCondition>(
		`/terms-and-conditions/${tncId}`,
		() =>
			xhr.get(`/terms-and-conditions/${tncId}`).then((resp) => resp.data.data)
	)
	const [currentHistoryRevisionIndex, changeCurrentHistoryRevisionIndex] =
		useState<undefined | number>(undefined)
	if (!data && error) {
		return <Alert status="error">{error.message}</Alert>
	}
	if (!data) {
		return <Spinner padding="4" alignCenter />
	}
	const {
		id,
		name,
		description,
		created_at,
		updated_at,
		created_by,
		updated_by,
		deleted_at,
		deleted_by,
		older_versions,
		latest_version_id,
	} = data
	const history = [data].concat(older_versions || [])
	console.log(history)
	return (
		<Box key={id}>
			<Breadcrumbs
				title="Details"
				items={[
					[generatePath("/org/terms-and-conditions"), "TnC"],
					["", name],
				]}
				actions={
					hasPermission(PERMISSIONS.MANAGE_TRIP_OWNERS) ? (
						<Inline gap="4">
							{!deleted_at ? (
								<ButtonLink
									to={generatePath("/terms-and-conditions/:tncId/edit", {
										tncId: id.toString(),
									})}
									level="tertiary"
									size="sm"
								>
									<Icons.Pencil /> Edit
								</ButtonLink>
							) : null}
							<Component<"archive" | "restore" | undefined>
								initialState={undefined}
							>
								{({ state, setState }) => (
									<>
										<Dropdown alignRight>
											<Dropdown.ToggleButton size="sm" level="tertiary">
												<Icons.DotsVertical />
											</Dropdown.ToggleButton>
											<Dropdown.Menu>
												{!deleted_at ? (
													<Dropdown.MenuItem
														onClick={() => setState("archive")}
														color="danger"
													>
														<Icons.Trash /> Archive
													</Dropdown.MenuItem>
												) : (
													<Dropdown.MenuItem
														onClick={() => setState("restore")}
													>
														<Icons.Refresh /> Restore
													</Dropdown.MenuItem>
												)}
											</Dropdown.Menu>
										</Dropdown>
										<Dialog
											open={state === "archive"}
											onClose={() => setState(undefined)}
											sm
										>
											<Dialog.Header>
												<Dialog.Title>
													Archive Terms and Conditions
												</Dialog.Title>
											</Dialog.Header>
											<Form<{ terms_and_condition_id: number }>
												initialValues={{ terms_and_condition_id: id }}
												onSubmit={withServerErrors(async (values) => {
													const resp = await xhr.delete<{
														data: { message: string }
													}>(
														`/terms-and-conditions/${values.terms_and_condition_id}`
													)
													mutate()
													showSnackbar(resp.data.data.message)
													setState(undefined)
												})}
												subscription={{ submitting: true }}
											>
												{({ submitting, handleSubmit }) => (
													<form noValidate onSubmit={handleSubmit}>
														<Dialog.Body>
															<Stack gap="4">
																<Text>
																	These "Terms and Conditions" will be removed
																	from all listing and selection pages. Existing
																	usage of this TnC will NOT be affected.
																</Text>
																<Divider marginY={"0"} />
																<Text color="warning">
																	Are you sure you want to archive/disable these
																	Terms and Conditions ?
																</Text>
																<SubmissionError />
															</Stack>
														</Dialog.Body>
														<Dialog.Footer>
															<Button
																type="submit"
																disabled={submitting}
																status="danger"
															>
																{submitting
																	? "Archiving..."
																	: "I understand, archive it."}
															</Button>
															<Button
																onClick={() => setState(undefined)}
																disabled={submitting}
															>
																Cancel
															</Button>
														</Dialog.Footer>
													</form>
												)}
											</Form>
										</Dialog>
										<Dialog
											open={state === "restore"}
											onClose={() => setState(undefined)}
											sm
										>
											<Dialog.Header>
												<Dialog.Title>
													Restore archived Terms and Conditions
												</Dialog.Title>
											</Dialog.Header>
											<Form<{ terms_and_condition_id: number }>
												initialValues={{ terms_and_condition_id: id }}
												onSubmit={withServerErrors(async (values) => {
													const resp = await xhr.post<{
														data: { message: string }
													}>(
														`/terms-and-conditions/${values.terms_and_condition_id}/restore`
													)
													mutate()
													showSnackbar(resp.data.data.message)
													setState(undefined)
												})}
												subscription={{ submitting: true }}
											>
												{({ submitting, handleSubmit }) => (
													<form noValidate onSubmit={handleSubmit}>
														<Dialog.Body>
															<Stack gap="4">
																<Text>
																	Are you sure you want to restore these
																	archived Terms and Conditions ?
																</Text>
																<SubmissionError />
															</Stack>
														</Dialog.Body>
														<Dialog.Footer>
															<Button type="submit" disabled={submitting}>
																{submitting ? "Restoring..." : "Restore"}
															</Button>
															<Button
																onClick={() => setState(undefined)}
																disabled={submitting}
															>
																Cancel
															</Button>
														</Dialog.Footer>
													</form>
												)}
											</Form>
										</Dialog>
									</>
								)}
							</Component>
						</Inline>
					) : null
				}
			/>
			<Container paddingY="6" bgColor="default" borderBottomWidth="1">
				<Inline justifyContent="between" gap="6" collapseBelow="md">
					<Stack gap="px">
						<Text color="muted">Name</Text>
						<Heading>{name}</Heading>
						{latest_version_id ? (
							<Box>
								<Link
									to={generatePath("/terms-and-conditions/:tncId", {
										tncId: latest_version_id.toString(),
									})}
									textDecoration="underline"
								>
									Goto Latest Version
								</Link>
							</Box>
						) : history.length > 1 ? (
							<Box>
								{currentHistoryRevisionIndex === undefined ? (
									<Button
										onClick={() => changeCurrentHistoryRevisionIndex(0)}
										inline
									>
										<Icons.RefreshClock /> View Revisions
									</Button>
								) : (
									<Button
										onClick={() => changeCurrentHistoryRevisionIndex(undefined)}
										inline
									>
										<Icons.Cancel /> Hide Revisions
									</Button>
								)}
							</Box>
						) : null}
					</Stack>
					<Inline gap="8" flexWrap="wrap">
						<Stack gap="px">
							<Text color="muted">Created by</Text>
							<Inline gap="2" alignItems="baseline">
								<Text fontWeight="semibold" fontSize="md">
									{created_by?.name}
								</Text>
								{created_at ? <RelativeTime timestamp={created_at} /> : null}
							</Inline>
						</Stack>
						{updated_by ? (
							<Stack gap="px">
								<Text color="muted">Updated by</Text>
								<Inline gap="2" alignItems="baseline">
									<Text fontWeight="semibold" fontSize="md">
										{updated_by.name}
									</Text>
									{updated_at ? <RelativeTime timestamp={updated_at} /> : null}
								</Inline>
							</Stack>
						) : null}
						{deleted_by ? (
							<Stack gap="px" color="danger">
								<Text color="muted">Archived by</Text>
								<Inline gap="2" alignItems="baseline">
									<Text fontWeight="semibold" fontSize="md">
										{deleted_by.name}
									</Text>
									{deleted_at ? <RelativeTime timestamp={deleted_at} /> : null}
								</Inline>
							</Stack>
						) : null}
					</Inline>
				</Inline>
			</Container>
			<Container paddingY="8" bgColor="default" borderBottomWidth="1">
				{currentHistoryRevisionIndex === undefined || history.length < 1 ? (
					<Stack gap="4">
						<Markdown>{description}</Markdown>
					</Stack>
				) : history.length > 1 ? (
					<Stack gap="4">
						<Inline gap="2">
							<Button
								size="sm"
								disabled={currentHistoryRevisionIndex === 0}
								onClick={() =>
									changeCurrentHistoryRevisionIndex((h) =>
										Math.max(Number(h) - 1, 0)
									)
								}
							>
								{currentHistoryRevisionIndex === 0 ? (
									"Latest"
								) : (
									<>
										<Icons.ChevronDown rotate="90" />{" "}
										<Time
											timestamp={
												history[currentHistoryRevisionIndex].created_at
											}
										/>
									</>
								)}
							</Button>
							<Button
								size="sm"
								disabled={currentHistoryRevisionIndex === history.length - 2}
								onClick={() =>
									changeCurrentHistoryRevisionIndex((h) =>
										Math.min(Number(h) + 1, history.length - 2)
									)
								}
							>
								{currentHistoryRevisionIndex === history.length - 2 ? (
									"Oldest"
								) : (
									<>
										<Time
											timestamp={
												history[currentHistoryRevisionIndex + 1].created_at
											}
										/>{" "}
										<Icons.ChevronDown rotate="270" />
									</>
								)}
							</Button>
						</Inline>
						{currentHistoryRevisionIndex !== undefined ? (
							<ShowReversionDiff
								newer={history[currentHistoryRevisionIndex]}
								older={history[currentHistoryRevisionIndex + 1]}
							/>
						) : null}
					</Stack>
				) : null}
			</Container>
		</Box>
	)
}

function ShowReversionDiff({
	newer,
	older,
}: {
	newer: ITermsAndCondition
	older: ITermsAndCondition
}) {
	const diff = patienceDiffPlus(
		older.description.split("\n"),
		newer.description.split("\n")
	)
	return (
		<Stack gap="4">
			<Inline gap="1" flexWrap="wrap">
				<Badge danger>- {diff.lineCountDeleted} Deletion</Badge>
				<Badge success>+ {diff.lineCountInserted} Addition</Badge>
				{diff.lineCountMoved ? (
					<Badge warning>~ {diff.lineCountMoved} Moved</Badge>
				) : null}
				<Box>
					by {newer.created_by?.name} on{" "}
					<Time timestamp={newer.created_at} dateFormat timeFormat />
				</Box>
			</Inline>
			<Stack whiteSpace="preserveLine" gap="1">
				{diff.lines.map(({ line, aIndex, bIndex, moved }, index) => {
					let col = ""
					if (bIndex < 0 && moved) {
						col = "-m  "
					} else if (moved) {
						col += "+m  "
					} else if (aIndex < 0) {
						col += "+   "
					} else if (bIndex < 0) {
						col += "-   "
					} else {
						col += "    "
					}
					return (
						<Box
							key={index}
							bgColor={
								bIndex === -1 ? "danger" : aIndex === -1 ? "success" : "default"
							}
							paddingLeft="8"
							position="relative"
						>
							<Box
								as="span"
								fontFamily="mono"
								width="8"
								position={"absolute"}
								left="0"
								pointerEvents="none"
								style={{
									userSelect: "none",
								}}
								paddingX="1"
							>
								{col}
							</Box>
							{line}
						</Box>
					)
				})}
			</Stack>{" "}
		</Stack>
	)
}
