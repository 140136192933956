import {
	isSame,
	clone,
	getDiff,
	parseDate,
	localOrUtcTimestampToLocalDate,
} from "@sembark-travel/datetime-utils"
import type { IQuote, ITrip, IQuoteCab, IQuoteHotel, ITripQuote } from "./store"
import { collect } from "../utils"
import { type TChildrenArray, childrenToQueryParams } from "./../Tourists"
import {
	addMoneyFromDifferentCurrencies,
	formatMoneyByDecimal,
	getMoneyRatio,
	makeCurrencyConverter,
	moneyParseByDecimal,
	subtractMoney,
} from "@sembark-travel/money"
import { normalizeNumber } from "@sembark-travel/number-utils"
import { Required } from "utility-types"

type TDiff = {
	message: string
	startDate?: {
		trip: Date
		quote: Date
	}
	days?: {
		trip: number
		quote: number
	}
	noOfAdults?: {
		trip: number
		quote: number
	}
	children?: {
		trip: TChildrenArray
		quote: TChildrenArray
	}
}
/**
 * Get the diff between a trip and it's quote's basic details
 */
export function getTripQuoteDetailsDiff(
	trip: Pick<
		ITrip,
		"start_date" | "start_date_local" | "days" | "no_of_adults" | "children"
	>,
	quote: Pick<
		IQuote,
		"start_date" | "start_date_local" | "days" | "no_of_adults" | "children"
	>
): TDiff | null {
	const startDate = localOrUtcTimestampToLocalDate(
		quote.start_date_local,
		quote.start_date
	)
	const { days, children, no_of_adults } = quote
	const diff: TDiff = {
		message: "",
	}
	if (
		!isSame(
			startDate,
			localOrUtcTimestampToLocalDate(trip.start_date_local, trip.start_date)
		)
	) {
		diff.startDate = {
			trip: localOrUtcTimestampToLocalDate(
				trip.start_date_local,
				trip.start_date
			),
			quote: startDate,
		}
	}
	if (days !== trip.days) {
		diff.days = {
			trip: trip.days,
			quote: days,
		}
	}
	if (no_of_adults !== trip.no_of_adults) {
		diff.noOfAdults = {
			trip: trip.no_of_adults,
			quote: no_of_adults,
		}
	}
	if (
		childrenToQueryParams(children) !== childrenToQueryParams(trip.children)
	) {
		diff.children = {
			trip: trip.children,
			quote: children,
		}
	}
	const notMatched = []
	if (diff.startDate) {
		notMatched.push("Start Date")
	}
	if (diff.days) {
		notMatched.push("Duration")
	}
	if (diff.noOfAdults) {
		notMatched.push("Number of Adults")
	}
	if (diff.children) {
		notMatched.push("Children")
	}

	if (notMatched.length > 0) {
		diff.message = notMatched.join(", ") + " don't match"
		return diff
	}
	return null
}

export function getUniqueHotels(
	hotels: Array<IQuoteHotel>,
	config?: { includePrice: boolean }
) {
	const stay_hotels = hotels.reduce<Array<Array<IQuoteHotel>>>(
		(stay_hotels, hotel) => {
			if (!stay_hotels.length) return [[hotel]]
			const index = stay_hotels.length - 1
			const last_hotel = stay_hotels[index][stay_hotels[index].length - 1]
			if (
				last_hotel.hotel.id === hotel.hotel.id &&
				Math.abs(getDiff(hotel.date, last_hotel.date, "days")) <= 1
			) {
				stay_hotels[index].push(hotel)
			} else {
				stay_hotels.push([hotel])
			}
			return stay_hotels
		},
		[]
	)
	const uniqueHotels = stay_hotels.map((quoteHotels) => {
		const nights = quoteHotels.reduce<
			Array<{
				night: Date
				quoteHotel: IQuoteHotel
			}>
		>((nights, quoteHotel) => {
			const night = localOrUtcTimestampToLocalDate(
				quoteHotel.date_local,
				quoteHotel.date
			)
			nights.push({
				night: clone(night),
				quoteHotel,
			})
			return nights
		}, [])
		const roomsAndMealPlansById = nights.reduce<{
			[key: string]: Array<{
				night: Date
				quoteHotel: IQuoteHotel
				price:
					| undefined
					| {
							currency?: string
							given_price?: number
							per_room_given_price?: number
							per_adult_with_extra_bed_given_price?: number
							per_child_with_extra_bed_given_price?: number
							per_child_without_extra_bed_given_price?: number
					  }
			}>
		}>((roomsAndMealPlans, { night, quoteHotel }) => {
			const {
				room_type,
				meal_plan,
				adults_with_extra_bed,
				children_with_extra_bed,
				children_without_extra_bed,
				no_of_rooms,
				persons_per_room,
				similar_hotel_options,
				currency,
				given_price,
				per_room_given_price,
				per_adult_with_extra_bed_given_price,
				per_child_with_extra_bed_given_price,
				per_child_without_extra_bed_given_price,
			} = quoteHotel
			let price
			if (config?.includePrice) {
				price = {
					currency,
					given_price,
					per_room_given_price,
					per_adult_with_extra_bed_given_price,
					per_child_with_extra_bed_given_price,
					per_child_without_extra_bed_given_price,
				}
				if (similar_hotel_options?.length) {
					// check if any similar hotel has more price then the main hotel
					// and update if any
					const similar_hotel_options_prices = similar_hotel_options.map(
						({
							currency,
							given_price,
							per_room_given_price,
							per_adult_with_extra_bed_given_price,
							per_child_with_extra_bed_given_price,
							per_child_without_extra_bed_given_price,
						}) => ({
							currency,
							given_price,
							per_room_given_price,
							per_adult_with_extra_bed_given_price,
							per_child_with_extra_bed_given_price,
							per_child_without_extra_bed_given_price,
						})
					)
					for (const sp of similar_hotel_options_prices) {
						if (Number(sp.given_price || 0) > Number(price.given_price || 0)) {
							price = sp
						}
					}
				}
			}
			const id = [
				room_type.id,
				meal_plan.id,
				adults_with_extra_bed,
				children_with_extra_bed,
				children_without_extra_bed,
				no_of_rooms,
				persons_per_room,
				similar_hotel_options?.length || 0,
			]
				.concat(
					config?.includePrice && price
						? [
								price.given_price,
								price.per_room_given_price || 0,
								price.per_adult_with_extra_bed_given_price || 0,
								price.per_child_with_extra_bed_given_price || 0,
								price.per_child_without_extra_bed_given_price || 0,
							]
						: []
				)
				.join("-")
			if (!roomsAndMealPlans[id]) {
				roomsAndMealPlans[id] = []
			}
			roomsAndMealPlans[id].push({ night, quoteHotel, price })
			return roomsAndMealPlans
		}, {})
		const roomsAndMealPlans = Object.keys(roomsAndMealPlansById).map((id) => {
			const nights = roomsAndMealPlansById[id]
			const quoteHotel = nights[0].quoteHotel
			const price = nights[0].price
			const {
				room_type,
				meal_plan,
				adults_with_extra_bed,
				children_with_extra_bed,
				children_without_extra_bed,
				no_of_rooms,
				persons_per_room,
				room_configuration,
			} = quoteHotel

			return {
				nights: nights.map((n) => n.night),
				room_type,
				meal_plan,
				adults_with_extra_bed,
				children_with_extra_bed,
				children_without_extra_bed,
				no_of_rooms,
				persons_per_room,
				room_configuration,
				...(price || {}),
			}
		})
		return {
			hotel: quoteHotels[0].hotel,
			similar_hotel_options: quoteHotels[0].similar_hotel_options,
			nights: nights
				.map((n) => n.night)
				.reduce<Array<Date>>((uniqueNights, night) => {
					const exists = uniqueNights.findIndex((n) => isSame(n, night, "date"))
					if (exists === -1) {
						uniqueNights.push(night)
					}
					return uniqueNights
				}, []),
			roomsAndMealPlans,
		}
	})
	uniqueHotels.sort((a, b) => (a.nights[0] > b.nights[0] ? 1 : -1))
	return uniqueHotels
}

export function getCabsWithLocations(cabs: Array<IQuoteCab>) {
	const byCabTypeId = cabs.reduce<{ [key: string]: Array<IQuoteCab> }>(
		(byCabTypeId, cab) => {
			const key = cab.cab_type.id + cab.no_of_cabs
			if (!byCabTypeId[key]) {
				byCabTypeId[key] = [cab]
			} else {
				byCabTypeId[key].push(cab)
			}
			return byCabTypeId
		},
		{}
	)
	const cabTypes = collect(
		Object.keys(byCabTypeId).map((cabTypeId: string) => {
			const quoteCabs = byCabTypeId[cabTypeId]
			const dateServices = quoteCabs.map(
				(h) => h.date + "_" + h.transport_service.id
			)
			dateServices.sort()
			const dateServiceId = dateServices.join("-")
			return {
				dateServiceId: dateServiceId,
				cabType: quoteCabs[0].cab_type,
				noOfCabs: quoteCabs[0].no_of_cabs,
				services: quoteCabs.map((h) => h.transport_service),
				dates: quoteCabs.map((h) => h.date),
				dateWiseServices: quoteCabs,
			}
		})
	)
		.groupBy((item) => item.dateServiceId)
		.toArray()
		.map((grouped_by_services) => {
			return {
				dates: grouped_by_services[0]["dates"],
				services: grouped_by_services[0]["services"],
				dateWiseServices: grouped_by_services[0]["dateWiseServices"],
				cabs: grouped_by_services.map(function (s) {
					return {
						cabType: s["cabType"],
						noOfCabs: s["noOfCabs"],
					}
				}),
				cabsStr: grouped_by_services
					.map(function (s) {
						return s["noOfCabs"] + " - " + s["cabType"].name
					})
					.join(" + "),
			}
		})
	// sort the cab types so that the first one is the one that is provided on minimum services
	cabTypes.sort((a, b) => a.services.length - b.services.length)
	return cabTypes
}

export function calculateTotalPrice({
	costPriceCurrency,
	costPriceWithoutFlights,
	flightPrice,
	gstIncluded,
	taxPercentage,
	margin,
}: {
	costPriceCurrency: string
	costPriceWithoutFlights: number
	flightPrice: number | undefined
	gstIncluded: boolean
	taxPercentage: number | null | undefined
	margin: number | null | undefined
}): number {
	const tax = gstIncluded && taxPercentage ? taxPercentage : 0
	const priceWithoutTax = Number(costPriceWithoutFlights) + Number(margin || 0)
	// given_price = totalCostPrice + margin + (costPriceWithoutFlights + margin) * tax / 100
	return Number(
		formatMoneyByDecimal(
			moneyParseByDecimal(
				priceWithoutTax +
					(priceWithoutTax * Number(tax)) / 100 +
					Number(flightPrice || 0),
				costPriceCurrency
			)
		)
	)
}

export function calculateMarginFromTotalPrice({
	givenPrice,
	costPriceWithoutFlights,
	flightPrice,
	gstIncluded,
	taxPercentage,
}: {
	givenPrice: number
	costPriceWithoutFlights: number
	flightPrice?: number
	gstIncluded: boolean
	taxPercentage?: number | null
}): number {
	const tax = gstIncluded && taxPercentage ? taxPercentage : 0
	// given_price = costPriceWithoutFlights + flightPrice + margin + (costPriceWithoutFlights + margin) * tax / 100
	// margin = (given_price - costPriceWithoutFlights - flightPrice - costPriceWithoutFlights * tax / 100) / (1 + tax / 100)
	// margin = (100 * (given_price - costPriceWithoutFlights - flightPrice) - costPriceWithoutFlights * tax) / (100 + tax)
	return Math.ceil(
		(100 * (givenPrice - costPriceWithoutFlights - (flightPrice || 0)) -
			costPriceWithoutFlights * tax) /
			(100 + tax)
	)
}

export function calculateMarginPercentageFromTotalPrice({
	margin,
	costPriceWithoutFlights,
}: {
	margin: number
	costPriceWithoutFlights: number
}): number {
	if (Number(costPriceWithoutFlights || 0) <= 0) return 0
	if (Number(margin) <= 0) return 0
	return normalizeNumber(
		(Number(margin || 0) * 100) / Number(costPriceWithoutFlights || 1),
		4
	)
}

export function getDaywiseTransportsAndActivitiesForQuote({
	transports,
	transportExtras,
	travelActivities,
}: {
	transports: Array<IQuote["cabs"][number] & { fetching_prices?: boolean }>
	transportExtras: IQuote["transport_extras"]
	travelActivities: IQuote["travel_activities"]
}) {
	const daywiseTransportServices = collect(transports)
		.groupBy((t) => `${t.group_id || "."}-${t.date}`)
		.toArray()
		.map((transports) => {
			const date = transports[0].date
			const date_local = transports[0].date_local
			return {
				date,
				date_local,
				transports: collect(transports)
					.groupBy((t) => t.transport_service.id)
					.toArray()
					.map((services) => {
						return {
							id: services[0].id,
							transport_service: services[0].transport_service,
							sort_order: services[0].sort_order,
							fetching_prices: services.some((s) => Boolean(s.fetching_prices)),
							cabs: services,
						}
					}),
			}
		})
	const daywiseTransportExtraServices = collect(
		transportExtras.filter(
			(service): service is Required<typeof service, "date"> =>
				Boolean(service.date)
		)
	)
		.groupBy((t) => t.date)
		.toArray()
		.map((extras) => {
			const date = extras[0].date
			const date_local = extras[0].date_local
			return {
				date,
				date_local,
				extras,
			}
		})
	const daywiseActivityServices = collect(travelActivities || [])
		.groupBy((t) => `${t.group_id || "."}-${t.date}`)
		.toArray()
		.map((activities) => {
			const date = activities[0].date
			const date_local = activities[0].date_local
			return {
				date,
				date_local,
				activities,
			}
		})
	const daywiseServices = collect(
		(
			[] as Array<{
				date: string
				date_local: string | undefined
				transports?: (typeof daywiseTransportServices)[0]["transports"]
				activities?: (typeof daywiseActivityServices)[0]["activities"]
				extras?: (typeof daywiseTransportExtraServices)[0]["extras"]
			}>
		)
			.concat(daywiseTransportServices)
			.concat(daywiseActivityServices)
			.concat(daywiseTransportExtraServices)
	)
		.groupBy((service) => service.date)
		.toArray()
		.map((daywiseServices) => {
			let activitiesNotAttachedtoCabs = daywiseServices.flatMap(
				(s) => s.activities || []
			)
			const transports = daywiseServices
				.flatMap((s) => s.transports || [])
				.map((transport) => {
					const [activitiesAttachedToThisTransport, remainingActivities] =
						collect(activitiesNotAttachedtoCabs).partition(
							(a): a is Required<typeof a, "quote_cab_id"> =>
								a.quote_cab_id === transport.id
						)
					activitiesNotAttachedtoCabs = remainingActivities
					return {
						...transport,
						activities: activitiesAttachedToThisTransport,
					}
				})
			const extras = daywiseServices.flatMap((s) => s.extras || [])
			return {
				date: daywiseServices[0].date,
				date_local: daywiseServices[0].date_local,
				transports,
				activities: activitiesNotAttachedtoCabs,
				extras,
			}
		})

	daywiseServices.sort((a, b) =>
		a.date > b.date ? 1 : a.date === b.date ? 0 : -1
	)
	const extraServiceWithoutDates = transportExtras.filter((t) => !t.date)
	const daywiseServicesWithSorting = daywiseServices.map(function ({
		transports,
		activities,
		extras,
		date,
		date_local,
	}) {
		const sortedData: Array<
			(
				| {
						type: "transport"
						transport: Omit<(typeof transports)[0], "activities">
						activity?: undefined
				  }
				| {
						type: "activity"
						transport?: undefined
						activity: (typeof activities)[0]
				  }
			) & {
				start_time: Date | undefined
				sort_order?: number
			}
		> = []
		transports.forEach(({ activities, ...t }) => {
			sortedData.push({
				type: "transport",
				transport: t,
				sort_order: t.sort_order,
				start_time: t.transport_service.start_time
					? parseDate(t.transport_service.start_time, "HH:mm:ss")
					: undefined,
			})
			activities.forEach((a) => {
				sortedData.push({
					type: "activity",
					activity: a,
					sort_order: a.sort_order,
					start_time: a.slot ? parseDate(a.slot, "HH:mm") : undefined,
				})
			})
		})
		activities.forEach((a) => {
			sortedData.push({
				type: "activity",
				activity: a,
				sort_order: a.sort_order,
				start_time: a.slot ? parseDate(a.slot, "HH:mm") : undefined,
			})
		})

		if (sortedData[0]?.sort_order) {
			sortedData.sort((a, b) =>
				a.sort_order && b.sort_order ? a.sort_order - b.sort_order : 0
			)
		} else {
			const canSort = sortedData.every((a) => {
				return Boolean(a.start_time)
			})

			if (canSort) {
				// now sort the data
				sortedData.sort((a, b) => {
					if (!a.start_time || !b.start_time) {
						return 0
					}
					const aStartTime = a.start_time.valueOf()
					const bStartTime = b.start_time.valueOf()
					return aStartTime > bStartTime ? 1 : aStartTime < bStartTime ? -1 : 0
				})
			}
		}

		return {
			date,
			date_local,
			transportsAndActivities: sortedData,
			extras,
		}
	})
	return {
		daywiseServices: daywiseServicesWithSorting,
		extraServiceWithoutDates,
	}
}

export function getMultiplier(
	tripQuote: Pick<
		ITripQuote,
		"currency" | "cost_price" | "given_quote" | "quote" | "options"
	>
): number {
	const { given_quote, quote } = tripQuote
	if (!given_quote) return 0
	const currencyPairs = given_quote.currency_pairs
	const packageCurrency = given_quote.given_currency
	const convertCurrency = makeCurrencyConverter(currencyPairs || [])
	const flightsPriceInCostingCurrency = convertCurrency(
		tripQuote.currency,
		addMoneyFromDifferentCurrencies(
			quote.flights.map(({ given_price, currency }) =>
				moneyParseByDecimal(given_price || 0, currency)
			)
		)
	)

	const totalGivenPriceInPackageCurrency = moneyParseByDecimal(
		given_quote.given_price,
		packageCurrency
	)

	const flightsPriceInPackageCurrency = convertCurrency(
		packageCurrency,
		flightsPriceInCostingCurrency
	)

	const totalCostPriceInPackageCurrency = convertCurrency(
		packageCurrency,
		moneyParseByDecimal(tripQuote.cost_price, tripQuote.currency)
	)

	const ratio = getMoneyRatio(
		subtractMoney(
			totalGivenPriceInPackageCurrency,
			flightsPriceInPackageCurrency
		),
		subtractMoney(
			totalCostPriceInPackageCurrency,
			flightsPriceInPackageCurrency
		),
		0
	)

	return ratio
}

export function orderDaywiseServicesByDate<T extends { dates?: Date[] }>(
	daywiseServices: T[]
): T[] {
	if (!(daywiseServices || []).length) return daywiseServices

	const orderedServices = [...daywiseServices]

	const multiDateIndices = orderedServices.reduce<number[]>(
		(indices, service, index) => {
			if (service.dates && (service.dates || []).length > 1) {
				indices.push(index)
			}
			return indices
		},
		[]
	)

	let startIdx = 0
	for (let i = 0; i <= multiDateIndices.length; i++) {
		const endIdx =
			i < multiDateIndices.length ? multiDateIndices[i] : orderedServices.length

		if (endIdx > startIdx) {
			const segment = orderedServices.slice(startIdx, endIdx)
			segment.sort((a, b) => {
				if (!(a.dates || []).length || !(b.dates || []).length) return 0

				return (
					(a.dates as Date[])[0].getTime() - (b.dates as Date[])[0].getTime()
				)
			})

			for (let j = startIdx; j < endIdx; j++) {
				orderedServices[j] = segment[j - startIdx]
			}
		}

		if (i < multiDateIndices.length) {
			startIdx = multiDateIndices[i] + 1
		}
	}

	return orderedServices
}
