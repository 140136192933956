import {
	Alert,
	Box,
	Button,
	Icons,
	Text,
	Heading,
	Stack,
	Inline,
	Component,
	Dropdown,
	Spinner,
	Container,
} from "@sembark-travel/ui/base"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { Breadcrumbs, Link } from "@sembark-travel/ui/router"
import { utcTimestampToLocalDateTimeString } from "@sembark-travel/datetime-utils"
import { numberToLocalString } from "@sembark-travel/number-utils"
import { useXHR } from "@sembark-travel/xhr"
import { Fragment, useMemo, useState } from "react"
import { generatePath, useNavigate } from "../router-utils"
import { DeleteAccount, DisableAccount } from "./Accounts"
import { LogTransactionDialog } from "./LogTransaction"
import { useAccount } from "./store"
import { Transactions } from "./Transactions"

export function AccountItem({
	accountId,
	...props
}: { accountId: number | string } & Omit<
	React.ComponentProps<typeof Transactions>,
	"params" | "disableLogging" | "onChange" | "pageKey"
>) {
	const navigate = useNavigate()
	const xhr = useXHR()
	const { account, error, mutate: revalidate } = useAccount(accountId)
	const transactionParams = useMemo(
		() => ({ accounts: account ? [account] : [] }),
		[account]
	)
	const [updateTransactionsFlag, setForceUpdateTransactions] = useState(1)
	if (error) return <Alert status="error">{error.message}</Alert>
	if (!account) return <Spinner alignCenter padding="4" />
	const {
		id,
		name,
		description,
		assets,
		is_public,
		disabled_at,
		tags,
		types,
		updated_by,
		can_edit,
		can_disable,
		can_delete,
		asset_type,
	} = account
	return (
		<Box>
			<Breadcrumbs
				title="Account Details"
				items={[
					[generatePath("/accounting/accounts"), "Accounts"],
					["", name],
				]}
				actions={
					<Inline gap="4">
						{!account.disabled_at ? (
							<Component initialState={false}>
								{({ state, setState }) => (
									<>
										<Button
											level="primary"
											status="primary"
											onClick={() => setState(true)}
										>
											<Icons.Plus /> Transaction
										</Button>
										<LogTransactionDialog
											open={state}
											initialValues={{
												debit_account: {
													...account,
													name: account?.asset_type
														? `${account.name} (${account.asset_type})`
														: account.name,
												},
												...(account?.asset_type && {
													debit_currency: account.asset_type,
												}),
											}}
											onSuccess={() => {
												setState(false)
												setForceUpdateTransactions((flag) => flag + 1)
												revalidate()
											}}
											onCancel={() => {
												setState(false)
											}}
										/>
									</>
								)}
							</Component>
						) : (
							<Button
								title="Enable Account"
								level="secondary"
								onClick={async () => {
									if (
										window.confirm(
											"Are you sure you want to enable this account ?"
										)
									) {
										try {
											const resp = await xhr.delete(
												`/accounting/disabled-accounts/${account.id}`
											)
											revalidate()
											showSnackbar(
												resp.data.message || "Account enabled successfully"
											)
										} catch (e) {
											const error = e as Error
											window.alert(error.message)
										}
									}
								}}
							>
								<Icons.Ok /> Enable Account
							</Button>
						)}
						{can_edit || can_delete || can_disable ? (
							<Dropdown alignRight>
								<Dropdown.ToggleButton level="tertiary" size="sm">
									<Icons.DotsVertical />
								</Dropdown.ToggleButton>
								<Dropdown.Menu>
									{can_edit ? (
										<Dropdown.MenuItem as={Link} to={"edit"}>
											<Icons.Pencil /> Edit Details
										</Dropdown.MenuItem>
									) : null}
									{can_disable ? (
										<DisableAccount account={account} onSuccess={revalidate}>
											{({ onDisable }) => (
												<Dropdown.MenuItem onClick={onDisable}>
													<Icons.Ban /> Disable Account
												</Dropdown.MenuItem>
											)}
										</DisableAccount>
									) : null}
									{can_delete ? (
										<DeleteAccount
											account={account}
											onSuccess={() => {
												navigate("/accounting/accounts")
											}}
										>
											{({ onDelete }) => (
												<Dropdown.MenuItem onClick={() => onDelete()}>
													<Icons.Trash /> Delete Account
												</Dropdown.MenuItem>
											)}
										</DeleteAccount>
									) : null}
								</Dropdown.Menu>
							</Dropdown>
						) : null}
					</Inline>
				}
			/>
			<Container paddingY="6" bgColor="default" borderBottomWidth="1">
				<Stack gap="4">
					<Stack gap="1">
						<Inline alignItems="center" gap="4">
							<Heading as="h3">{name}</Heading>
							<Inline gap="2">
								{is_public ? (
									<Icons.UserGroup
										title="Everyone in the company can log transactions to this account"
										color="muted"
									/>
								) : null}
								{disabled_at ? (
									<Icons.Ban
										color="warning"
										title={`Disabled by ${updated_by?.name} on ${utcTimestampToLocalDateTimeString(
											disabled_at
										)}`}
									/>
								) : null}
							</Inline>
						</Inline>
						{description ? <Text color="muted">{description}</Text> : null}
					</Stack>
					<Inline gap="8" flexWrap="wrap">
						<Stack gap="1">
							<Heading
								as="h4"
								fontSize="sm"
								color="muted"
								textTransform="uppercase"
							>
								Assets
							</Heading>
							<Box>
								{assets?.length ? (
									<Inline gap="1">
										{assets.map((a, i) => (
											<Fragment key={a.type}>
												{i > 0 ? (
													<Text>
														<Icons.Plus />
													</Text>
												) : null}
												<Inline
													alignItems="baseline"
													letterSpacing="wider"
													color={a.amount < 0 ? "danger" : "success"}
													gap="1"
												>
													<Text fontSize="lg" fontWeight="semibold">
														{numberToLocalString(a.amount)}
													</Text>
													<Text color="muted" fontSize="sm">
														{a.type}
													</Text>
												</Inline>
											</Fragment>
										))}
									</Inline>
								) : (
									"n/a"
								)}
							</Box>
						</Stack>

						<Stack gap="1">
							<Heading fontSize="sm" color="muted" textTransform="uppercase">
								Currency
							</Heading>
							<Text>{asset_type || "-"}</Text>
						</Stack>
						<Stack gap="1">
							<Heading fontSize="sm" color="muted" textTransform="uppercase">
								Types
							</Heading>
							<Text>
								{types?.length ? types.map((t) => t.name).join(",") : "-"}
							</Text>
						</Stack>
						<Stack>
							<Heading fontSize="sm" color="muted" textTransform="uppercase">
								Tags
							</Heading>
							<Text>
								{tags?.length ? tags.map((t) => t.name).join(",") : "-"}
							</Text>
						</Stack>
					</Inline>
				</Stack>
			</Container>
			<Transactions
				{...props}
				params={transactionParams}
				disableLogging
				onChange={revalidate}
				pageKey={`accounting/transactions/${id}/${updateTransactionsFlag}`}
			/>
		</Box>
	)
}
