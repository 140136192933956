import {
	Alert,
	Badge,
	Box,
	Inline,
	Heading,
	Stack,
	Text,
	Col,
	Container,
	Details,
	Divider,
	Grid,
	Spinner,
	Stars,
	Summary,
	Icons,
	joinAttributes,
} from "@sembark-travel/ui/base"
import { ButtonLink, Link } from "@sembark-travel/ui/router"
import { Markdown } from "@sembark-travel/ui/markdown"
import {
	utcTimestampToLocalDate,
	getDiff,
	formatDate,
	localOrUtcTimestampToLocalDate,
} from "@sembark-travel/datetime-utils"
import { withOrdinalSuffix } from "@sembark-travel/number-utils"
import { useXHR } from "@sembark-travel/xhr"
import pluralize from "pluralize"
import React, { Fragment, useMemo } from "react"
import useSWR from "swr"
import type { ITermsAndCondition } from "../TermsAndConditions"
import {
	IDaywiseSchedule,
	IInclusionExclusion,
	IQuote,
	ITripQuote,
} from "./store"
import { generatePath } from "../router-utils"
import { getUniqueHotels, getCabsWithLocations } from "./utils"
import { collect } from "../utils"

interface ItineraryProps {
	quote: ITripQuote
	editable?: boolean
	onChange?: () => void
}

export default function ItineraryItem({
	quote: tripQuote,
	editable,
}: ItineraryProps) {
	const xhr = useXHR()
	const { itinerary: minimalItinerary, quote } = tripQuote
	const { data: itinerary, error } = useSWR(
		minimalItinerary ? `/quote-itineraries/${tripQuote.id}` : null,
		() =>
			xhr
				.get(`/quote-itineraries/${tripQuote.id}`)
				.then((resp) => resp.data.data)
	)
	const editItineraryUrl =
		generatePath("/trips/:tripId/quotes/:quoteId", {
			tripId: tripQuote.trip_id.toString(),
			quoteId: tripQuote.id.toString(),
		}) + `/edit-itinerary`
	if (!minimalItinerary) {
		return (
			<Box>
				<Box>Inlcusions/Exclusions and Daywise Schedule not provided!</Box>
				{editable ? (
					<Box>
						<Box marginTop="8">
							<ButtonLink
								to={editItineraryUrl}
								level="primary"
								status="primary"
							>
								{tripQuote.shared_by_tenant
									? "Edit T&C or Other Info"
									: "Edit Inclusion/Exclusions and Itinerary"}
							</ButtonLink>
						</Box>
					</Box>
				) : null}
			</Box>
		)
	}
	if (!itinerary && !error) {
		return <Spinner padding="4" alignCenter />
	}
	if (error) {
		return <Alert status="error">{error.message || "No itinerary"}</Alert>
	}
	if (!itinerary || !quote) {
		return <Box fontSize="lg">No itinerary</Box>
	}
	const { start_date, start_date_local } = quote
	const {
		inclusion_exclusions,
		daywise_schedules,
		terms_and_conditions,
		other_info,
	} = itinerary
	const startDateLocal = localOrUtcTimestampToLocalDate(
		start_date_local,
		start_date
	)
	return (
		<Box>
			<Details open>
				<Summary>Inclusion/Exclusions</Summary>
				<Box marginTop="4" maxWidth="6xl">
					{inclusion_exclusions.length ? (
						<InclusionExclusions inclusionExclusions={inclusion_exclusions} />
					) : (
						<Box color="muted">No inclusion/exclusions added</Box>
					)}
				</Box>
			</Details>
			<Divider />
			<Details open>
				<Summary>Day-wise Schedule</Summary>
				{daywise_schedules.length ? (
					<DaywiseSchedule
						daywiseSchedules={daywise_schedules}
						startDate={startDateLocal}
					/>
				) : (
					<Box color="muted">Day-wise schedule not provided</Box>
				)}
			</Details>
			{terms_and_conditions ? (
				<Fragment>
					<Divider />
					<Details>
						<Summary>
							Terms and Conditions{" "}
							{terms_and_conditions.deleted_at ? (
								<Badge warning>Archived</Badge>
							) : null}
						</Summary>
						<TermsAndConditions tc={terms_and_conditions} />
					</Details>
				</Fragment>
			) : null}
			{other_info ? (
				<Fragment>
					<Divider />
					<Details open>
						<Summary>Other Information Attached</Summary>
						<Box
							whiteSpace="preserveAndWrap"
							marginTop="4"
							padding="4"
							borderWidth="1"
							rounded="md"
							bgColor="default"
						>
							{other_info}
						</Box>
					</Details>
				</Fragment>
			) : null}
			{editable ? (
				<>
					<Box marginTop="8">
						<ButtonLink to={editItineraryUrl} level="primary" status="primary">
							{tripQuote.shared_by_tenant
								? "Edit T&C or Other Info"
								: "Edit Inclusion/Exclusions and Itinerary"}
						</ButtonLink>
					</Box>
				</>
			) : null}
		</Box>
	)
}

export function Hotels({ quote }: { quote: IQuote }) {
	const { hotels, start_date: startDate } = quote
	const uniqueHotels = React.useMemo(() => {
		return getUniqueHotels(hotels)
	}, [hotels])
	return (
		<Box>
			<Grid as="ol">
				{uniqueHotels.map(({ hotel, roomsAndMealPlans, nights }) => {
					return (
						<Col
							as="li"
							key={hotel.id}
							marginY="4"
							style={{ minWidth: "280px", maxWidth: "550px" }}
						>
							<Box padding="3" rounded="lg" borderWidth="1" bgColor="default">
								<Box>
									<Box as="h4" marginBottom="0" fontWeight="semibold">
										{hotel.name}
									</Box>
									<Box>
										{joinAttributes(
											hotel.location.short_name,
											hotel.stars ? <Stars stars={hotel.stars} /> : null
										)}
									</Box>
									<Inline gap="2" marginTop="1">
										<Box
											display="inline"
											textTransform="uppercase"
											letterSpacing="wider"
											fontWeight="semibold"
										>
											Nights
										</Box>
										<Inline gap="1">
											{nights.map((n, i) => (
												<Badge key={i} primary fullRounded>
													{withOrdinalSuffix(
														getDiff(
															n,
															utcTimestampToLocalDate(startDate),
															"days"
														) + 1
													)}
												</Badge>
											))}
										</Inline>
									</Inline>
								</Box>
								<Box
									borderTopWidth={
										roomsAndMealPlans.length > 1 ? "1" : undefined
									}
									marginTop={roomsAndMealPlans.length > 1 ? "2" : undefined}
									paddingTop={roomsAndMealPlans.length > 1 ? "2" : undefined}
								>
									{roomsAndMealPlans.map(
										(
											{
												nights,
												room_type,
												meal_plan,
												adults_with_extra_bed,
												children_with_extra_bed,
												children_without_extra_bed,
												no_of_rooms,
											},
											i
										) => (
											<Box marginBottom="2" key={i}>
												{roomsAndMealPlans.length > 1 ? (
													<Inline gap="2">
														<Box
															display="inline"
															textTransform="uppercase"
															letterSpacing="wider"
															color="muted"
														>
															Nights
														</Box>
														<Inline gap="1">
															{nights.map((n, i) => (
																<Badge key={i} outlined fullRounded>
																	{withOrdinalSuffix(
																		getDiff(
																			n,
																			utcTimestampToLocalDate(startDate),
																			"days"
																		) + 1
																	)}
																</Badge>
															))}
														</Inline>
													</Inline>
												) : null}
												<Grid gap="4">
													<Col>
														<Box
															textTransform="uppercase"
															letterSpacing="wider"
															color="muted"
															fontSize="sm"
														>
															Rooms
														</Box>
														<Box fontWeight="semibold">
															{no_of_rooms} {room_type.name}
														</Box>
														<Box fontSize="sm">
															{[
																adults_with_extra_bed +
																	children_with_extra_bed >
																0
																	? `${
																			adults_with_extra_bed +
																			children_with_extra_bed
																		} extra ${pluralize(
																			"bed",
																			adults_with_extra_bed +
																				children_with_extra_bed
																		)}`
																	: null,
																children_without_extra_bed > 0
																	? `${pluralize(
																			"child",
																			children_without_extra_bed,
																			true
																		)} without extra bed`
																	: null,
															]
																.filter((i) => i)
																.join(" and ")}
														</Box>
													</Col>
													<Col>
														<Box
															textTransform="uppercase"
															letterSpacing="wider"
															color="muted"
															fontSize="sm"
														>
															Meal Plan
														</Box>
														<Box fontWeight="semibold">
															{meal_plan.description}
														</Box>
													</Col>
												</Grid>
											</Box>
										)
									)}
								</Box>
							</Box>
						</Col>
					)
				})}
			</Grid>
			<Box marginTop="8">
				<Box as="h3" fontSize="lg" letterSpacing="wider" paddingLeft="4">
					Extras
				</Box>
				<HotelExtras quote={quote} />
			</Box>
		</Box>
	)
}

export function HotelExtras({ quote }: { quote: IQuote }) {
	const { hotel_extras, start_date: startDate } = quote
	return (
		<Grid as="ol">
			{hotel_extras.map(({ id, hotel, date, service }) => {
				return (
					<Col
						as="li"
						key={id}
						marginY="4"
						style={{ minWidth: "280px", maxWidth: "550px" }}
					>
						<Box bgColor="default" rounded="lg" borderWidth="1" padding="4">
							<Box as="h4">{service.name}</Box>
							<Box>
								{date ? (
									<>
										<Badge primary outlined>
											{withOrdinalSuffix(getDiff(date, startDate, "day") + 1)}{" "}
											Night
										</Badge>{" "}
										at{" "}
									</>
								) : null}
								<b>{hotel.name}</b> ({hotel.location.short_name})
							</Box>
						</Box>
					</Col>
				)
			})}
		</Grid>
	)
}

export function Cabs({ quote }: { quote: IQuote }) {
	const { cabs } = quote
	const providedCabs = React.useMemo(() => {
		return getCabsWithLocations(cabs)
	}, [cabs])
	return (
		<Box marginTop="4">
			<Inline as="ul" flexWrap="wrap" gap="4">
				{providedCabs.map(({ cabsStr, services }, i) => {
					return (
						<Box
							as="li"
							key={i}
							borderWidth="1"
							display="inlineBlock"
							verticalAlign="top"
							padding="4"
							rounded="md"
							bgColor="default"
							style={{ minWidth: "150px" }}
						>
							<Box fontSize="lg" fontWeight="semibold">
								{cabsStr}
							</Box>
							<Box>
								{services.map((s) => (
									<Box key={s.name}>{s.name}</Box>
								))}
							</Box>
						</Box>
					)
				})}
			</Inline>
			<Box marginTop="8">
				<Box as="h3" fontSize="lg" letterSpacing="wider" paddingLeft="4">
					Extras
				</Box>
				<CabExtras quote={quote} />
			</Box>
		</Box>
	)
}

export function CabExtras({ quote }: { quote: IQuote }) {
	const { transport_extras, start_date: startDate } = quote
	return (
		<Grid as="ol">
			{transport_extras.map(({ id, date, service }) => {
				return (
					<Col
						as="li"
						key={id}
						marginY="4"
						style={{ minWidth: "280px", maxWidth: "550px" }}
					>
						<Box bgColor="default" rounded="lg" borderWidth="1" padding="4">
							<Box as="h4">{service.name}</Box>
							{date ? (
								<Box>
									<Badge primary outlined>
										{withOrdinalSuffix(getDiff(date, startDate, "day") + 1)} Day
									</Badge>
								</Box>
							) : null}
						</Box>
					</Col>
				)
			})}
		</Grid>
	)
}

export function DaywiseScheduleDay({
	date,
	from,
	previousDate,
}: {
	date: Date
	previousDate?: Date | null
	from: Date
}) {
	const day = getDiff(date, from, "days")
	const previousDay = previousDate
		? getDiff(previousDate, from, "days")
		: undefined
	if (previousDay === day) return null
	return (
		<Box
			borderWidth="1"
			rounded="md"
			borderColor="accent"
			bgColor="accent"
			textAlign="center"
			overflow="hidden"
			pointerEvents="none"
		>
			<Box
				paddingX="2"
				paddingY="2"
				color="accent"
				fontWeight="semibold"
				fontFamily="mono"
				fontSize="md"
			>
				{withOrdinalSuffix(day + 1)} Day
			</Box>
			<Box paddingTop="2" paddingX="3" bgColor="default" fontSize="sm">
				{formatDate(date, "dddd")}
			</Box>
			<Box
				paddingY="1"
				paddingX="2"
				bgColor="default"
				whiteSpace="preserve"
				fontWeight="semibold"
			>
				{formatDate(date, "Do MMM")}
			</Box>
			<Box
				paddingBottom="1"
				paddingX="2"
				bgColor="default"
				fontSize="sm"
				color="muted"
			>
				{formatDate(date, "YYYY")}
			</Box>
		</Box>
	)
}

function InclusionExclusionItem({
	item,
	isInclusion = false,
}: {
	item: IInclusionExclusion
	isInclusion?: boolean
}) {
	const { category, is_boolean, inclusion, exclusion } = item
	return (
		<Box display="flex">
			<Box
				bgColor={isInclusion ? "success_emphasis" : "danger_emphasis"}
				rounded="full"
				width="2"
				height="2"
				marginRight="2"
				marginTop="2"
			/>
			<Box flex="1" minWidth="0">
				<Text fontWeight="semibold" fontSize="md">
					{category}
				</Text>
				{!is_boolean ? (
					<Text whiteSpace="preserveLine">
						{isInclusion ? inclusion : exclusion}
					</Text>
				) : null}
			</Box>
		</Box>
	)
}

function InclusionExclusions({
	inclusionExclusions,
}: {
	inclusionExclusions: IInclusionExclusion[]
}) {
	const { inclusions, exclusions } = React.useMemo(() => {
		return inclusionExclusions.reduce<{
			inclusions: IInclusionExclusion[]
			exclusions: IInclusionExclusion[]
		}>(
			({ inclusions, exclusions }, ie) => {
				if (ie.inclusion) {
					inclusions.push(ie)
				}
				if (ie.exclusion) {
					exclusions.push(ie)
				}
				return {
					inclusions,
					exclusions,
				}
			},
			{ inclusions: [], exclusions: [] }
		)
	}, [inclusionExclusions])
	return (
		<Container fluid>
			<Grid bgColor="default">
				<Col xs sm={6} marginY="4">
					<Box style={{ minWidth: "150px" }}>
						<Box
							display="inlineBlock"
							paddingX="2"
							borderBottomWidth="4"
							borderColor="success"
						>
							<Heading fontSize="base" as="h4">
								Inclusions
							</Heading>
						</Box>
						<Box as="ul">
							{inclusions.map((item) => (
								<Box as="li" key={item.id} marginY="2">
									<InclusionExclusionItem item={item} isInclusion />
								</Box>
							))}
						</Box>
					</Box>
				</Col>
				<Col xs sm={6} marginY="4">
					<Box style={{ minWidth: "150px" }}>
						<Box
							display="inlineBlock"
							paddingX="2"
							borderBottomWidth="4"
							borderColor="danger"
						>
							<Heading fontSize="base" as="h4">
								Exclusions
							</Heading>
						</Box>
						<Box as="ul">
							{exclusions.map((item) => (
								<Box as="li" key={item.id} marginY="2">
									<InclusionExclusionItem item={item} />
								</Box>
							))}
							<Box marginY="2">
								<Box
									as="blockquote"
									fontWeight="semibold"
									color="warning"
									borderColor="warning_emphasis"
								>
									Anything not in inclusions is Excluded
								</Box>
							</Box>
						</Box>
					</Box>
				</Col>
			</Grid>
		</Container>
	)
}

function DaywiseSchedule({
	daywiseSchedules,
	startDate,
}: {
	daywiseSchedules: IDaywiseSchedule[]
	startDate: Date
}) {
	const groupedDaywiseSchedules = useMemo(() => {
		return collect(daywiseSchedules)
			.groupBy((s) => s.start_date)
			.toArray()
			.map((schedules) => ({
				date: schedules[0].start_date,
				date_local: schedules[0].start_date_local,
				schedules,
			}))
	}, [daywiseSchedules])
	return (
		<Stack as="ol" marginTop="4" paddingX="4" gap="6">
			{groupedDaywiseSchedules.map(
				({ date: start_date, date_local, schedules }) => {
					const date = localOrUtcTimestampToLocalDate(date_local, start_date)
					return (
						<Grid gap="6" as="li" key={start_date}>
							<Col xs={12} sm="auto">
								<Box paddingTop="1" style={{ width: "120px" }}>
									<DaywiseScheduleDay date={date} from={startDate} />
								</Box>
							</Col>
							<Col>
								<Stack as="ol" gap="4">
									{schedules.map(({ id, title, description, travel_info }) => {
										if (travel_info) {
											description += "\n\n" + travel_info
										}
										return (
											<Stack as="li" gap="1" key={id}>
												<Heading
													as="h4"
													fontSize="md"
													textDecoration={"underline"}
												>
													{title}
												</Heading>
												<Box>
													<Markdown>{description}</Markdown>
												</Box>
											</Stack>
										)
									})}
								</Stack>
							</Col>
						</Grid>
					)
				}
			)}
		</Stack>
	)
}

function TermsAndConditions({ tc }: { tc: ITermsAndCondition }) {
	const { name, description, deleted_at } = tc
	return (
		<Stack
			marginTop="4"
			borderWidth="1"
			rounded="md"
			overflow="hidden"
			borderColor={deleted_at ? "warning" : "default"}
		>
			<Stack
				padding="4"
				bgColor={deleted_at ? "warning" : "subtle"}
				borderBottomWidth="1"
				borderColor={deleted_at ? "warning" : "emphasis"}
				gap="1"
			>
				<Inline gap="4">
					<Link
						to={generatePath("/terms-and-conditions/:tncId", {
							tncId: tc.id.toString(),
						})}
						color="default"
						fontWeight="semibold"
					>
						{name}
					</Link>
					{deleted_at ? <Badge warning>Archived</Badge> : null}
				</Inline>
				{deleted_at ? (
					<Text color="warning">
						<Icons.AttentionSolid /> These terms have been archived. Please
						avoid using these terms for any new quotes.
					</Text>
				) : null}
			</Stack>
			<Box padding="4">
				<Markdown>{description}</Markdown>
			</Box>
		</Stack>
	)
}
