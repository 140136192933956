import { IListResponse, XHRInstance } from "@sembark-travel/xhr"
import { TWorldBankBranch } from "../World"

type T_BankAccountStatus = {
	id: number
	name: "new" | "in_progress" | "verified" | "rejected"
	created_at: string
}

export type T_BankAccount = {
	id: number
	account_number: string
	currency: string
	beneficiary_name: string
	brank_branch_id: number
	bank_branch?: TWorldBankBranch
	accounting_account?: { id: number; name: string; description?: string }
	status: T_BankAccountStatus
	bank_name: string
	bank_code: string
	branch_name: string
	branch_city_name: string
	branch_state_name: string
	branch_country_name: string
	branch_address: string
	branch_centre: string
	branch_district_name?: string
	branch_swift_code?: string
	branch_ifsc_code?: string
	branch_micr_code?: string
}

export function bankAccountsXHR(xhr: XHRInstance) {
	return {
		async getBankAccounts(
			params?: unknown
		): Promise<IListResponse<T_BankAccount>> {
			return xhr
				.get("/accounting/bank-accounts", { params })
				.then((resp) => resp.data)
		},
		async deleteBankAccount(id: string | number) {
			return xhr
				.delete<{ message: string }>(`/accounting/bank-accounts/${id}`)
				.then((resp) => resp.data)
		},
		async storeBankAccount(data: unknown): Promise<T_BankAccount> {
			return xhr
				.post(`/accounting/bank-accounts`, data)
				.then((resp) => resp.data.data)
		},
	}
}
