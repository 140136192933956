import { Box, Icons, Container } from "@sembark-travel/ui/base"
import {
	ButtonLink,
	Breadcrumbs,
	searchToQuery,
} from "@sembark-travel/ui/router"
import { useMemo } from "react"
import { Helmet } from "react-helmet-async"
import { IAccount } from "../../../../Accounting/store"
import {
	useNavigate,
	useLocation,
	generatePath,
} from "../../../../router-utils"
import { LogTransaction } from "./../../../../Accounting"

export default function LogNewTransactionPage() {
	const navigate = useNavigate()
	const location = useLocation()
	const { debit_account_id, debit_account_name, debit_account_asset_type } =
		searchToQuery<{
			debit_account_id: number
			debit_account_name: string
			debit_account_asset_type: string
		}>(location?.search)
	const initialValues = useMemo(() => {
		if (!debit_account_id) return undefined
		return {
			debit_account: {
				id: debit_account_id,
				name: debit_account_asset_type
					? `${debit_account_name} (${debit_account_asset_type})`
					: debit_account_name,
			} as unknown as IAccount,
			...(debit_account_asset_type && {
				debit_currency: debit_account_asset_type,
			}),
		}
	}, [debit_account_id, debit_account_name, debit_account_asset_type])
	const transactionsUrl = debit_account_id
		? generatePath("/accounting/accounts/:accountId", {
				accountId: debit_account_id.toString(),
			})
		: generatePath("/accounting/transactions")
	return (
		<>
			<Helmet>
				<title>Add New Transaction</title>
			</Helmet>
			<Breadcrumbs
				title="New Transaction"
				items={[
					[transactionsUrl, "Transactions"],
					["", "New"],
				]}
				actions={
					<ButtonLink to={generatePath("/accounting/transactions/csv-upload")}>
						<Icons.Upload /> Upload CSV
					</ButtonLink>
				}
			/>
			<Box paddingX="4" paddingY="8">
				<Box maxWidth="6xl" marginX="auto">
					<Box borderWidth="1" rounded="md" bgColor="default">
						<Container paddingY="6">
							<LogTransaction
								initialValues={initialValues}
								onSuccess={() => {
									navigate(transactionsUrl as "/accounting/transactions")
								}}
								onCancel={() => {
									navigate(transactionsUrl as "/accounting/transactions")
								}}
							/>
						</Container>
					</Box>
				</Box>
			</Box>
		</>
	)
}
